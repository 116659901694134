import React, { useState } from "react";
import { Blacked } from "../../../../CRM/components/styles";
import { ReactComponent as ModalCloseIcon } from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import { ReactComponent as CustomerCard } from "../../../../assets/HomeTownLogin/CustomerCard.svg";
import { ReactComponent as RealEstateCard } from "../../../../assets/HomeTownLogin/RealEstateCard.svg";
import styled from "styled-components";
import { message, Select } from "antd";
import { useAgentADInfo } from "../../../hooks/Agent/Commercial/GetAgentADInfo";
import { useAgentADUserInfo } from "../../../hooks/Agent/Commercial/GetAgentADUserInfo";
import { useToken } from "../../../../app/hooks";
import API from "../../../../utils/api";

export const OutputCommercialDetail = ({ itemId, setItemId, mutate }) => {
  // 데이터 조회
  const { agentADInfo, mutateAgentADInfo } = useAgentADInfo(itemId);
  const { agentADUserInfo, mutateAgentADUserInfo } = useAgentADUserInfo(itemId);
  const { data: token } = useToken();

  const permitCommercial = (status) => {
    const shouldEdit = window.confirm(
      `광고를 ${status === 1 ? "승인" : "거절"}하시겠습니까?`
    );
    if (shouldEdit) {
      API.post("/agent/ad/list/permit", token + "", {
        ids: [itemId],
        status,
      })
        .then((res: any) => {
          if (res.result.success) {
            message.success(`${status === 1 ? "승인" : "거절"}하였습니다.`);
            mutate();
            setItemId(null);
          }
        })
        .catch((err: any) => {
          message.error("실패하였습니다.");
        });
    }
  };

  return (
    <>
      <Blacked onClick={() => setItemId(null)} />
      <ModalWrapper className={"scroll-container"}>
        <ModalHeader>
          <ModalTitle>광고요청 상세</ModalTitle>
          <CloseIconSVG
            as={ModalCloseIcon}
            onClick={() => {
              setItemId(null);
            }}
          />
        </ModalHeader>

        <ContentsTitle>요청한 광고 정보</ContentsTitle>
        <Table>
          <tbody>
            <TableRow>
              <Th>
                <ThLabel>광고주</ThLabel>
              </Th>
              <Td>{agentADInfo?.store}</Td>
            </TableRow>
            <TableRow>
              <Th>
                <ThLabel>광고명</ThLabel>
              </Th>
              <Td>{agentADInfo?.title}</Td>
            </TableRow>
            <TableRow>
              <Th>
                <ThLabel>요청일</ThLabel>
              </Th>
              <Td>{agentADInfo?.requestedAt}</Td>
            </TableRow>
            <TableRow>
              <Th>
                <ThLabel>광고기간</ThLabel>
              </Th>
              <Td>{agentADInfo?.period}</Td>
            </TableRow>
            <TableRow>
              <Th>
                <ThLabel>광고료</ThLabel>
              </Th>
              <Td>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{agentADInfo?.price || 0}원</div>
                  <div>{`수수료(30%) ${
                    Math.floor(agentADInfo?.price * 0.3) || 0
                  }원`}</div>
                </div>
              </Td>
            </TableRow>
            <TableRow>
              <Th>
                <ThLabel>첨부파일</ThLabel>
              </Th>
              <Td>
                {agentADInfo?.fileList?.map((item, index) => (
                  <>
                    {item?.url?.includes("mp4") ? (
                      <video
                        key={`${item?.name}_${index}`}
                        autoPlay
                        loop
                        muted
                        style={{ width: 130, margin: "0 2px" }}
                      >
                        <source
                          src={"https://api.myhometown-ad.co.kr/" + item?.url}
                          type="video/mp4"
                        />
                      </video>
                    ) : (
                      <img
                        key={`${item?.name}_${index}`}
                        alt={item?.name}
                        style={{ width: 130, margin: "0 2px" }}
                        src={"https://api.myhometown-ad.co.kr/" + item?.url}
                      />
                    )}
                  </>
                ))}
              </Td>
            </TableRow>
          </tbody>
        </Table>

        <ContentsTitle>
          광고주 정보{" "}
          <ContentsSubTitle>광고에 노출되지 않습니다.</ContentsSubTitle>
        </ContentsTitle>

        <CommercialInfoWrapper>
          <InfoHeader>
            이름 :<InfoValue>{agentADUserInfo?.store}</InfoValue>
          </InfoHeader>
          <InfoHeader>
            주소 :<InfoValue>{agentADUserInfo?.address}</InfoValue>
          </InfoHeader>
          <InfoHeader>
            메일 :<InfoValue>{agentADUserInfo?.email}</InfoValue>
          </InfoHeader>
          <InfoHeader>
            업종 :<InfoValue>{agentADUserInfo?.businessType}</InfoValue>
          </InfoHeader>
        </CommercialInfoWrapper>

        {/*<ButtonsWrapper>*/}
        {/*  <SignUpButton onClick={() => permitCommercial(1)}>*/}
        {/*    승인*/}
        {/*  </SignUpButton>*/}

        {/*  <CancelButton onClick={() => permitCommercial(2)}>*/}
        {/*    거절*/}
        {/*  </CancelButton>*/}
        {/*</ButtonsWrapper>*/}
      </ModalWrapper>
    </>
  );
};

const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 800px;
  width: 580px;
  min-height: 411px;
  background-color: #fff;
  overflow-y: auto;
  border-radius: 8px;
  padding: 24px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const CloseIconSVG = styled.svg`
  width: 24px;
  cursor: pointer;
`;

const ModalTitle = styled.div`
  font-family: Pretendard, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 21.48px;
  color: #2e2559;
`;

const Table = styled.table`
  width: 100%;
  margin-top: 8px;
  border-collapse: collapse;
  border: 1px solid #ccc;
`;

const TableRow = styled.tr`
  min-height: 40px;
`;

const Th = styled.th`
  padding: 10px;
  text-align: left;
  width: 22%;
  border: 1px solid #ccc;
  background-color: #f4f2ff;
  vertical-align: middle;
`;

const ThLabel = styled.div`
  font-family: Pretendard, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16.71px;
  color: #00000099;
`;

const RequireIcon = styled.p`
  color: #f15151;
`;

const Td = styled.td`
  padding: 10px;
  text-align: left;
  width: 78%;
  border: 1px solid #ccc;
`;

const ContentsTitle = styled.div`
  width: 100%;
  margin-top: 20px;
  font-family: Pretendard, sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 17.9px;
  color: #000000cc;
  display: flex;
  gap: 8px;
`;

const ContentsSubTitle = styled.div`
  font-family: Pretendard, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.75px;
  color: #00000080;
`;

const CommercialInfoWrapper = styled.div`
  width: 532px;
  height: 102px;
  background-color: #f5f5f5;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  //padding-top: 8px;
  //padding-left: 10px;
`;

const InfoHeader = styled.div`
  display: flex;
  gap: 6px;
  font-family: Pretendard, sans-serif;
  font-size: 14px;
  line-height: 16.75px;
  color: #00000099;
`;

const InfoValue = styled.div`
  font-family: Pretendard, sans-serif;
  font-size: 14px;
  line-height: 16.75px;
  color: #000000;
`;

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 6px;
`;

const SignUpButton = styled.div`
  width: 100px;
  height: 40px;
  padding: 11px;
  background-color: #530dad;
  color: #fff;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
  font-size: 15px;
  line-height: 17.9px;
  font-family: Pretendard, sans-serif;
`;

const CancelButton = styled.div`
  width: 100px;
  height: 40px;
  padding: 11px;
  border: 1px solid #000000;
  color: #000000;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
  font-size: 15px;
  line-height: 17.9px;
  font-family: Pretendard, sans-serif;
`;
