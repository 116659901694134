import React, {useState} from 'react'
import {Display} from "../../../CRM/organism/Main/styles";
import {CalculationFilter} from "./CalculationFilter";
import {CalculationTable} from "./CalculationTable";
import { useOrderList } from '../../../CRM/hooks/Tosme/OperateManage/Sales/OrderManage/GetOrderList'
import styled from "styled-components";
import {useAgentRefundList} from "../../hooks/Agent/Calculation/GetAgentRefundList";
import {message} from "antd";
import API from "../../../utils/api";
import {useToken} from "../../../app/hooks";


export const Calculation = () => {
  // 필터링 관련
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [status, setStatus] = useState(0); // 1: 처리중 | 2:처리완료

  // 데이터 조회
  const { agentRefundList, mutateAgentRefundList, total, isLoading } = useAgentRefundList(page, pageSize, status)
  const {data: token} = useToken();


  const postAgentRefund = () => {
    const shouldEdit = window.confirm(`종료된 건에 대한 정산신청하시겠습니까?`);
    if (shouldEdit) {
      API.post('/agent/refund/list', token + '', {
      })
        .then((res: any) => {
          if (res.result.success) {
            message.success(`정산신청되었습니다.`);
            mutateAgentRefundList();
          } else {
            message.error(res.result.message || `실패하였습니다.`);
          }
        })
        .catch((err: any) => {
          message.error('실패하였습니다.')
        })
    }
  }


  return(
    <Display>
      {/*<RegistSection>*/}
      {/*  <RegistSaleButton onClick={() => {*/}
      {/*    postAgentRefund();*/}
      {/*  }}>정산 신청</RegistSaleButton>*/}
      {/*</RegistSection>*/}

      <CalculationFilter
        setStatus={setStatus}
      />
      <CalculationTable
        agentRefundList={agentRefundList}
        mutateAgentRefundList={mutateAgentRefundList}
        isLoading={isLoading}
        total={total}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </Display>
  )
}

const RegistSection = styled.div`
    display: flex;
    justify-content: flex-end;
`

const RegistSaleButton = styled.div`
    background-color: #530DAD;
    box-shadow: 0px 0px 10px 0px #0000000D;
    width: 140px;
    height: 42px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    
    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard ,sans-serif;
`

