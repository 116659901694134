import React, {startTransition, useEffect, useState} from 'react'
import {Blacked} from "../../../../CRM/components/styles";
import {ReactComponent as ModalCloseIcon} from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import styled from "styled-components";
import {message, Select, TimePicker} from "antd";
import API from "../../../../utils/api";
import TextArea from "antd/es/input/TextArea";
import {SelectAddressItem} from "../../../../HomeTown/Customer/MediaList/blocks/SelectAddressItem";
import {useBankList} from "../../../../HomeTown/hooks/Agent/GetBankList";
import {useToken} from "../../../../app/hooks";
import {HomeTownRoundCheckbox} from "../../../../app/organisms/Auth/Forms/HomeTownRoundCheckbox";


export const RegisteModal = ({setIsRegisterOpen}) => {
  const [cardSelected, setCardSelected] = React.useState('customerCard');
  const {data: token} = useToken();

  // 유틸리티
  const [transWeeks, setTransWeeks] = React.useState('');
  const [openTime, setOpenTime] = React.useState<any>('');
  const [closeTime, setCloseTime] = React.useState<any>('');
  const [imageUrl, setImageUrl] = React.useState<any>('');


  const [fileInputKey, setFileInputKey] = useState(Date.now()); // 파일 초기화


  // 회원가입 정보
  const [store, setStore] = useState('');
  const [address, setAddress] = useState('');
  const [detailAddress, setDetailAddress] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [memo, setMemo] = useState('');
  const [fileList, setFileList] = React.useState<any>([]);


  // const [account, setAccount] = useState('');
  // const [password, setPassword] = useState('');
  // const [businessType, setBusinessType] = useState('');
  // const [mediaInfo, setMediaInfo] = React.useState(1);
  // const [mediaType, setMediaType] = React.useState(0);
  // const [realestateRegistrationNumber, setRealestateRegistrationNumber] = React.useState('');
  // const [businessRegistrationNumber, setBusinessRegistrationNumber] = React.useState('');
  // const [footTrafficGrade, setFootTrafficGrade] = React.useState('');
  // const [region, setRegion] = useState('');
  // const [bank, setBank] = useState<any>(null);


  // 은행 리스트
  const {bankList} = useBankList();

  // useEffect(() => {
  //   console.log('bankList', bankList)
  // }, [bankList]);



  const openPostcode = () => {
    new window.daum.Postcode({
      oncomplete: function (data: any) {
        const fullAddress = data?.address;
        startTransition(() => {
          setAddress(fullAddress);
          // // 좌표 변환 API 호출
          // handleGeocode(fullAddress);
        });
      }
    }).open();
  };


  const UploadData = (file, type, fileType) => {
    if (!file) {
      message.error('파일을 선택하세요');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);


    API.postImage('POST', '/content?type=user', '', formData)
      .then((res) => {
        if (res.message === 'success') {
          message.success('파일이 추가되었습니다.');
          console.log(res)

          const url = res?.result?.url;
          const name = res?.result?.name;

          setFileList([...fileList, {type, fileType, url, name}])
          setFileInputKey(Date.now())
        } else {
          message.error('파일이 잘못되었습니다');
        }
      })
      .catch((err) => {
        message.error('업로드 중 오류가 발생했습니다');
        console.log('Upload Data ERROR',err)
      });
  };




  const clickRegist = () => {
    const shouldEdit = window.confirm(`광고물 제작 업체 등록을 요청하시겠습니까?`);

    if(shouldEdit) {
      // const businessHours = transWeeks + '/*-/' + openTime.format('HH') + '~' + closeTime.format('HH');
      const IMGCount = fileList.filter(file => file.type === 'IMG').length > 3

      if(!name && !phone && !store && !address && !email && !IMGCount && !detailAddress) {
        message.error('필수 사항을 입력해주세요.')
        // console.log(name, phone , store , address , email , account , password , mediaInfo , mediaType , businessHours , businessRegistrationNumber , IMGCount , ACount , BCount)
      } else {
        API.post('/auth/signup', '', {
          userType:3,
          email,
          name,
          phone,
          store,
          address,
          detailAddress,
          fileList,
          password: '0000',
        })
          .then((res: any) => {
            if (res.result.success) {
              message.success('광고물 제작 업체 등록을 요청하였습니다.');
              setIsRegisterOpen(false);
            } else {
              message.error( res.result.message || '광고물 제작 업체 등록 요청에 실패하였습니다.');
            }
          })
          .catch((err: any) => {
            message.error('시스템 에러');
          })
      }
    }
  }


  return (
    <>
      <Blacked onClick={() => setIsRegisterOpen(false)}/>
      <ModalWrapper className={"scroll-container"}>
        <ModalHeader>
          <ModalTitle>광고물 제작 업체 등록 신청</ModalTitle>
          <CloseIconSVG as={ModalCloseIcon} onClick={() => {setIsRegisterOpen(false)}  }/>
        </ModalHeader>

        <Table>
          <tbody>
          <TableRow>
            <Th>
              <ThLabel>
                업체명
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td><Input type="text" value={store} onChange={e => setStore(e.target.value)}/></Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                주소
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td>
              <TdRowContents>
                <GreyButton onClick={() => openPostcode()}>주소검색</GreyButton>
                <Input type="text" value={address} onChange={e => setAddress(e.target.value)}/>
              </TdRowContents>
            </Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                세부주소
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td><Input type="text" value={detailAddress} onChange={e => setDetailAddress(e.target.value)}/></Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                담당자명(대표명)
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td><Input type="text" value={name} onChange={e => setName(e.target.value)}/></Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                메일
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td><Input type="text" placeholder={'test@gmail.com'} value={email} onChange={e => setEmail(e.target.value)}/></Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                연락처
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td>
              <TdRowContents style={{paddingRight: 167}}>
                <Input type="text" value={phone} onChange={e => setPhone(e.target.value)} placeholder={'ex. 010-1234-1234'}/>
                {/*<GreyButton>휴대폰인증</GreyButton>*/}
              </TdRowContents>
            </Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                포트폴리오
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td>
              <FileInput key={fileInputKey} type="file" onChange={({target: {files}}) => {
                files?.length && UploadData(files[0], 'IMG', 0);
              }}/>

              {fileList?.filter(it => it?.type === 'IMG')?.map((item: any, index: number) => (
                <DataText key={item?.url}>
                  {item?.name}
                  <DeleteItemIconSVG as={ModalCloseIcon} onClick={() => {
                    setFileList(prev => {
                      const newFileList = [...prev];
                      const prevIndex = newFileList.findIndex(i => i.url === item?.url);
                      if (prevIndex !== -1) {
                        newFileList.splice(prevIndex, 1);
                      }
                      return newFileList;
                    });
                  }}/>
                </DataText>
              ))}

              <DescText>
                {`사진 최대 3장까지 가능`}
              </DescText>
            </Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                서비스 설명
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td>
              <TdRowContents>
                <TextArea value={memo} onChange={e => setMemo(e.target.value)}/>
              </TdRowContents>
            </Td>
          </TableRow>
          </tbody>
        </Table>

        <SignUpButton onClick={() => clickRegist()}>
          신청
        </SignUpButton>

      </ModalWrapper>
    </>
  )
}


const DataText = styled.div`
    font-weight: 400;
    font-size: 14px;
    font-family: Pretendard, sans-serif;
    line-height: 16.71px;
    color: #000000;
    margin-top: 8px;
    white-space: pre-wrap;
`


const DeleteItemIconSVG = styled.svg`
    width: 12px;
    cursor: pointer;
    margin-left: 10px;
`


const Desc = styled.div`
    white-space: pre-wrap;
    margin-top: 10px;
    margin-left: 5px;
    font-size: 13px;
    color: #757575;
`

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 800px;
    width: 800px;
    min-height: 411px;
    background-color: #fff;
    overflow-y: auto;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #2E2559;
`

const Table = styled.table`
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
    border: 1px solid #ccc;
`;

const TableRow = styled.tr`
    min-height: 40px;
`;

const Th = styled.th`
    padding: 10px;
    text-align: left;
    width: 22%;
    border: 1px solid #ccc;
    background-color: #F4F2FF;
`;

const ThLabel = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
    color: #00000099;
    display: flex;
    gap: 2px;
`

const RequireIcon = styled.p`
    color: #F15151;
`

const Td = styled.td`
    padding: 10px;
    text-align: left;
    width: 78%;
    border: 1px solid #ccc;
`;

const TdRowContents = styled.div`
    display: flex;
    align-items: center;

    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
    font-family: Pretendard, sans-serif;
    color: #000000;
`

const DescText = styled.div`
  font-weight: 400;
    font-size: 14px;
    font-family: Pretendard, sans-serif;
    line-height: 16.71px;
    color: #00000099;
    margin-top: 8px;
    margin-bottom: 20px;
    white-space: pre-wrap;
`

const Input = styled.input`
    width: 100%;
    height: 28px;
    padding-left: 10px;
    border: 1px solid #2E2559;
    border-radius: 4px;
    display: flex;
`;

const FileInput = styled.input`
    width: 100%;
    padding: 2px;
    border: 1px solid #2E2559;
    border-radius: 4px;
    display: flex;
`;

const GreyButton = styled.div`
    height: 31px;
    width: 120px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0000001A;
    cursor: pointer;
    color: #000000CC;
    margin: 0 4px;

    font-size: 14px;
    font-weight: 500;
    line-height: 16.71px;
`

const StyledSelect = styled(Select)`
    width: 160px;
    border: 1px solid #00000033;
    border-radius: 7px;
    
`

const SignUpButton = styled.div`
    width: 100px;
    padding: 11px;
    background-color: #530DAD;
    color: #fff;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;

const StyleTimePicker = styled(TimePicker)`
    width: 107px;
    
    &::placeholder {
        color: #000000B2;
    }
`