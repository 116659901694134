import React, {useEffect, useState} from 'react'
import {
	DBFilterDateArea,
	DBFilterWrapper,
	DBTableExcelBtn,
	DateArea,
	GotoStatus,
} from '../../../CRM/organism/DB/styles'
import { Select, Input, ConfigProvider, DatePicker, Upload, message } from 'antd'
import { RangePickerProps } from 'rc-picker/es/RangePicker.d'
import dayjs, {Dayjs} from 'dayjs'
import 'dayjs/locale/ko'
import locale from 'antd/lib/locale/ko_KR'
import { Icon } from '../../../CRM/organism/Main/styles'
import { IoSearch } from 'react-icons/io5'
import styled from "styled-components";
import {HomeTownRoundCheckbox} from "../../../app/organisms/Auth/Forms/HomeTownRoundCheckbox";
import {ReactComponent as SearchIcon} from "./assets/SearchIcon.svg";
import API from "../../../utils/api";
import {useToken} from "../../../app/hooks";


export const AdminCalculationFilter = ({setSearchWord, setOrder, setStatus, mutateAdminRefundList, order, selectedRows, setIsSelectItem, isSelectItem, setSelectedRows}) => {
	const [inputSearchWord, setInputSearchWord] = useState('')
	const [settlementDate, setSettlementDate] = useState<any>(dayjs().format('YYYY-MM-DD'))
	const [inputStatus, setInputStatus] = useState(0)

	const {data: token} = useToken();


	const isSearch = () => {
		setSearchWord(inputSearchWord);
		setStatus(inputStatus);
		mutateAdminRefundList();
	}


	const putReturnPermit = () => {
		const shouldEdit = window.confirm('정산을 완료하시겠습니까?');
		if(shouldEdit) {
			API.post('/admin/settlement', token + '', {
				settlementId: selectedRows,
				settlementDate,
			})
				.then((res: any) => {
					if (res.result.success) {
						message.success('완료되었습니다');
						mutateAdminRefundList();
						setIsSelectItem(false);
						setSelectedRows([]);
					} else {
						message.error(res.result.message || '실패하였습니다');
					}
				})
				.catch((err: any) => {
					message.error('실패하였습니다');
				})
		}
	}


	const CommercialStateItem = [
		{
			name: '상태 전체',
			value : 0
		},
		{
			name: '정산필요',
			value : 1
		},
		{
			name: '정산완료',
			value : 2
		},
	]



	return (
		<DBFilterWrapper style={{ backgroundColor: '#FAFAFC' }}>
			{isSelectItem ?
				<DBFilterDateArea
					style={{justifyContent: 'space-between', backgroundColor: '#E3E3E3', height: 60, padding: '0 16px'}}>
					<SelectItemCount>
						{`${selectedRows.length}건 선택`}
						<ButtonsWrapper>
							<StyledDataPicker
								placeholder="정산 지급 날짜"
								allowClear={true}
								onChange={e => setSettlementDate((e) ? dayjs(e).format('YYYY-MM-DD') : '')}
								value={settlementDate ? dayjs(settlementDate, 'YYYY-MM-DD') : undefined}
							/>
							<ItemButton onClick={() => putReturnPermit()}>정산완료</ItemButton>
							{/*<ItemButton style={{color: '#FF6C4B'}} >탈퇴</ItemButton>*/}
							{/*<ItemButton style={{color: '#FF6C4B'}} >삭제</ItemButton>*/}
						</ButtonsWrapper>
					</SelectItemCount>

					<SearchWrapper>
						<ItemButton  onClick={() => {
							setIsSelectItem(false);
						}}>
							취소
						</ItemButton>
					</SearchWrapper>
				</DBFilterDateArea>
			:
				<DBFilterDateArea style={{justifyContent: 'flex-end', height: 60, padding: '0 16px'}}>
					{/*<RoundCheckWrapper>*/}
					{/*	<HomeTownRoundCheckbox text={'최신순'} checked={order === 'latest'} onClick={() => setOrder('latest')}/>*/}
					{/*	<HomeTownRoundCheckbox text={'과거순'} checked={order === 'oldest'} onClick={() => setOrder('oldest')}/>*/}
					{/*</RoundCheckWrapper>*/}

					<SearchWrapper>
						<AllCheckButton onClick={() => setIsSelectItem(true)}>
							일괄처리
						</AllCheckButton>

						<StyledSelect value={inputStatus} onChange={(e) => setInputStatus(e as number)}>
							{CommercialStateItem?.map((item, index) => (
								<Select.Option value={item.value}>{item.name}</Select.Option>
							))}
						</StyledSelect>

						<StyleInput placeholder={'검색'} value={inputSearchWord} onChange={e => setInputSearchWord(e.target.value as string)} suffix={<SearchIconSVG as={SearchIcon} />} />

						<SearchButton onClick={() => isSearch()}>
							조회
						</SearchButton>
					</SearchWrapper>
				</DBFilterDateArea>
			}

		</DBFilterWrapper>
	)
}


const StyledDataPicker = styled(DatePicker)`
	input::placeholder {
		color: #676666 !important;
	}
`;

const ItemCardsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
`

const ItemCard = styled.div`
	box-shadow: 0px 0px 10px 0px #0000000D;
	border-radius: 4px;
	width: 146px;
	height: 88px;
	padding: 18px 16px;
	display: flex;
	flex-direction: column;
	gap: 10px;
`

const CardTitle = styled.div`
	font-weight: 400;
	font-size: 15px;
	line-height: 17.9px;
	font-family: Pretendard, sans-serif;
`

const CardCount = styled.div`
 text-align: right;
	font-weight: 600;
	font-size: 20px;
	line-height: 23.87px;
	font-family: Pretendard, sans-serif;
	color: #F15151;
`

const RoundCheckWrapper = styled.div`
	display: flex;
	gap: 14px;
`

const SearchWrapper = styled.div`
	display: flex;
	gap: 6px;
`

const AllCheckButton = styled.div`
	border: 1px solid #FF6C4B;
	width: 92px;
	height: 36px;
	font-weight: 400;
	font-family: Pretendard, sans-serif;
	font-size: 15px;
	line-height: 17.9px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #FF6C4B;
	cursor: pointer;
`

const SearchButton = styled.div`
	background-color: #FF6C4B;
	width: 66px;
	height: 36px;
	border-radius: 4px;
	justify-content: center;
	display: flex;
	align-items: center;
	color: white;
	font-weight: 500;
	font-size: 15px;
	line-height: 17.9px;
	cursor: pointer;
`

const StyledSelect = styled(Select)`
	width: 170px;
	height: 36px;
	border-radius: 4px;
	
`

const TemporarySave = styled.div`
	width: 146px;
	height: 41px;
	display: flex;
	border: 1px solid #00000033;
	box-shadow: 0px 0px 10px 0px #0000000D;
	align-items: center;
	justify-content: center;
	gap: 53px;
	border-radius: 4px;
	
	font-family: Pretendard, sans-serif;
	font-size: 15px;
	line-height: 17.9px;
	font-weight: 400;
`

const TemporaryWrapper = styled.div`
	
`

const TemporaryCount = styled.div`
	font-weight: 500;
	color: #F15151;
`

const PastSaleText = styled.div`
	font-weight: 400;
	font-size: 15px;
	line-height: 17.9px;
	color: #00000099;
	text-decoration: underline;
	text-align: right;
	margin-top: 6px;
`

const StyleInput = styled(Input)`
	width: 238px;
	height: 36px;

	.ant-input::placeholder {
		color: #535353; /* 원하는 색상으로 변경 */
	}
`

const SearchIconSVG = styled.svg`
	width: 20px;
`

const SelectItemCount = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
`

const ButtonsWrapper = styled.div`
    display: flex;
    gap: 6px;
`

const ItemButton = styled.div`
    padding: 9px 20px;
    min-width: 36px;
    background: #FFFFFF;
    border: 1px solid #0000004D;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
`