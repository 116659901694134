import React, {useEffect, useState} from 'react'
import {Blacked} from "../../../../CRM/components/styles";
import {ReactComponent as ModalCloseIcon} from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import {ReactComponent as CustomerCard} from "../../../../assets/HomeTownLogin/CustomerCard.svg";
import {ReactComponent as RealEstateCard} from "../../../../assets/HomeTownLogin/RealEstateCard.svg";
import styled from "styled-components";
import {DatePicker, Input, message, Select, TimePicker} from "antd";
import {HomeTownRoundCheckbox} from "../../../../app/organisms/Auth/Forms/HomeTownRoundCheckbox";
import TextArea from "antd/es/input/TextArea";
import {ReactComponent as RightArrowIcon} from "../assets/RightArrowIcon.svg";
import {PreviewMonitor} from "./PreviewMonitor";
import {ApplicationTwoStep} from "./ApplicationTwoStep";
import {useAdvertiserADItemList} from "../../../hooks/Advertiser/CustomerCommercial/GetAdvertiserADItemList";
import API from "../../../../utils/api";
import {useToken} from "../../../../app/hooks";
import dayjs from "dayjs";
import {useAdvertiserADInfo} from "../../../hooks/Advertiser/CustomerCommercial/GetAdvertiserADInfo";
import {to} from "react-spring";
import {useMyInfo} from "../../../../CRM/hooks/GetMyInfo";


export const ApplicationOneStep = ({setIsOneStepModal, setIsTwoStepModal, title, setTitle, startedAt, setStartedAt, endedAt, setEndedAt, itemId, setItemId, fileList, setFileList, days, setDays, setAdIdValue, setTotalPrice, mutateAdvertiserADList, totalPrice, adIdValue }) => {
  // 유틸리티
  const { RangePicker } = DatePicker;
  const [isPreviewModal, setIsPreviewModal] = React.useState(false);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [localFileList, setLocalFileList] = React.useState<any>([]);
  const [deleteFileList, setDeleteFileList] = React.useState<any>([]);


  // 데이터 조회
  const {advertiserADItemList} = useAdvertiserADItemList();
  const {advertiserADInfo, mutateAdvertiserADInfo} = useAdvertiserADInfo(adIdValue)
  const {data: token} = useToken();
  const { myInfo } = useMyInfo()


  useEffect(() => {
    // console.log('days', days);

    setTotalPrice(advertiserADItemList?.find(item => item?.itemId === itemId )?.price * days || 0);
  }, [days, itemId]);

  const extractDates = (period) => {
    const regex = /(\d{4}-\d{2}-\d{2})~(\d{4}-\d{2}-\d{2})/;
    const matches = period?.match(regex);
    if (matches) {
      setStartedAt(matches[1]);
      setEndedAt(matches[2]);
    }
  };

  useEffect(() => {
    // console.log('advertiserADInfo', advertiserADInfo)

    setTitle(advertiserADInfo?.title)
    setItemId(advertiserADInfo?.itemId)
    setDays(Number(advertiserADInfo?.period?.split('일')[0]))
    extractDates(advertiserADInfo?.period)
    setFileList(advertiserADInfo?.fileList)
  }, [advertiserADInfo]);




  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {

      setStartedAt(dates[0].format('YYYY-MM-DD'));
      setEndedAt(dates[1].format('YYYY-MM-DD'));

      const start = dates[0];
      const end = dates[1];
      const diff = end.diff(start, 'days');
      setDays(diff + 1);
    } else {
      setDays(0);
    }
  };


  const postAdvertiser = (type) => {
    const shouldEdit = window.confirm(type === '임시저장' ? '임시저장하시겠습니까?' : `등록하시겠습니까?`);
    if (shouldEdit) {
      API.post('/advertiser/ad/list', token + '', {
        title,
        days,
        startedAt,
        endedAt,
        itemId,
        fileList,
      })
        .then((res: any) => {
          if (res.result.success) {
            if(type === '임시저장'){
              message.success(`임시저장하였습니다.`);
              mutateAdvertiserADList();
              setIsOneStepModal(false);
              setFileList([])
            } else {
              message.success(`등록하였습니다.`);
              mutateAdvertiserADList();
              setIsTwoStepModal(true);
              setIsOneStepModal(false);
              setAdIdValue(res.result?.result?.adId || null);
              console.log('res.result?.result?.adId', res.result?.result?.adId)
              setFileList([])
            }
          } else {
            message.error(res.result.message || `실패하였습니다.`);
          }
        })
        .catch((err: any) => {
          message.error('실패하였습니다.')
        })
    }
  }



  const putAdvertiser = (type) => {
    const shouldEdit = window.confirm(type === '임시저장' ? '임시저장하시겠습니까?' : `수정하시겠습니까?`);
    if (shouldEdit) {
      API.put(`/advertiser/ad/list/${adIdValue}`, token + '', {
       datas: {
         title,
         days,
         startedAt,
         endedAt,
         itemId,
         fileList,
         price: totalPrice,
         deleteFileList
       }
      })
        .then((res: any) => {
          if (res.result.success) {
            if(type === '임시저장'){
              message.success(`임시저장하였습니다.`);
              mutateAdvertiserADList();
              setIsOneStepModal(false);
              setFileList([])
            } else {
              message.success(`수정하였습니다.`);
              mutateAdvertiserADList();
              setIsTwoStepModal(true);
              setIsOneStepModal(false);
              setFileList([])
            }
          } else {
            message.error(res.result.message || `실패하였습니다.`);
          }
        })
        .catch((err: any) => {
          message.error('실패하였습니다.')
        })
    }
  }


  const UploadData = (file, fileType) => {
    if (fileList?.length === 1) {
      message.error('파일은 최대 1개만 허용합니다.');
      setFileInputKey(Date.now())
      return;
    }

    if (!file) {
      message.error('파일을 선택하세요');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);


    API.postImage('POST', '/content?type=user', '', formData)
      .then((res) => {
        if (res.message === 'success') {
          message.success('파일이 추가되었습니다.');
          console.log(res);

          const url = res?.result?.url;
          const name = res?.result?.name;

          setFileList([{name, fileType, url}])
          setFileInputKey(Date.now())
        } else {
          message.error('파일이 잘못되었습니다');
        }
      })
      .catch((err) => {
        message.error('업로드 중 오류가 발생했습니다');
        console.log('Upload Data ERROR',err);
      });
  };

  useEffect(() => {
    console.log('adIdValue', adIdValue)
  }, [adIdValue]);


  return (
    <>
      <Blacked onClick={() => {setIsOneStepModal(false); setFileList([]);}}/>
      <ModalWrapper className={"scroll-container"}>
        <ModalHeader>
          <div style={{padding: 12}}/>
          <ModalTitle>광고신청</ModalTitle>
          <CloseIconSVG as={ModalCloseIcon} onClick={() => {setIsOneStepModal(false); setFileList([])}  }/>
        </ModalHeader>

        <ProcessWrapper>
          <StepWrapper>
            <StepNumber Active={true}>1</StepNumber>
            <StepTitle Active={true}>광고내용입력</StepTitle>
          </StepWrapper>

          <ArrowSVG as={RightArrowIcon} />

          <StepWrapper>
            <StepNumber>2</StepNumber>
            <StepTitle>광고매체선택</StepTitle>
          </StepWrapper>

          <ArrowSVG as={RightArrowIcon} />

          <StepWrapper>
            <StepNumber>3</StepNumber>
            <StepTitle>신청완료 및 승인대기</StepTitle>
          </StepWrapper>

          {/*<ArrowSVG as={RightArrowIcon} />*/}

          {/*<StepWrapper>*/}
          {/*  <StepNumber>4</StepNumber>*/}
          {/*  <StepTitle>승인완료 및 자동결제(예치금차감)</StepTitle>*/}
          {/*</StepWrapper>*/}
        </ProcessWrapper>

        <ContentsSection>
          <ContentsTitle>광고 상세 정보 입력</ContentsTitle>

          <Table>
            <tbody>
            <TableRow>
              <Th>
                <ThLabel>
                  광고명
                </ThLabel>
              </Th>
              <Td><InputText type="text" value={title} onChange={e => setTitle(e.target.value as string)}/></Td>
            </TableRow>
            <TableRow>
              <Th>
                <ThLabel>
                  광고기간
                </ThLabel>
              </Th>
              <Td>
               <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                 <div>{`${days || 0}일 / `}</div>
                 <RangePicker
                   onChange={handleDateChange}
                   placeholder={['시작일', '종료일']}
                   format="YYYY-MM-DD"
                   value={[
                     startedAt ? dayjs(startedAt, "YYYY-MM-DD") : null,
                     endedAt ? dayjs(endedAt, "YYYY-MM-DD") : null
                   ]}
                 />
               </div>
              </Td>
            </TableRow>
            <TableRow>
              <Th>
                <ThLabel>
                  광고상품
                </ThLabel>
              </Th>
              <Td>
                {myInfo?.premium === 0 ?
                  '( 무료 )'
                  :
                  <StyledSelect value={itemId} onChange={(e) => setItemId(e as number)} placeholder={'선택'}>
                    {advertiserADItemList?.map((item, index) => (
                      <Select.Option value={item?.itemId}>
                        {`${item?.title} - 일 ${item?.price?.toLocaleString('en-US')}원`}
                      </Select.Option>
                    ))}
                  </StyledSelect>
                }
              </Td>
            </TableRow>
            <TableRow>
              <Th>
                <ThLabel>
                  광고료
                </ThLabel>
              </Th>
              <Td>
                {myInfo?.premium === 0 ?
                  '( 무료 )'
                  :
                  `광고처 1곳당 ${(advertiserADItemList?.find(item => item?.itemId === itemId )?.price * days || 0).toLocaleString('en-US')}원`
                }
              </Td>
            </TableRow>
            <TableRow>
              <Th>
                <ThLabel>
                  <div>
                    첨부파일
                    <div style={{fontSize: 13}}>이미지 1개 가능</div>
                    <div onClick={() => setIsPreviewModal(true)}
                         style={{color: '#530DAD', textDecoration: 'underline', marginTop: 10, cursor: 'pointer'}}>광고미리보기
                    </div>
                  </div>
                </ThLabel>
              </Th>
              <Td>
                <div>
                  <FileInput key={fileInputKey} type="file" onChange={({target: {files}}) => {
                    files?.length && UploadData(files[0], 0)
                  }}/>

                  {/*{localFileList?.map((item: any, index: number) => (*/}
                  {/*  <DataText key={item?.url}>*/}
                  {/*    {item?.name}*/}
                  {/*    <DeleteItemIconSVG as={ModalCloseIcon} onClick={() => {*/}
                  {/*      setDeleteFileList([...deleteFileList, item?.urlId]);*/}
                  {/*      setLocalFileList(prev => {*/}
                  {/*        const newFileList = [...prev];*/}
                  {/*        const prevIndex = newFileList.findIndex(i => i.urlId === item?.urlId);*/}
                  {/*        if (prevIndex !== -1) {*/}
                  {/*          newFileList.splice(prevIndex, 1);*/}
                  {/*        }*/}
                  {/*        return newFileList;*/}
                  {/*      });*/}
                  {/*    }}/>*/}
                  {/*  </DataText>*/}
                  {/*))}*/}

                  {fileList?.map((item: any, index: number) => (
                    <DataText key={item?.url}>
                      {item?.name}
                      <DeleteItemIconSVG as={ModalCloseIcon} onClick={() => {
                        setDeleteFileList([...deleteFileList, item?.urlId]);

                        setFileList(prev => {
                          const newFileList = [...prev];
                          const prevIndex = newFileList.findIndex(i => i.url === item?.url);
                          if (prevIndex !== -1) {
                            newFileList.splice(prevIndex, 1);
                          }
                          return newFileList;
                        });
                      }}/>
                    </DataText>
                  ))}

                </div>
              </Td>
            </TableRow>
            </tbody>
          </Table>

        </ContentsSection>



          <ButtonWrapper>
            <TempSaveButton onClick={() =>{
              if(adIdValue) {
                putAdvertiser('임시저장');
              } else {
                postAdvertiser('임시저장');
              }
            }}>
              임시저장
            </TempSaveButton>

            {fileList ?
              <SignUpButton onClick={() => {
                if(adIdValue) {
                  putAdvertiser('다음');
                } else {
                  postAdvertiser('다음');
                }
              }}>
                다음
              </SignUpButton>

              :
              <CancelButton style={{cursor: 'not-allowed', backgroundColor: '#dad8d8', color: '#7e7c7c', border: '1px solid #7e7c7c'}}>다음</CancelButton>
            }

            <CancelButton onClick={() => {setIsOneStepModal(false); setFileList([]);}}>취소</CancelButton>
          </ButtonWrapper>


      </ModalWrapper>

      {/*{isEditModal &&  <CommercialEdit setIsEditModal={setIsEditModal} />}*/}
      {/*{isPauseModal &&  <CommercialPause setIsPauseModal={setIsPauseModal} />}*/}
      {isPreviewModal &&  <PreviewMonitor setIsPreviewModal={setIsPreviewModal} itemId={0} advertiserADList={fileList} />}
    </>
  )
}


const DataText = styled.div`
    font-weight: 400;
    font-size: 14px;
    font-family: Pretendard, sans-serif;
    line-height: 16.71px;
    color: #000000;
    margin-top: 8px;
    white-space: pre-wrap;
`


const DeleteItemIconSVG = styled.svg`
    width: 12px;
    cursor: pointer;
    margin-left: 10px;
`

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 800px;
    width: 880px;
    min-height: 411px;
    background-color: #fff;
    overflow-y: auto;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 700;
    font-size: 30px;
    line-height: 35.8px;
    color: #2E2559;
`

const Table = styled.table`
    width: 100%;
    margin-top: 8px;
    border-collapse: collapse;
    border: 1px solid #ccc;
`;

const TableRow = styled.tr`
    min-height: 40px;
`;

const Th = styled.th`
    padding: 10px;
    text-align: left;
    width: 26%;
    border: 1px solid #ccc;
    background-color: #F4F2FF;
    vertical-align: middle;
`;

const ThLabel = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
    color: #00000099;
    display: flex;
    gap: 2px;
`

const RequireIcon = styled.p`
    color: #F15151;
`

const Td = styled.td`
    padding: 10px;
    text-align: left;
    border: 1px solid #ccc;
`;

const TdRowContents = styled.div`
    display: flex;
    align-items: center;

    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
    font-family: Pretendard, sans-serif;
    color: #000000;
`

const DescText = styled.div`
  font-weight: 400;
    font-size: 14px;
    font-family: Pretendard, sans-serif;
    line-height: 16.71px;
    color: #00000099;
    margin-top: 8px;
    margin-bottom: 20px;
    white-space: pre-wrap;
`

const InputText = styled(Input)`
    width: 100%;
    height: 28px;
    padding-left: 10px;
    border: 1px solid #2E2559;
    border-radius: 4px;
    display: flex;

    &::placeholder {
        color: #535353;
    }
`;

const FileInput = styled(Input)`
    width: 100%;
    padding: 2px;
    border: 1px solid #2E2559;
    border-radius: 4px;
    display: flex;
`;


const StyledSelect = styled(Select)`
    width: 100%;
    border: 1px solid #00000033;
    border-radius: 7px;
`

const SignUpButton = styled.div`
    width: 100px;
    padding: 11px;
    background-color: #530DAD;
    color: #fff;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;

const EdtiButton = styled.div`
    width: 100px;
    padding: 11px;
    background-color: #FF6C4B;
    color: #fff;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;

const ContentsTitle = styled.div`
    width: 100%;
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #000000CC;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
`

const ContentsSection = styled.div`
    width: 793px;
    background-color: #FAFAFC;
    padding: 40px 106.5px;
`

const ContentsSubTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.75px;
    color: #00000080;
`


const CommercialInfoWrapper = styled.div`
    width: 532px;
    height: 102px;
    background-color: #F5F5F5;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
    //padding-top: 8px;
    //padding-left: 10px;
`

const InfoHeader = styled.div`
    display: flex;
    gap: 6px;
    font-family: Pretendard, sans-serif;
    font-size: 14px;
    line-height: 16.75px;
    color: #00000099;
    align-items: center;
`

const InfoInput = styled(Input)`
    width: 459px;
    height: 25px;
`

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 5px;
    gap: 6px;
`

const ButtonLeftSide = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 6px;
`

const TempSaveButton = styled.div`
    width: 100px;
    padding: 11px;
    color: #FF6C4B;
    border: 1px solid #FF6C4B;
    border-radius: 2px;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`

const PreviewButton = styled.div`
    font-weight: 400;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
    text-decoration: underline;
    cursor: pointer;
`

const CancelButton = styled.div`
    width: 100px;
    padding: 11px;
    border: 1px solid #000000;
    border-radius: 2px;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`

const ProcessWrapper = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    margin-top: 45.5px;
    margin-bottom: 67.5px;
`

const ArrowSVG = styled.svg`

`

const StepWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`

const StepNumber = styled.div<{Active?:boolean}>`
    background-color: ${(props) => props.Active ? '#2E1155' : '#BFBFBF' };
    border-radius: 50px;
    width: 18px;
    height: 18px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-size: 14px;
    font-weight: bold;
`

const StepTitle = styled.div<{Active?:boolean}>`
    font-family: Pretendard, sans-serif;
    font-weight: ${(props) => props.Active ? '400' : '600' };
    font-size: 16px;
    line-height: 19.09px;
    color: ${(props) => props.Active ? '#000000' : '#000000B2' };
`