import React from 'react';
import styled from 'styled-components';
import {useNavigate} from "react-router-dom";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    text-align: center;
    padding: 20px;
    background-color: #f9f9f9;
`;

const Message = styled.h1`
    font-size: 1.5em;
    color: #333;
`;

const Button = styled.button`
    background: #007bff;
    color: white;
    padding: 10px 20px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;

    &:hover {
        background: #0056b3;
    }
`;

const ButtonWrap = styled.div`
  display: flex;
    gap: 5px;
`

export const SuccessRegister = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Message>등록이 완료되었습니다.</Message>

      <ButtonWrap>
        <Button onClick={() => navigate('/admin/login')}>로그인</Button>
        <Button onClick={() => navigate('/mobile')}>매물추가하기</Button>
      </ButtonWrap>
    </Container>
  );
};

