import React, {useState} from 'react'
import {Display} from "../../../CRM/organism/Main/styles";
import {TransactionFilter} from "./TransactionFilter";
import {TransactionTable} from "./TransactionTable";
import { useOrderList } from '../../../CRM/hooks/Tosme/OperateManage/Sales/OrderManage/GetOrderList'
import styled from "styled-components";
import {useAgentPaymentList} from "../../hooks/Agent/Transaction/GetPaymentList";
import {useAgentPaymentSubList} from "../../hooks/Agent/Transaction/GetPaymentSubsList";
import {PaymentModal} from "./blocks/PaymentModal";


export const Transaction = () => {
  // 필터링 관련
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [status, setStatus] = useState(0); // 1: 결제필요 | 2:결제완료

  const [modalOpen, setModalOpen] = useState(false);

  const { agentPaymentList, mutateAgentPaymentList, total, isLoading } = useAgentPaymentList(page, pageSize, status);
  const { agentPaymentSubList } = useAgentPaymentSubList();


  return(
    <Display>
      <RegistSection>
        <ProductDesc>
          디지털알리미 울동네 프로그램을 사용하는데에 따른
          월 구독료 및 연간 구독료 납부현황을 확인할 수 있습니다.
        </ProductDesc>

        <RegistSaleButton onClick={() => {
          setModalOpen(true);
        }}>구독료 납부</RegistSaleButton>
      </RegistSection>

      <TransactionFilter
        setStatus={setStatus}
      />
      <TransactionTable
        agentPaymentList={agentPaymentList}
        mutateAgentPaymentList={mutateAgentPaymentList}
        setPage={setPage}
        page={page}
        pageSize={pageSize}
        setPageSize={setPageSize}
        total={total}
        isLoading={isLoading}
      />

      {modalOpen &&  <PaymentModal setModalOpen={setModalOpen} />}
    </Display>
  )
}

const ProductDesc = styled.div`
    font-weight: 600;
    
`

const RegistSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const RegistSaleButton = styled.div`
    background-color: #530DAD;
    box-shadow: 0px 0px 10px 0px #0000000D;
    width: 140px;
    height: 42px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;

    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard ,sans-serif;
`

