import {Checkbox, Table} from 'antd';
import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {DBFilterCheckboxArea, DBFilterWrapper} from '../../../CRM/organism/DB/styles';
import {useToken} from "../../../app/hooks";
import {HomeTownRoundCheckbox} from "../../../app/organisms/Auth/Forms/HomeTownRoundCheckbox";
import {DummyData} from "../../../utils/DummyData";
import {CustomerDetail} from "./blocks/CustomerDetail";
import {RealEstateDetail} from "./blocks/RealEstateDetail";
import {RealEstateInfo} from "../../Customer/CustomerCommercial/blocks/RealEstateInfo";
import {RequestUserInfo} from "./blocks/RequestUserInfo";
import {formatPhoneNumber} from "../../hooks/util/formatPhoneNumber";


export const UserManageTable = ({
                                  mutateUserList,
                                  orderList,
                                  categoryItem,
                                  setIsSelectItem,
                                  isSelectItem,
                                  selectedRows,
                                  setSelectedRows,
                                  isAllChecked,
                                  setIsAllChecked,
                                  isLoading,
    page, pageSize, setPage, setPageSize, total
                                }) => {

  const [customerId, setCustomerId] = useState(null);
  const [realEstateId, setRealEstateId] = useState(null);
  const [requestId, setRequestId] = useState(null);

  const {data: token} = useToken();


  useEffect(() => {
    const allSelected = orderList?.length > 0 && orderList?.every(it => selectedRows?.includes(it.userId));
    setIsAllChecked(allSelected);
  }, [selectedRows, orderList]);


  const handleCheckboxChange = (e, id) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows?.filter(rowId => rowId !== id));
    }
  };


  const handleSelectAllChange = (e) => {
    setIsAllChecked(e.target.checked);
    const ids = orderList?.map(db => db.userId);
    setSelectedRows(e.target.checked ? ids : []);
  };

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };



  const customerColumns = [
    {
      dataIndex: 'userId',
      title: isSelectItem ?
        (<Checkbox
          checked={isAllChecked}
          onChange={handleSelectAllChange}
        />)
        :
        'No'
      ,
      render: (value, data, index) => {
        return (
          <>
            {isSelectItem ?
              (<div className='checkbox' style={{justifyContent: 'center', display: 'flex'}}>
                <Checkbox checked={selectedRows?.includes(data.userId)} key={data?.userId}
                          onChange={(e) => handleCheckboxChange(e, data?.userId)}/>
              </div>)
              :
              <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{ value || '-'}</div>
            }
          </>
        )
      }
    },
    {
      dataIndex: 'name',
      title: '회원명',
      render: (value, data) => {
        return (
          <div className='email'
               style={{ justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'email',
      title: '아이디',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'store',
      title: '상호명',
      render: (value, data) => {
        return (
          <div className='email' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'address',
      title: '주소',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'phone',
      title: '휴대폰번호',
      render: (value, data, index) => {
        return (
          <div className='No.'
               style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'adCount',
      title: '진행중광고',
      render: (value, data) => {
        return (
          <div className='type' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '계정보기',
      render: (value, data) => {
        return (
          <div className='date' onClick={() => setCustomerId(data?.userId)}
               style={{justifyContent: 'center', display: 'flex', color: '#9747FF', cursor: 'pointer'}}>이동하기</div>
        )
      }
    },
    // {
    //   dataIndex: 'premium',
    //   title: '사용자 관리(유료)',
    //   render: (value, data) => {
    //     return (
    //       <div className='date' style={{justifyContent: 'center', display: 'flex'}}>
    //         <HomeTownRoundCheckbox text={''} checked={value === 1} />
    //       </div>
    //     )
    //   }
    // },
    {
      dataIndex: 'premium',
      title: '사용자 관리',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>
            {/*<HomeTownRoundCheckbox text={''} checked={value === 0}/>*/}
            {value === 0 ? '무료' : '유료'}
          </div>
        )
      }
    },
    {
      dataIndex: 'premiumChangeDate',
      title: '사용자 관리  (유료전환예정일)',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'memberStatus',
      title: '상태',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
            <div>{value}</div>
            {value === '탈퇴' && <div style={{}}>{data?.resignedAt || '-'}</div>}
          </div>
        )
      }
    },
  ];

  const realEstateColumns = [
    {
      dataIndex: 'userId',
      title: isSelectItem ?
        (<Checkbox
          checked={isAllChecked}
          onChange={handleSelectAllChange}
        />)
        :
        'No'
      ,
      render: (value, data, index) => {
        return (
          <>
            {isSelectItem ?
              (<div className='checkbox' style={{justifyContent: 'center', display: 'flex'}}>
                <Checkbox checked={selectedRows?.includes(data.userId)} key={data?.userId}
                          onChange={(e) => handleCheckboxChange(e, data?.userId)}/>
              </div>)
              :
              <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
            }
          </>
        )
      }
    },
    {
      dataIndex: 'name',
      title: '회원명',
      render: (value, data) => {
        return (
          <div className='email' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'email',
      title: '아이디',
      render: (value, data, index) => {
        return (
          <div className='No.'
               style={{textDecoration: 'underline', justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'store',
      title: '상호명',
      render: (value, data) => {
        return (
          <div className='email' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'address',
      title: '주소',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'phone',
      title: '휴대폰번호',
      render: (value, data, index) => {
        return (
          <div className='No.'
               style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'adCount',
      title: '진행중광고',
      render: (value, data) => {
        return (
          <div className='type' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '계정보기',
      render: (value, data) => {
        return (
          <div className='date' onClick={() => setRealEstateId(data?.userId)}
               style={{justifyContent: 'center', display: 'flex', color: '#9747FF', cursor: 'pointer'}}>이동하기</div>
        )
      }
    },
    // {
    //   dataIndex: 'premium',
    //   title: '사용자 관리(유료)',
    //   render: (value, data) => {
    //     return (
    //       <div className='date' style={{justifyContent: 'center', display: 'flex'}}>
    //         <HomeTownRoundCheckbox text={''} checked={value === 1}/>
    //       </div>
    //     )
    //   }
    // },
    {
      dataIndex: 'premium',
      title: '사용자 관리',
      render: (value, data) => {
        return (
          <div className='date' style={{
            justifyContent: 'center', display: 'flex',
            color: value === 0 ? '#000000' : '#00ff55'
          }}>
            {/*<HomeTownRoundCheckbox text={''} checked={value === 0}/>*/}
            {value === 0 ? '무료' : '유료'}
          </div>
        )
      }
    },
    {
      dataIndex: 'premiumChangeDate',
      title: '사용자 관리 (유료전환예정일)',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'roleId',
      title: '권한관리(외부)',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>
            {/*<HomeTownRoundCheckbox text={''} checked={value === 1}/>*/}
            {(value === 1) && '외부' || (value === 2) && '매물' || (value === 3) && '외부+매물'}
          </div>
        )
      }
    },
    // {
    //   dataIndex: 'roleId',
    //   title: '권한관리 (매물)',
    //   render: (value, data) => {
    //     return (
    //       <div className='date' style={{justifyContent: 'center', display: 'flex'}}>
    //         <HomeTownRoundCheckbox text={''} checked={value === 2}/>
    //       </div>
    //     )
    //   }
    // },
    // {
    //   dataIndex: 'roleId',
    //   title: '권한관리 (외부+매물)',
    //   render: (value, data) => {
    //     return (
    //       <div className='date' style={{justifyContent: 'center', display: 'flex'}}>
    //         <HomeTownRoundCheckbox text={''} checked={value === 3}/>
    //       </div>
    //     )
    //   }
    // },
    {
      dataIndex: 'roleChangedAt',
      title: '권한관리 (권한변경일)',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'memberStatus',
      title: '상태',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
            <div>{value}</div>
            {value === '탈퇴' && <div style={{}}>{data?.resignedAt || '-'}</div>}
          </div>
        )
      }
    },
  ];

  const userColumns = [
    {
      dataIndex: 'userId',
      title: isSelectItem ?
        (<Checkbox
          checked={isAllChecked}
          onChange={handleSelectAllChange}
        />)
        :
        'No'
      ,
      render: (value, data, index) => {
        return (
          <>
            {isSelectItem ?
              (<div className='checkbox' style={{justifyContent: 'center', display: 'flex'}}>
                <Checkbox checked={selectedRows?.includes(data.userId)} key={data?.userId}
                          onChange={(e) => handleCheckboxChange(e, data?.userId)}/>
              </div>)
              :
              <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
            }
          </>
        )
      }
    },
    {
      dataIndex: 'name',
      title: '이름',
      render: (value, data) => {
        return (
          <div className='email' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'store',
      title: '상호명',
      render: (value, data, index) => {
        return (
          <div className='No.' onClick={() => setRequestId(data?.userId)} style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            justifyContent: 'center',
            display: 'flex'
          }}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'address',
      title: '주소',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'phone',
      title: '휴대폰번호',
      render: (value, data, index) => {
        return (
          <div className='No.'
               style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'email',
      title: '아이디',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'password',
      title: '비밀번호',
      render: (value, data) => {
        return (
          <div className='type' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'createdAt',
      title: '신청일',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'memberStatus',
      title: '가입처리',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    }
  ];

  const OutColumn = () => {
    if (categoryItem === '광고주') {
      return customerColumns
    } else if (categoryItem === '매체주') {
      return realEstateColumns
    } else {
      return userColumns
    }
  }

  return (
    <DBFilterWrapper>
      <DBFilterCheckboxArea>

        {/*<div style={{display: 'flex', gap: 10, justifyContent: 'space-between', width: '100%',}}>*/}
        {/*  <CheckboxUnit>*/}
        {/*    <DBTableExcelBtn onClick={excelDownAll}>*/}
        {/*      <Icon><FaRegFileExcel /></Icon>*/}
        {/*      엑셀다운로드*/}
        {/*    </DBTableExcelBtn>*/}
        {/*    <DBTableExcelBtn onClick={excelDownSelect}>*/}
        {/*      <Icon><FaRegFileExcel /></Icon>*/}
        {/*      선택 다운로드*/}
        {/*    </DBTableExcelBtn>*/}
        {/*  </CheckboxUnit>*/}

        {/*  <DBTableExcelBtn onClick={clickDelete} style={{backgroundColor: '#f93e3e', color: '#fff'}}>*/}
        {/*    선택 삭제*/}
        {/*  </DBTableExcelBtn>*/}
        {/*</div>*/}

      </DBFilterCheckboxArea>

      <StyleTable columns={OutColumn() || []}
                  dataSource={orderList}
                  loading={isLoading}
                  bordered={true}
                  pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: total,
                  }}
                  onChange={handleTableChange}
      />

      {customerId !== null &&
        <CustomerDetail customerId={customerId} setCustomerId={setCustomerId} mutateUserList={mutateUserList}/>}
      {realEstateId !== null && <RealEstateDetail realEstateId={realEstateId} setRealEstateId={setRealEstateId}
                                                  mutateUserList={mutateUserList}/>}
      {requestId !== null && <RequestUserInfo setRequestId={setRequestId} requestId={requestId}/>}

    </DBFilterWrapper>
  )
}

export default React.memo(UserManageTable);

const ColumnHeader = styled.div`

`

const StyleTable = styled(Table)`
    .ant-table-thead > tr > th {
        background-color: #F4F2FF;
        color: #00000099;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.71px;
    }
`
