import React, {useEffect, useState} from 'react'
import {Blacked} from "../../../../CRM/components/styles";
import {ReactComponent as ModalCloseIcon} from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import {ReactComponent as CustomerCard} from "../../../../assets/HomeTownLogin/CustomerCard.svg";
import {ReactComponent as RealEstateCard} from "../../../../assets/HomeTownLogin/RealEstateCard.svg";
import styled from "styled-components";
import {Input, message, Select, Table} from "antd";
import {HomeTownRoundCheckbox} from "../../../../app/organisms/Auth/Forms/HomeTownRoundCheckbox";
import {useAdminUserInfo} from "../../../hooks/Admin/UserManage/GetAdminUserInfo";
import {useAdminUserADList} from "../../../hooks/Admin/UserManage/GetAdminUserADList";
import {useAdminPaymentDepositInfo} from "../../../hooks/Admin/PaymentManage/GetAdminPaymentDepositInfo";
import {useAdminPaymentDetailList} from "../../../hooks/Admin/PaymentManage/GetAdminPaymentDetailList";
import {useAdminRefundDetailList} from "../../../hooks/Admin/AdminCalculation/GetAdminRefundDetailList";
import API from "../../../../utils/api";
import TextArea from "antd/es/input/TextArea";
import {useToken} from "../../../../app/hooks";


export const RegistContents = ({setRegistContentsModal, mutateAdminContentsList}) => {
    const [fileList, setFileList] = useState<any>([]);
    const [type, setType] = React.useState(1);
    const [title, setTitle] = React.useState('');
    const [desc, setDesc] = React.useState('');


    const {data: token} = useToken();


    const UploadData = (file, fileType) => {
        if (!file) {
            message.error('파일을 선택하세요');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);


        API.postImage('POST', '/content?type=user', '', formData)
          .then((res) => {
              if (res.message === 'success') {
                  message.success('파일이 추가되었습니다.');
                  console.log(res)

                  const url = res?.result?.url;
                  const name = res?.result?.name;

                  setFileList([...fileList, {name, fileType, url}])
              } else {
                  message.error('파일이 잘못되었습니다');
              }
          })
          .catch((err) => {
              message.error('업로드 중 오류가 발생했습니다');
              console.log('Upload Data ERROR',err);
          });
    };


    const postContents = () => {
        const shouldEdit = window.confirm(`콘첸츠를 등록하시겠습니까?`);
        if (shouldEdit) {
            API.post('/admin/content/list', token + '', {
                type,
                title,
                desc,
                fileList
            })
              .then((res: any) => {
                  if (res.result.success) {
                      message.success(`등록하였습니다.`);
                      setRegistContentsModal(false);
                      mutateAdminContentsList();
                  } else {
                      message.error(res.result.message || '실패하였습니다');
                  }
              })
              .catch((err: any) => {
                  message.error('실패하였습니다.')
              })
        }
    }

    const typeInfo = [
        {
            name: '광고',
            value: 1
        },
        {
            name: '매물',
            value: 2
        },
    ]


    return (
      <>
          <Blacked onClick={() => setRegistContentsModal(false)}/>
          <ModalWrapper className={"scroll-container"}>
              <ModalHeader>
                  <ModalTitle>콘텐츠 등록</ModalTitle>
                  <CloseIconSVG as={ModalCloseIcon} onClick={() => {setRegistContentsModal(false)}  }/>
              </ModalHeader>

              <CustomTable>
                  <tbody>
                  <TableRow>
                      <Th>
                          <ThLabel>
                              제목
                          </ThLabel>
                      </Th>
                      <Td colSpan={3}>
                          <InputText value={title} onChange={e => setTitle(e.target.value)} placeholder={'직접입력'} />
                      </Td>
                  </TableRow>
                  <TableRow>
                      <Th>
                          <ThLabel>
                              구분
                          </ThLabel>
                      </Th>
                      <Td colSpan={3}>
                          <StyledSelect value={type} onChange={(e: any) => setType(e)}>
                              {typeInfo.map((item, index) => (
                                <Select.Option value={item?.value}>
                                    {item?.name}
                                </Select.Option>
                              ))}
                          </StyledSelect>
                      </Td>
                  </TableRow>
                  <TableRow>
                      <Th>
                          <ThLabel>
                              파일
                          </ThLabel>
                      </Th>
                      <Td colSpan={3}>
                          <FileInput type="file" onChange={({target: {files}}) => {
                              files?.length && UploadData(files[0], 0)
                          }}/>
                      </Td>
                  </TableRow>
                  <TableRow>
                      <Th>
                          <ThLabel>
                              설명
                          </ThLabel>
                      </Th>
                      <Td colSpan={3}>
                        <TextArea value={desc} onChange={e => setDesc(e.target.value)}/>
                      </Td>
                  </TableRow>
                  </tbody>
              </CustomTable>


              <ButtonsWrapper>
                  <CancelButton onClick={() => postContents()}>
                      콘텐츠 등록
                  </CancelButton>
              </ButtonsWrapper>
          </ModalWrapper>


      </>
    )
}

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 800px;
    width: 776px;
    min-height: 411px;
    background-color: #fff;
    overflow-y: auto;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #2E2559;
`

const CustomTable = styled.table`
    width: 100%;
    margin-top: 8px;
    border-collapse: collapse;
    border: 1px solid #ccc;
`;

const TableRow = styled.tr`
    min-height: 40px;
`;

const Th = styled.th`
    padding: 10px;
    text-align: left;
    width: 25%;
    border: 1px solid #ccc;
    background-color: #F4F2FF;
    vertical-align: middle;
`;

const ThLabel = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
    color: #00000099;
    
`


const Td = styled.td`
    padding: 10px;
    text-align: left;
    // width: 78%;
    border: 1px solid #ccc;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
`;

const FileInput = styled(Input)`
    width: 100%;
    padding: 2px;
    border-radius: 4px;
    display: flex;
    margin-bottom: 12px;
`;

const ButtonsWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    gap: 6px;
`


const CancelButton = styled.div`
    width: 100px;
    height: 40px;
    padding: 11px;
    border: 1px solid #000000;
    color: #000000;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;

const StyledSelect = styled(Select)`
    width: 160px;
    border: 1px solid #00000033;
    border-radius: 7px;
`

const InputText = styled(Input)`
    height: 35px;
    padding-left: 10px;
    border-radius: 4px;
    display: flex;

    &::placeholder {
        color: #535353;
    }
`;