import React, {useEffect, useState} from 'react'
import {
  DBFilterDateArea,
  DBFilterWrapper,
} from '../../../CRM/organism/DB/styles'
import {message, Select} from 'antd'
import 'dayjs/locale/ko'
import styled from "styled-components";
import {HomeTownRoundCheckbox} from "../../../app/organisms/Auth/Forms/HomeTownRoundCheckbox";
import {useAdvertiserADSummaryList} from "../../hooks/Advertiser/CustomerCommercial/GetAdvertiserADSummaryList";
import {useAdvertiserADTempInfo} from "../../hooks/Advertiser/CustomerCommercial/GetAdvertiserADTempInfo";
import API from "../../../utils/api";
import {useToken} from "../../../app/hooks";
import axios from "axios";
import {API_URL} from "../../../app/constants/APIKEYS";


export const CustomerCommercialFilter = ({
                                           setIsSelectItem,
                                           isSelectItem,
                                           selectedRows,
                                           setSelectedRows,
                                           setOrder,
                                           order,
                                           setStatus,
                                           mutateAdvertiserADList
                                         }) => {
  // 상태값
  const [inputStatus, setInputStatus] = useState(0)

  // 데이터 조회
  const {advertiserADSummaryList, mutateAdvertiserADSummaryList} = useAdvertiserADSummaryList()
  const {advertiserADTempInfo} = useAdvertiserADTempInfo()
  const {data:token} = useToken();

  const IsSearch = () => {
    setStatus(inputStatus)
  }


  // const clickDelete = () => {
  //   const shouldEdit = window.confirm(`선택한 내역을 삭제하시겠습니까?`);
  //   if (shouldEdit) {
  //     if (selectedRows?.length == 0) {
  //       message.error('선택한 내역이 없습니다.')
  //     } else {
  //       axios.patch(API_URL + '/advertiser/ad/list', {adIds:selectedRows}, {
  //         headers: {
  //           Authorization: `Bearer ${token}`
  //         }
  //       })
  //         .then((res: any) => {
  //           console.log('삭제되었습니다', res);
  //           if (res.data.result.success) {
  //             message.success('삭제되었습니다.');
  //             setSelectedRows([]);
  //             mutateAdvertiserADList();
  //           } else {
  //             message.error(res.result.message || '실패하였습니다');
  //           }
  //         })
  //         .catch((err: any) => {
  //           message.error('삭제에 실패하였습니다.')
  //         })
  //     }
  //   }
  // }


  const clickDelete = () => {
    const shouldEdit = window.confirm(`선택한 내역을 삭제하시겠습니까?`);
    if (shouldEdit) {
      if (selectedRows?.length == 0) {
        message.error('선택한 내역이 없습니다.')
      } else {
        API.patch('/advertiser/ad/list', token+"" ,{adIds:selectedRows})
          .then((res: any) => {

            console.log('삭제되었습니다', res);

            if (res.result.success) {
              message.success('삭제되었습니다.');
              setSelectedRows([]);
              mutateAdvertiserADList();
            } else {
              message.error(res.result.message || '실패하였습니다');
            }
          })
          .catch((err: any) => {
            message.error('삭제에 실패하였습니다.')
          })
      }
    }
  }


  const CommercialStateItem = [
    {
      name: '전체 상태',
      value: 0
    },
    {
      name: '승인대기',
      value: 1
    },
    {
      name: '승인',
      value: 2
    },
    {
      name: '광고거절',
      value: 3
    },
    // {
    //   name: '재요청',
    //   value: 4
    // },
    {
      name: '광고중',
      value: 5
    },
    {
      name: '광고종료',
      value: 6
    },
    {
      name: '임시저장',
      value: 7
    }
  ]

  const adSummaryList = Array.isArray(advertiserADSummaryList) ? advertiserADSummaryList : [];


  return (
    <DBFilterWrapper style={{backgroundColor: '#FAFAFC'}}>
      <DBFilterDateArea style={{justifyContent: 'space-between'}}>
        <ItemCardsWrapper>
          {adSummaryList?.map((item, index) => (
            <ItemCard>
              <CardTitle>
                {item?.title}
              </CardTitle>
              <CardCount>
                {item?.value}
              </CardCount>
            </ItemCard>
          ))}
        </ItemCardsWrapper>

        <TemporaryWrapper>
          <TemporarySave>
            임시저장
            <TemporaryCount>{advertiserADTempInfo?.total}</TemporaryCount>
          </TemporarySave>

          {/*<PastSaleText>지난매물보기</PastSaleText>*/}
        </TemporaryWrapper>
      </DBFilterDateArea>

      {isSelectItem ?
        <DBFilterDateArea
          style={{justifyContent: 'space-between', backgroundColor: '#E3E3E3', height: 60, padding: '0 16px'}}>
          <SelectItemCount>
            {`${selectedRows.length}건 선택`}
            <ButtonsWrapper>
              <ItemButton style={{color: '#FF6C4B'}} onClick={() => clickDelete()}>삭제</ItemButton>
            </ButtonsWrapper>
          </SelectItemCount>

          <SearchWrapper>
            <ItemButton onClick={() => {
              setIsSelectItem(false);
              setSelectedRows([]);
            }}>
              취소
            </ItemButton>
          </SearchWrapper>
        </DBFilterDateArea>

      :
        <DBFilterDateArea style={{justifyContent: 'space-between', height: 60, padding: '0 16px'}}>
          <RoundCheckWrapper>
            <HomeTownRoundCheckbox text={'최신순'} checked={order === 'latest'} onClick={() => setOrder('latest')}/>
            <HomeTownRoundCheckbox text={'과거순'} checked={order === 'oldest'} onClick={() => setOrder('oldest')}/>
          </RoundCheckWrapper>

          <SearchWrapper>
            <AllCheckButton onClick={() => {
              setIsSelectItem(true);
            }}>
              일괄처리
            </AllCheckButton>

            <StyledSelect value={inputStatus} onChange={(e) => setInputStatus(e as number)}>
              {CommercialStateItem?.map((item, index) => (
                <Select.Option value={item.value}>{item.name}</Select.Option>
              ))}
            </StyledSelect>

            <SearchButton onClick={() => IsSearch()}>
              조회
            </SearchButton>
          </SearchWrapper>
        </DBFilterDateArea>

      }
    </DBFilterWrapper>
  )
}

const ItemCardsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`

const TemporarySave = styled.div`
    width: 146px;
    height: 41px;
    display: flex;
    border: 1px solid #00000033;
    box-shadow: 0px 0px 10px 0px #0000000D;
    align-items: center;
    justify-content: center;
    gap: 53px;
    border-radius: 4px;

    font-family: Pretendard, sans-serif;
    font-size: 15px;
    line-height: 17.9px;
    font-weight: 400;
`

const TemporaryWrapper = styled.div`

`

const TemporaryCount = styled.div`
	font-weight: 500;
	color: #F15151;
`


const ItemCard = styled.div`
    box-shadow: 0px 0px 10px 0px #0000000D;
    border-radius: 4px;
    width: 146px;
    height: 88px;
    padding: 18px 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const CardTitle = styled.div`
    font-weight: 400;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`

const CardCount = styled.div`
    text-align: right;
    font-weight: 600;
    font-size: 20px;
    line-height: 23.87px;
    font-family: Pretendard, sans-serif;
    color: #F15151;
`

const RoundCheckWrapper = styled.div`
    display: flex;
    gap: 14px;
`

const SearchWrapper = styled.div`
    display: flex;
    gap: 6px;
`

const AllCheckButton = styled.div`
    border: 1px solid #FF6C4B;
    width: 92px;
    height: 36px;
    font-weight: 400;
    font-family: Pretendard, sans-serif;
    font-size: 15px;
    line-height: 17.9px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FF6C4B;
    cursor: pointer;
`

const SearchButton = styled.div`
    background-color: #FF6C4B;
    width: 66px;
    height: 36px;
    border-radius: 4px;
    justify-content: center;
    display: flex;
    align-items: center;
    color: white;
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    cursor: pointer;
`

const StyledSelect = styled(Select)`
    width: 170px;
    height: 36px;
    border-radius: 4px;

`

const SelectItemCount = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
`

const ButtonsWrapper = styled.div`
    display: flex;
    gap: 6px;
    align-items: center;
`

const ItemButton = styled.div`
    padding: 9px 20px;
    min-width: 36px;
    background: #FFFFFF;
    border: 1px solid #0000004D;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
`