import React, {useEffect, useState} from 'react'
import {
	DBFilterDateArea,
	DBFilterWrapper,
	DBTableExcelBtn,
	DateArea,
	GotoStatus,
} from '../../../CRM/organism/DB/styles'
import { Select, Input, ConfigProvider, DatePicker, Upload, message } from 'antd'
import { RangePickerProps } from 'rc-picker/es/RangePicker.d'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import locale from 'antd/lib/locale/ko_KR'
import { Icon } from '../../../CRM/organism/Main/styles'
import { IoSearch } from 'react-icons/io5'
import styled from "styled-components";
import {HomeTownRoundCheckbox} from "../../../app/organisms/Auth/Forms/HomeTownRoundCheckbox";
import {ReactComponent as SearchIcon} from "./assets/SearchIcon.svg";
import {CommercialFeeList} from "./blocks/CommercialFeeList";
import {useAgentRefundAmountInfo} from "../../hooks/Agent/Calculation/GetAgentRefundAmountInfo";
import {numberWithCommas} from "../../../utils/ciutils";
import {useAgentRefundSearch} from "../../hooks/Agent/Calculation/GetAgentRefundSearch";


export const CalculationFilter = ({setStatus}) => {
	const [inputStatus, setInputStatus] = useState(null)
	const [filterSelectItems, setFilterSelectItems] = useState([])
	const [feeModal, setFeeModal] = useState(false)

	// 데이터 요청
	const {agentRefundSearch} = useAgentRefundSearch()

	useEffect(() => {
		console.log('agentRefundSearch', agentRefundSearch?.options)

		setFilterSelectItems(agentRefundSearch?.options);

	}, [agentRefundSearch]);


	const IsSearch = () => {
		setStatus(inputStatus);
	}




	return (
		<>
			<DBFilterWrapper style={{ backgroundColor: '#FAFAFC' }}>

			<DBFilterDateArea style={{justifyContent: 'space-between', height: 60, padding: '0 16px'}}>
				<RoundCheckWrapper>
					<Title>
						광고료
						{/*<BoldTitle>{numberWithCommas(agentRefundAmountInfo?.amount)} 원</BoldTitle>*/}
					</Title>

					{/*<ListBadge>*/}
					{/*	내역*/}
					{/*</ListBadge>*/}
				</RoundCheckWrapper>

				<SearchWrapper>
					{/*<DatePicker placeholder={'날짜 선택'} />*/}

					<StyledSelect value={inputStatus} onChange={(e:any) => setInputStatus(e)} placeholder={'날짜 선택'}>
						{filterSelectItems?.map((item, index) => (
							<Select.Option value={item}>{item}</Select.Option>
						))}
					</StyledSelect>

					<SearchButton onClick={() => IsSearch()}>
						조회
					</SearchButton>
				</SearchWrapper>
			</DBFilterDateArea>
		</DBFilterWrapper>

			{/*{feeModal && <CommercialFeeList setFeeModal={setFeeModal}/>}*/}
		</>
	)
}

const Title = styled.div`
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	font-family: Pretendard, sans-serif;
	display: flex;
	align-items: center;
	gap: 6px;
`

const BoldTitle = styled.div`
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
	font-family: Pretendard, sans-serif;
`

const ListBadge = styled.div`
	background-color: #F5F5F5;
	width: 32px;
	height: 25px;
	border-radius: 2px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #00000099;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	font-family: Pretendard, sans-serif;
`


const RoundCheckWrapper = styled.div`
	display: flex;
	gap: 14px;
`

const SearchWrapper = styled.div`
	display: flex;
	gap: 6px;
`



const SearchButton = styled.div`
	background-color: #FF6C4B;
	width: 66px;
	height: 36px;
	border-radius: 4px;
	justify-content: center;
	display: flex;
	align-items: center;
	color: white;
	font-weight: 500;
	font-size: 15px;
	line-height: 17.9px;
	cursor: pointer;
`

const StyledSelect = styled(Select)`
	width: 170px;
	height: 36px;
	border-radius: 4px;
	
`
