import styled from 'styled-components';
import section4Background from '../../assets/images/section4_background.png';
import externalAd from '../../assets/icons/svg/external_ad.svg';
import outdoorAd from '../../assets/icons/svg/outdoor_ad.svg';
import arrowRedIcon from '../../assets/icons/svg/arrow_red.svg';
import tv from '../../assets/images/tv.png';
import tvInside from '../../assets/gif/section4.gif';
import {DeskTopCalVW, media} from "../../../utils/CalVW";

const Section4Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 1276px;
  min-height: 1276px;
  padding: 120px 448px 120px 460px;
  background-color: #ffffff;
  gap: 60px;

    @media ${media.desktop} {
        height: ${DeskTopCalVW(1276)};
        min-height: ${DeskTopCalVW(1276)};
        padding: ${DeskTopCalVW(120)} ${DeskTopCalVW(448)} ${DeskTopCalVW(120)} ${DeskTopCalVW(460)};
        gap: ${DeskTopCalVW(60)};
    }
`;

const BackgroundImage = styled.img`
    position: absolute;
    top: 0;
    right: 0;
`;

const Title = styled.h2`
    font-size: 3rem;
    font-weight: 700;
    color: #212121;
    line-height: 60px;
    white-space: nowrap;

    @media ${media.desktop} {
        font-size: ${DeskTopCalVW(48)};
        line-height: ${DeskTopCalVW(60)};
    }
`;

const ContentContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;

    @media ${media.desktop} {
        gap: ${DeskTopCalVW(100)};
    }
`;

const ContentTopContainer = styled.div`
    display: flex;
    align-items: center;
    height: 128px;
    min-height: 128px;
    gap: 40px;

    @media ${media.desktop} {
        height: ${DeskTopCalVW(128)};
        min-height: ${DeskTopCalVW(128)};
        gap: ${DeskTopCalVW(40)};
    }
`;

const ContentTopItem = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  width: 430px;
  min-width: 430px;
  height: 100%;
  min-height: 100%;
  padding: 20px 30px;
  border: 1px solid #dddddd;
  border-radius: 24px;

    @media ${media.desktop} {
        width: ${DeskTopCalVW(430)};
        min-width: ${DeskTopCalVW(430)};
        padding: ${DeskTopCalVW(20)} ${DeskTopCalVW(30)};
        gap: ${DeskTopCalVW(15)};
    }
`;

const ContentTopItemIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background-color: #fff5f5;

    @media ${media.desktop} {
        width: ${DeskTopCalVW(88)};
        height: ${DeskTopCalVW(88)};
    }

  img {
    width: 40px;
    height: 40px;

      @media ${media.desktop} {
          width: ${DeskTopCalVW(40)};
          height: ${DeskTopCalVW(40)};
      }
  }
`;

const ContentTopItemText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

    @media ${media.desktop} {
        gap: ${DeskTopCalVW(6)};
    }

  span {
    font-size: 1.25rem;
    font-weight: 500;
    color: #212121;
    line-height: 28px;

      @media ${media.desktop} {
          font-size: ${DeskTopCalVW(20)};
          line-height: ${DeskTopCalVW(28)};
      }
  }

  h3 {
    font-size: 1.75rem;
    font-weight: 700;
    color: #212121;
    line-height: 32px;

      @media ${media.desktop} {
          font-size: ${DeskTopCalVW(28)};
          line-height: ${DeskTopCalVW(32)};
      }
  }
`;

const ContentBottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;

    @media ${media.desktop} {
        gap: ${DeskTopCalVW(12)};
    }
`;

const LeftRedArrow = styled.img`
    position: absolute;
    top: 135px;
    left: 230px;

    @media ${media.desktop} {
        top: ${DeskTopCalVW(135)};
        left: ${DeskTopCalVW(230)};
    }
`;

const RightRedArrow = styled.img`
    position: absolute;
    top: 135px;
    right: 210px;
    transform: rotateY(180deg);

    @media ${media.desktop} {
        top: ${DeskTopCalVW(135)};
        right: ${DeskTopCalVW(210)};
    }
`;

const TvWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 616px;

    @media ${media.desktop} {
        height: ${DeskTopCalVW(616)};
    }
`;

const Tv = styled.img`
    width: 1012px;
    height: 616px;

    @media ${media.desktop} {
        width: ${DeskTopCalVW(1012)};
        height: ${DeskTopCalVW(616)};
    }
`;

const TvInside = styled.img`
  position: absolute;
  width: 974px;
  height: 544px;
  top: 8px;
  left: 19px;

    @media ${media.desktop} {
        width: ${DeskTopCalVW(974)};
        height: ${DeskTopCalVW(544)};
        top: ${DeskTopCalVW(8)};
        left: ${DeskTopCalVW(19)};
    }
`;

const TvDescription = styled.span`
    font-size: 1.375rem;
    font-weight: 400;
    color: #212121;
    line-height: 30px;

    @media ${media.desktop} {
        font-size: ${DeskTopCalVW(22)};
        line-height: ${DeskTopCalVW(30)};
    }
`;


function Section4() {
  return (
    <Section4Container>
      <BackgroundImage src={section4Background} alt='section4_background' />
      <Title>디지털사이니지 디스플레이 방식</Title>
      <ContentContainer>
        <ContentTopContainer>
          <ContentTopItem>
            <ContentTopItemIcon>
              <img src={externalAd} alt='external_ad' />
            </ContentTopItemIcon>
            <ContentTopItemText>
              <span>광고를 원하는 고객의</span>
              <h3>외부광고</h3>
            </ContentTopItemText>
          </ContentTopItem>
          <ContentTopItem>
            <ContentTopItemIcon>
              <img src={outdoorAd} alt='outdoor_ad' />
            </ContentTopItemIcon>
            <ContentTopItemText>
              <span>부동산중개소의</span>
              <h3>매물광고</h3>
            </ContentTopItemText>
          </ContentTopItem>
        </ContentTopContainer>

        <ContentBottomContainer>
          <TvWrapper>
            <Tv src={tv} alt='tv' />
            <TvInside src={tvInside} alt='tv_inside' />
          </TvWrapper>
          <TvDescription>
            외부광고 및 매물광고가 각각 전면, 2면, 6면에 순차 반복적
            디지털사이니지에 보여짐
            <br />
            외부광고(전면,2면,6면) ➔ 매물광고(전면,2면,6면) ➔ 외부광고 ➔
            매물광고
          </TvDescription>
        </ContentBottomContainer>
        <LeftRedArrow src={arrowRedIcon} alt='left_arrow_red' />
        <RightRedArrow src={arrowRedIcon} alt='right_arrow_red' />
      </ContentContainer>
    </Section4Container>
  );
}

export default Section4;
