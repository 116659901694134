import React, {useState} from 'react'
import {Display} from "../../../CRM/organism/Main/styles";
import {PaymentManageFilter} from "./PaymentManageFilter";
import {PaymentManageTable} from "./PaymentManageTable";
import {useOrderList} from '../../../CRM/hooks/Tosme/OperateManage/Sales/OrderManage/GetOrderList'
import styled from "styled-components";
import {useAdminPaymentADList} from "../../hooks/Admin/PaymentManage/GetAdminPaymentADList";
import {useAdminPaymentAgentList} from "../../hooks/Admin/PaymentManage/GetAdminPaymentAgentList";
import {useAdminPaymentReturnList} from "../../hooks/Admin/PaymentManage/GetAdminPaymentReturnList";
import API from "../../../utils/api";
import {message} from "antd";
import {useToken} from "../../../app/hooks";


export const PaymentManage = () => {
  //필터링
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [date, setDate] = useState('');
  const [type, setType] = useState<0 | 1 | 2>(0);
  const [agentType, setAgentType] = useState<0 | 1 | 2 | 3>(0);
  const [searchWord, setSearchWord] = useState('');
  const [order, setOrder] = useState<'latest' | 'oldest'>('latest');
  const [searchType, setSearchType] = useState<any>('');
  const [status, setStatus] = useState<0 | 1 | 2>(0);


  // 유틸리티
  const [categoryItem, setCategoryItem] = useState('광고주');
  const [isSelectItem, setIsSelectItem] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);

  // 데이터 조회
  const {
    adminPaymentADList,
    mutateAdminPaymentADList, total: adTotal, isLoading:isLoadingAD
  } = useAdminPaymentADList(page, pageSize, searchWord, searchType);

  const {
    adminPaymentAgentList,
    mutateAdminPaymentAgentList, total: agentTotal, isLoading:isLoadingAgent
  } = useAdminPaymentAgentList(page, pageSize, searchWord, searchType);

  const {
    adminPaymentReturnList,
    mutateAdminPaymentReturnList, total: returnTotal, isLoading:isLoadingReturn
  } = useAdminPaymentReturnList(page, pageSize, searchType, searchWord);

  const {data: token} = useToken();


  const getRefundExcel = () => {
    const shouldEdit = window.confirm('엑셀을 출력하시겠습니까?');
    if(shouldEdit) {
      API.get('/admin/payment/return/list/excel', token + '', {
      })
        .then((res: any) => {
          if (res.result.success) {
            message.success('출력되었습니다');
            window.open(res.result.result.link, '_blank');
          } else {
            message.error(res.result.message || '실패하였습니다');
          }
        })
        .catch((err: any) => {
          message.error('실패하였습니다');
        })
    }
  }


  return (
    <Display>

      <HeaderSection>
        <SelectCategory>
          <SelectItem onClick={() => {setCategoryItem('광고주');  }} Active={categoryItem === '광고주'}>광고주</SelectItem>
          <SelectItem onClick={() => {setCategoryItem('매체주');  setSearchWord('');}} Active={categoryItem === '매체주'}>매체주</SelectItem>
          <SelectItem onClick={() => {setCategoryItem('환급신청내역');  setSearchWord('');}} Active={categoryItem === '환급신청내역'}>환급신청내역</SelectItem>
        </SelectCategory>

        {categoryItem === '환급신청내역' &&
          <RegistSection>
            <RegistSaleButton onClick={() => getRefundExcel()}>엑셀출력</RegistSaleButton>
          </RegistSection>
        }
      </HeaderSection>

      <PaymentManageFilter
        setSearchType={setSearchType}
        categoryItem={categoryItem}
        date={date}
        setDate={setDate}
        type={type}
        setType={setType}
        searchWord={searchWord}
        setSearchWord={setSearchWord}
        order={order}
        setOrder={setOrder}
        mutateAdminPaymentADList={mutateAdminPaymentADList}
        agentType={agentType}
        setAgentType={setAgentType}
        mutateAdminPaymentAgentList={mutateAdminPaymentAgentList}
        setStatus={setStatus}
        mutateAdminPaymentReturnList={mutateAdminPaymentReturnList}
        setIsSelectItem={setIsSelectItem}
        isSelectItem={isSelectItem}
        selectedRows={selectedRows}
      />
      <PaymentManageTable
        adminPaymentADList={adminPaymentADList}
        mutateAdminPaymentADList={mutateAdminPaymentADList}
        adminPaymentAgentList={adminPaymentAgentList}
        adminPaymentReturnList={adminPaymentReturnList}
        categoryItem={categoryItem}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        isAllChecked={isAllChecked}
        setIsAllChecked={setIsAllChecked}
        isSelectItem={isSelectItem}
        adTotal={adTotal}
        agentTotal={agentTotal}
        returnTotal={returnTotal}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        isLoadingAD={isLoadingAD}
        isLoadingAgent={isLoadingAgent}
        isLoadingReturn={isLoadingReturn}

      />
    </Display>
  )
}

const HeaderSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const SelectCategory = styled.div`
    display: flex;
    gap: 20px;
`

const SelectItem = styled.div<{ Active: boolean }>`
    font-weight: ${(props) => props.Active ? '700' : '500'};
    font-size: 24px;
    line-height: 28.64px;
    font-family: Pretendard, sans-serif;
    color: ${(props) => props.Active ? '#2E2559' : '#00000066'};
    text-decoration: ${(props) => props.Active ? 'underline' : 'none'};
    cursor: pointer;
`

const RegistSection = styled.div`
    display: flex;
    justify-content: flex-end;
`

const RegistSaleButton = styled.div`
    background-color: #530DAD;
    box-shadow: 0px 0px 10px 0px #0000000D;
    width: 140px;
    height: 42px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;

    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`