import React, {useState, useEffect} from 'react'
import {Table, Checkbox, message, Button} from 'antd'
import {DBFilterCheckboxArea, DBFilterWrapper, DBTableExcelBtn} from '../../../CRM/organism/DB/styles';
import styled from "styled-components";
import {useToken} from "../../../app/hooks";
import {PreviewMonitor} from "./blocks/PreviewMonitor";
import {SalePreviewMonitor} from "./blocks/SalePreviewMonitor";
import API from "../../../utils/api";


export const CommercialRegistTable = ({
                                        adminContentsList,
                                        mutateAdminContentsList,
                                        total,
                                        page,
                                        setPage,
                                        pageSize,
                                        setPageSize,
                                        isLoading,
                                        setIsSelectItem,
                                        isSelectItem,
                                        selectedRows,
                                        setSelectedRows,
                                        isAllChecked,
                                        setIsAllChecked
                                      }) => {
  const [itemId, setItemId] = useState(null);
  const [isPreviewModal, setIsPreviewModal] = useState(false);
  const [isSalePreviewModal, setSaleIsPreviewModal] = useState(false);

  const {data: token} = useToken();


  useEffect(() => {
    const allSelected = adminContentsList?.length > 0 && adminContentsList?.every(it => selectedRows?.includes(it.adId));
    setIsAllChecked(allSelected);
  }, [selectedRows, adminContentsList]);


  const handleCheckboxChange = (e, id) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows?.filter(rowId => rowId !== id));
    }
  };


  const handleSelectAllChange = (e) => {
    setIsAllChecked(e.target.checked);
    const ids = adminContentsList?.map(db => db.adId);
    setSelectedRows(e.target.checked ? ids : []);
  };


  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };


  const editApproval = (id, type) => {
    const shouldEdit = window.confirm(type === 'resign' ? `광고물 제작 업체를 불허하시겠습니까?` : `광고물 제작 업체를 승인하시겠습니까?`);

    if (shouldEdit) {
      API.put('/admin/user/list', token + '', {
        ids: [id],
        type,
        value: 1
      })
        .then((res: any) => {
          if (res.result.success) {
            message.success(type === 'resign' ? '불허하였습니다.' : '승인하였습니다.')
          } else {
            message.error(res.result.message);
          }
        })
        .catch((err: any) => {})
    }
  }


  const customerColumns = [
    {
      dataIndex: 'userId',
      title: isSelectItem ?
        (<Checkbox
          checked={isAllChecked}
          onChange={handleSelectAllChange}
        />)
        :
        'No'
      ,
      render: (value, data, index) => {
        return (
          <>
            {isSelectItem ?
              (<div className='checkbox' style={{justifyContent: 'center', display: 'flex'}}>
                <Checkbox checked={selectedRows?.includes(value)} key={value}
                          onChange={(e) => handleCheckboxChange(e, value)}/>
              </div>)
              :
              <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
            }
          </>
        )
      }
    },
    {
      dataIndex: 'store',
      title: '업체명',
      render: (value, data) => {
        return (
          <div className='email' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'address',
      title: '주소',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'name',
      title: '담당자명',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'email',
      title: '이메일주소',
      render: (value, data) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'phone',
      title: '핸드폰번호',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex',}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'acceptedAt',
      title: '신청일',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex',}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'createdAt',
      title: '허가/불허',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex', gap: 10}}>
            <Button
              type={'primary'}
              onClick={() => {editApproval(data?.userId, 'accept')}}
            >허가
            </Button>
            <Button
              style={{border: '1px solid red', color: 'red'}}
              onClick={() => {editApproval(data?.userId, 'resign')}}
            >불허</Button>
          </div>
        )
      }
    },
    {
      dataIndex: 'approvedAt',
      title: '허가일',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex',}}>{value || '-'}</div>
        )
      }
    },
  ];


  return (
    <DBFilterWrapper>

      <StyleTable columns={customerColumns}
                  dataSource={adminContentsList}
                  bordered={true}
                  loading={isLoading}
                  pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: total,
                  }}
                  onChange={handleTableChange}
      />

      {/*{orderId !== null && <PaymentModal orderIdd={orderId} setOrderIdd={setOrderId} mutate={mutateOrderList} orderList={orderList} />}*/}
      {isPreviewModal &&
        <PreviewMonitor setIsPreviewModal={setIsPreviewModal} itemId={itemId} adminContentsList={adminContentsList}/>}
      {isSalePreviewModal &&
        <SalePreviewMonitor setIsPreviewModal={setSaleIsPreviewModal} itemId={itemId} agentSaleItem={null}/>}


    </DBFilterWrapper>
  )
}

export default React.memo(CommercialRegistTable);

const ColumnHeader = styled.div`

`

const StyleTable = styled(Table)`
    .ant-table-thead > tr > th {
        background-color: #F4F2FF;
        color: #00000099;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.71px;
    }
`
