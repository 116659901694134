import React, { useState } from 'react';
import styled from "styled-components";
import { Display } from "../../../CRM/organism/Main/styles";
import { useAgentSaleList } from "../../hooks/Agent/SaleProperty/GetAgentSaleList";
import { SalePropertyFilter } from "./SalePropertyFilter";
import { SalePropertyTable } from "./SalePropertyTable";
import API from "../../../utils/api";
import {useToken} from "../../../app/hooks";
import {message} from "antd";


export const SaleProperty = () => {
  // 필터링 관련
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [status, setStatus] = useState(0); // 1: 대기 | 2: 광고중 | 3: 중지 | 4: 거래완료
  const [order, setOrder] = useState('latest'); // latest, oldest
  const [searchWord, setSearchWord] = useState('');

  // 유틸리티
  const [isDetailModal, setIsDetailModal] = useState(false)
  const [isSelectItem, setIsSelectItem] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);

  // 데이터 조회
  const { agentSaleList, mutateAgentSaleList, total, isLoading } = useAgentSaleList(page, pageSize, status, order, searchWord)
  const {data:token} = useToken()


  const checkSale = () => {
    API.get('/agent/sale/check', token + '' , {}).then((res) => {
      console.log(res?.result?.success);

      if(res?.result?.success) {
        setIsDetailModal(true)
      } else {
        message.error(res?.result?.message ||'등록할 수 없습니다.')
      }
    }).catch((err:any) => {
      message.error('등록할 수 없습니다.', err)
    })
  }


  return(
    <Display>
      <RegistSection>
        <RegistSaleButton onClick={() => {
          checkSale();
        }}>매물등록</RegistSaleButton>
      </RegistSection>

      <SalePropertyFilter
        setOrder={setOrder}
        order={order}
        setStatus={setStatus}
        setSearchWord={setSearchWord}
        mutateAgentSaleList={mutateAgentSaleList}
        setIsSelectItem={setIsSelectItem}
        isSelectItem={isSelectItem}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        isAllChecked={isAllChecked}
        setIsAllChecked={setIsAllChecked}
      />

      <SalePropertyTable
        isDetailModal={isDetailModal}
        setIsDetailModal={setIsDetailModal}
        setPage={setPage}
        page={page}
        setPageSize={setPageSize}
        pageSize={pageSize}
        agentSaleList={agentSaleList}
        mutateAgentSaleList={mutateAgentSaleList}
        total={total}
        setIsSelectItem={setIsSelectItem}
        isSelectItem={isSelectItem}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        isAllChecked={isAllChecked}
        setIsAllChecked={setIsAllChecked}
        isLoading={isLoading}
      />
    </Display>
  )
}

const RegistSection = styled.div`
    display: flex;
    justify-content: flex-end;
`

const RegistSaleButton = styled.div`
    background-color: #530DAD;
    box-shadow: 0px 0px 10px 0px #0000000D;
    width: 140px;
    height: 42px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    
    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard ,sans-serif;
`

