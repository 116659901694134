import useSWR from 'swr'
import {useToken} from "../../../app/hooks";
import API from "../../../utils/api";

interface ResponseTypes {
	list: any[];
	total: {};
}

const M_KEY = '/user/bank'

const fetchKey = () =>
	`${M_KEY}`;


export function useBankList() {
	// const { data: token } = useToken();

	const KEY = fetchKey();


	const fetcher = () => fetchAPI(KEY, '');
	const { data, mutate } = useSWR(`${KEY}`, fetcher, { revalidateOnFocus: true });

	return { bankList: data?.list, mutateBankList: mutate, total: data?.total };
}

async function fetchAPI(KEY:string, token:any): Promise<ResponseTypes> {
	try {
		const response = await API.get(`${KEY}`, token + '', {});
		// console.log('response', response);
		return {list: response.result?.result || [], total: response.result?.result?.total}
	} catch (error) {
		console.error('Failed to fetch Bank List:', error);
		return { list: [], total: {} };
	}
}