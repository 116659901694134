import React, {useState, useEffect} from 'react'
import {Table, Checkbox, message} from 'antd'
import {DBFilterCheckboxArea, DBFilterWrapper, DBTableExcelBtn} from '../../../CRM/organism/DB/styles';
import styled from "styled-components";
import {useToken} from "../../../app/hooks";
import { numberWithCommas } from '../../../utils/ciutils';


export const TransactionTable = ({agentPaymentList, mutateAgentPaymentList, page, setPage, pageSize, setPageSize, total, isLoading}) => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const {data: token} = useToken();


  useEffect(() => {
    const allSelected = agentPaymentList?.length > 0 && agentPaymentList?.every(it => selectedRows?.includes(it.id));
    setIsAllChecked(allSelected);
  }, [selectedRows, agentPaymentList]);


  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };


  const columns = [
    {
      dataIndex: '',
      title: 'No',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{data?.paymentId || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '상품',
      render: (value, data) => {
        return (
          <div className='email' style={{justifyContent: 'center', display: 'flex'}}>{data?.productName || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '옵션',
      render: (value, data, index) => {
        return (
          <div className='No.'
               style={{cursor: 'pointer', justifyContent: 'center', display: 'flex'}}>{data?.optionName || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '내역',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{data?.content || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '금액',
      render: (value, data) => {
        return (
          <div className='type' style={{justifyContent: 'flex-end', display: 'flex'}}>{numberWithCommas(data?.amount)+' 원'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '납부예정일',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{data?.paymentDueDate || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '상태',
      render: (value, data) => {
        return (
          <div className='date' style={{
            justifyContent: 'center',
            display: 'flex'
          }}>{data?.status === '결제완료' ? `${data?.status} ${data?.payAt}` : data?.status}</div>
        )
      }
    },
  ];

  return (
    <DBFilterWrapper>
      <DBFilterCheckboxArea>

      </DBFilterCheckboxArea>
      <StyleTable columns={columns}
                  dataSource={agentPaymentList}
                  bordered={true}
                  loading={isLoading}
                  pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: total,
                  }}
                  onChange={handleTableChange}
      />


    </DBFilterWrapper>
  )
}

export default React.memo(TransactionTable);

const ColumnHeader = styled.div`

`

const StyleTable = styled(Table)`
    .ant-table-thead > tr > th {
        background-color: #F4F2FF;
        color: #00000099;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.71px;
    }
`
