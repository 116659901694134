import styled from 'styled-components';
import third1 from '../../assets/images/third_1.png';
import third2 from '../../assets/images/third_2.png';
import third3 from '../../assets/images/third_3.png';
import ContactModal from '../../components/ContactModal';
import React, {useEffect} from "react";
import {useNavigate} from "react-router";
import {useMakerUserDetail} from "../../../HomeTown/hooks/Admin/CommercialRegist/GetMakerUserDetail";
import {useLocation, useParams} from "react-router-dom";
import {MEDIA_URL} from "../../../app/constants/APIKEYS";
import {DeskTopCalVW, media} from "../../../utils/CalVW";

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 88px 320px 0 320px;

    @media ${media.desktop} {
        padding: ${DeskTopCalVW(88)} ${DeskTopCalVW(320)} ${DeskTopCalVW(0)} ${DeskTopCalVW(320)};
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 40px;

    @media ${media.desktop} {
        gap: ${DeskTopCalVW(40)};
    }
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 1280px;
  min-width: 1280px;
  gap: 12px;

    @media ${media.desktop} {
        width: ${DeskTopCalVW(1280)};
        min-width: ${DeskTopCalVW(1280)};
        gap: ${DeskTopCalVW(12)};
    }

  button {
    font-size: 1rem;
    font-weight: 600;
    color: #212121;
    line-height: 20px;
    background-color: transparent;
    border: none;

      @media ${media.desktop} {
          font-size: ${DeskTopCalVW(16)};
          line-height: ${DeskTopCalVW(20)};
      }
  }
`;

const ImagesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 280px;
  min-height: 280px;
  gap: 34px;

    @media ${media.desktop} {
        height: ${DeskTopCalVW(280)};
        min-height: ${DeskTopCalVW(280)};
        gap: ${DeskTopCalVW(34)};
    }

  img {
    width: 404px;
    min-width: 404px;
    height: 100%;
    border-radius: 12px;

      @media ${media.desktop} {
          width: ${DeskTopCalVW(404)};
          min-width: ${DeskTopCalVW(404)};
          border-radius: ${DeskTopCalVW(12)};
      }
  }
`;

const BottomContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1280px;
    min-width: 1280px;
    gap: 60px;

    @media ${media.desktop} {
        width: ${DeskTopCalVW(1280)};
        min-width: ${DeskTopCalVW(1280)};
        gap: ${DeskTopCalVW(60)};
    }
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 36px;

    @media ${media.desktop} {
        gap: ${DeskTopCalVW(36)};
    }

  p {
    font-size: 1.25rem;
    font-weight: 400;
    color: #212121;
    line-height: 30px;

      @media ${media.desktop} {
          font-size: ${DeskTopCalVW(20)};
          line-height: ${DeskTopCalVW(30)};
      }
  }
`;

const DescriptionInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 12px;

    @media ${media.desktop} {
        gap: ${DeskTopCalVW(12)};
    }

  h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #212121;
    line-height: 32px;

      @media ${media.desktop} {
          font-size: ${DeskTopCalVW(32)};
          line-height: ${DeskTopCalVW(32)};
      }
  }
`;

const DescriptionInfoDetail = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

    @media ${media.desktop} {
        gap: ${DeskTopCalVW(8)};
    }

  div {
    width: 1px;
    height: 10px;
    background-color: #afafaf;

      @media ${media.desktop} {
          height: ${DeskTopCalVW(10)};
      }
  }

  span {
    font-size: 1rem;
    font-weight: 400;
    color: #212121;
    line-height: 24px;

      @media ${media.desktop} {
          font-size: ${DeskTopCalVW(16)};
          line-height: ${DeskTopCalVW(24)};
      }
  }
`;

const ContactButton = styled.button`
  width: 164px;
  height: 52px;
  border-radius: 4px;
  background-color: #ff2828;
  font-size: 1.25rem;
  font-weight: 700;
  color: #ffffff;
  line-height: 28px;
  border: none;

    @media ${media.desktop} {
        width: ${DeskTopCalVW(164)};
        height: ${DeskTopCalVW(52)};
        border-radius: ${DeskTopCalVW(4)};
        font-size: ${DeskTopCalVW(20)};
        line-height: ${DeskTopCalVW(28)};
    }
`;


function ThirdContent() {
  const [openRequireModal, setOpenRequireModal] = React.useState(false);
  const location = useLocation();
  const { id:postId } = location?.state || {};
  const {makerUserDetail} = useMakerUserDetail(postId)


  useEffect(() => {
    console.log('makerUserDetail', makerUserDetail);
    // console.log('state', state);
  }, [])



  return (
    <Container>
      <Content>
        <TopContent>
          {/*<button type='button' onClick={() => navigate('/home-digital-detail')}>전체보기</button>*/}
          {makerUserDetail?.fileList ?
            <ImagesContainer>
              {makerUserDetail?.fileList?.map((imageItem, index) => {
                return (<img src={MEDIA_URL + imageItem?.url} alt='third1'/>)
              })}
            </ImagesContainer>
            :
            <h2></h2>
          }

        </TopContent>

        <BottomContent>
          <DescriptionContainer>
            <DescriptionInfo>
              <h2>서비스 설명</h2>
              <DescriptionInfoDetail>
                <span>{makerUserDetail?.address || '-'}</span>
                <div />
                <span>{makerUserDetail?.email || '-'}</span>
                <div />
                <span>{makerUserDetail?.phone || '-'}</span>
              </DescriptionInfoDetail>
            </DescriptionInfo>
            <p>
              {makerUserDetail?.memo || '-'}
            </p>
          </DescriptionContainer>
          {/*<ContactButton type='button' onClick={() => setOpenRequireModal(true)}>문의하기</ContactButton>*/}
        </BottomContent>
      </Content>

      {/*{openRequireModal &&*/}
      {/*  <ContactModal setOpenRequireModal={setOpenRequireModal}/>*/}
      {/*}*/}
    </Container>
  );
}

export default ThirdContent;
