import React, {useState} from 'react'
import {Display} from "../../../CRM/organism/Main/styles";
import {AdminNoticeFilter} from "./AdminNoticeFilter";
import {AdminNoticeTable} from "./AdminNoticeTable";
import {useOrderList} from '../../../CRM/hooks/Tosme/OperateManage/Sales/OrderManage/GetOrderList'
import styled from "styled-components";
import {useAdminPaymentAgentList} from "../../hooks/Admin/PaymentManage/GetAdminPaymentAgentList";
import {useAdminNoticeList} from "../../hooks/Admin/AdminNotice/GetAdminNoticeList";
import {RegistNotice} from "./blocks/RegistNotice";
import {RegistHomeNotice} from "./blocks/RegistHomeNotice";


export const AdminNotice = () => {
  //필터링
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [date, setDate] = useState('');
  const [type, setType] = useState<1 | 2 | 3>(2);
  const [searchWord, setSearchWord] = useState('');
  const [order, setOrder] = useState<'latest' | 'oldest'>('latest');

  // 유틸리티
  const [categoryItem, setCategoryItem] = useState('광고주');
  const [registNoticeModal, setRegistNoticeModal] = useState(false);
  const [registHomeNoticeModal, setRegistHomeNoticeModal] = useState(false);
  const [isSelectItem, setIsSelectItem] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);

  // 데이터 조회
  const {
    adminNoticeList,
    mutateAdminNoticeList, total, isLoading
  } = useAdminNoticeList(page, pageSize, date, type, searchWord, order);


  return (
    <Display>

      <RegistSection>
        <SelectCategory>
          <SelectItem onClick={() => {
            setCategoryItem('광고주');
            setType(2);
          }} Active={categoryItem === '광고주'}>광고주</SelectItem>
          <SelectItem onClick={() => {
            setCategoryItem('매체주');
            setType(1);
          }} Active={categoryItem === '매체주'}>매체주</SelectItem>
          <SelectItem onClick={() => {
            setCategoryItem('홈페이지');
            setType(3);
          }} Active={categoryItem === '홈페이지'}>홈페이지</SelectItem>
        </SelectCategory>

        {categoryItem === '홈페이지' ?
          <RegistSaleButton onClick={() => setRegistHomeNoticeModal(true)}>홈페이지 공지사항 등록</RegistSaleButton>
        :
          <RegistSaleButton onClick={() => setRegistNoticeModal(true)}>공지사항 등록</RegistSaleButton>
        }
      </RegistSection>


      <AdminNoticeFilter
        setSearchWord={setSearchWord}
        setOrder={setOrder}
        categoryItem={categoryItem}
        mutateAdminNoticeList={mutateAdminNoticeList}
        order={order}

        setIsSelectItem={setIsSelectItem}
        isSelectItem={isSelectItem}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
      <AdminNoticeTable
        mutateAdminNoticeList={mutateAdminNoticeList}
        adminNoticeList={adminNoticeList}
        categoryItem={categoryItem}
        total={total}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        isLoading={isLoading}

        setIsSelectItem={setIsSelectItem}
        isSelectItem={isSelectItem}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        isAllChecked={isAllChecked}
        setIsAllChecked={setIsAllChecked}
      />

      {registNoticeModal && <RegistNotice setRegistNoticeModal={setRegistNoticeModal} type={type} mutateAdminNoticeList={mutateAdminNoticeList} />}
      {registHomeNoticeModal && <RegistHomeNotice setRegistNoticeModal={setRegistHomeNoticeModal} type={3} mutateAdminNoticeList={mutateAdminNoticeList} />}
    </Display>
  )
}

const RegistSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const RegistSaleButton = styled.div`
    background-color: #530DAD;
    box-shadow: 0px 0px 10px 0px #0000000D;
    height: 42px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    padding-inline: 10px;

    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`


const SelectCategory = styled.div`
    display: flex;
    gap: 20px;
`

const SelectItem = styled.div<{ Active: boolean }>`
    font-weight: ${(props) => props.Active ? '700' : '500'};
    font-size: 24px;
    line-height: 28.64px;
    font-family: Pretendard, sans-serif;
    color: ${(props) => props.Active ? '#2E2559' : '#00000066'};
    text-decoration: ${(props) => props.Active ? 'underline' : 'none'};
    cursor: pointer;
`
