import styled from 'styled-components';
import logo from '../../assets/images/logo.png';
import * as React from "react";
import {HomeTownSignUpModal} from "../../../app/organisms/Auth/Forms/HomeTownSignUpModal";
import {HomeTownCustomerSignUpModal} from "../../../app/organisms/Auth/Forms/HomeTownCustomerSignUpModal";
import {HomeTownRealEstateSignUpModal} from "../../../app/organisms/Auth/Forms/HomeTownRealEstateSignUpModal";
import {Input, message} from "antd";
import {useState} from "react";
import {apiError, checkEmail} from "../../../utils/ExpensiveFunction";
import API from "../../../utils/api";
import {useNavigate} from "react-router-dom";
import {useToken} from "../../../app/hooks";

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 160px 0 0 0;
`;

const Content = styled.div`
  display: flex;
  width: 960px;
  height: 542px;
  border-radius: 12px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 480px;
  height: 100%;
  padding: 60px 36px 120px 36px;
  gap: 40px;
  background-color: #ffffff;

  h2 {
    font-size: 2.25rem;
    font-weight: 700;
    color: #212121;
    line-height: 44px;
  }
`;

const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

const LoginInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  span {
    font-size: 1rem;
    font-weight: 400;
    color: #4b4b4b;
    line-height: 20px;
  }
`;

const LoginInputWrapper = styled(Input)`
  width: 100%;
  height: 52px;
  border: 1px solid #d7d7d7;
  border-radius: 8px;
  padding: 0 10px;
  background-color: #ffffff;

  input {
    width: 100%;
    height: 100%;
    font-size: 1rem;
    font-weight: 400;
    color: #4b4b4b;
    background-color: transparent;
  }
`;

const LoginButton = styled.button`
  width: 100%;
  height: 52px;
  min-height: 52px;
  background-color: #ff2828;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 700;
  color: #ffffff;
  line-height: 20px;
    border: none;
`;

const SignupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  button {
    font-size: 0.875rem;
    font-weight: 600;
    color: #212121;
    line-height: 18px;
  }

  span {
    font-size: 0.875rem;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
    cursor: pointer;
  }
`;

const LoginBanner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;
  height: 100%;
  padding: 60px 36px 120px 36px;
  gap: 108px;
  background-color: #ffe07c;
`;

const LoginBannerItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

const LoginBannerItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 104px;
  min-height: 104px;
  background-color: #ffffff;
  border-radius: 12px;
  gap: 4px;

  p {
    font-size: 1.25rem;
    font-weight: 600;
    color: #212121;
    line-height: 28px;
  }

  span {
    font-size: 1.5rem;
    font-weight: 700;
    color: #ff2828;
    line-height: 32px;
  }
`;

const Logo = styled.img`
  width: 148px;
  height: 34px;
`;

const LoginInputWrap = styled.div`

`

const InputTitle = styled.div`
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    color: #000000CC;
    font-family: Pretendard, sans-serif;
    margin-bottom: 8px;
`

const LoginInput = styled.input`
    border: 1px solid #00000033;
    width: 100%;
    height: 45px;
    padding-left: 10px;
    border-radius: 10px;
`

function FourthContent() {
  const [isSignUpModalOpen, setIsSignUpModalOpen] = React.useState(false);
  const [isCustomerSignUpModalOpen, setIsCustomerSignUpModalOpen] = React.useState(false);
  const [isRealEstateSignUpModalOpen, setIsRealEstateSignUpModalOpen] = React.useState(false);

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const navigate = useNavigate();

  const {mutate} = useToken()


  const onChangeEmail = e => {
    setEmail(e.target.value)
  }

  const onChangePassword = e => {
    setPassword(e.target.value)
  }

  const handleSubmit = async () => {


    try {
      const res = await API.send('POST', '/auth/signin', null, {
        email,
        password,
      })

      if (res.result.success) {
        mutate(res?.result?.result?.token);
        mutate(res?.result?.username);
        message.success('로그인 성공!');
        navigate('/');
      } else {
        message.error(res.result.message)
      }
    } catch (e) {
      message.error('로그인 에러 발생');
      console.log(e, 'ERROR LOGIN')
    }
  }

  return (
    <Container>
      <Content>
        <LoginContainer>
          <h2>로그인</h2>
          <LoginContent>
            <LoginInputWrap>
              <InputTitle>아이디</InputTitle>
              <LoginInput onChange={onChangeEmail} value={email}></LoginInput>
            </LoginInputWrap>
            <LoginInputWrap>
              <InputTitle>비밀번호</InputTitle>
              <LoginInput onChange={onChangePassword} type={'password'}></LoginInput>
            </LoginInputWrap>
            <LoginButton
              onClick={event => {
                event.preventDefault()
                handleSubmit()
              }}
            >로그인</LoginButton>
            <SignupContainer>
              <div style={{cursor: 'pointer'}} onClick={() => setIsSignUpModalOpen(true)}>회원가입</div>
              <span>아이디/비밀번호 찾기</span>
            </SignupContainer>
          </LoginContent>
        </LoginContainer>

        <LoginBanner>
          <LoginBannerItemContainer>
            <LoginBannerItem>
              <p>광고주는</p>
              <span>최저 비용으로 최대 광고효과를</span>
            </LoginBannerItem>
            <LoginBannerItem>
              <p>부동산중개사무소는</p>
              <span>편리한 매물광과와 추가수익까지</span>
            </LoginBannerItem>
          </LoginBannerItemContainer>
          <Logo src={logo} alt='logo' />
        </LoginBanner>
      </Content>

      {isSignUpModalOpen &&
        <HomeTownSignUpModal setIsSignUpModalOpen={setIsSignUpModalOpen} setIsCustomerSignUpModalOpen={setIsCustomerSignUpModalOpen} setIsRealEstateSignUpModalOpen={setIsRealEstateSignUpModalOpen}/>
      }

      {isCustomerSignUpModalOpen &&
        <HomeTownCustomerSignUpModal setIsCustomerSignUpModalOpen={setIsCustomerSignUpModalOpen}/>
      }

      {isRealEstateSignUpModalOpen &&
        <HomeTownRealEstateSignUpModal setIsRealEstateSignUpModalOpen={setIsRealEstateSignUpModalOpen}/>
      }
    </Container>
  );
}

export default FourthContent;
