import React, {startTransition, Suspense, useEffect, useState, useTransition} from 'react'
import {Display} from "../../../CRM/organism/Main/styles";
import {useOrderList} from '../../../CRM/hooks/Tosme/OperateManage/Sales/OrderManage/GetOrderList'
import styled from "styled-components";
import {Container as MapDiv, NaverMap, Marker, useNavermaps} from 'react-naver-maps'
import {HomeTownRoundCheckbox} from "../../../app/organisms/Auth/Forms/HomeTownRoundCheckbox";
import {TwoStepListItem} from "../CustomerCommercial/atom/TwoStepListItem";
import {RealEstateInfo} from "../CustomerCommercial/blocks/RealEstateInfo";
import {useAdvertiserAgentList} from "../../hooks/Advertiser/CustomerCommercial/GetAdvertiserAgentList";
import {MediaItem} from "./blocks/MediaItem";
import {SelectAddressItem} from "./blocks/SelectAddressItem";
import {message, Table} from "antd";




export const MediaList = () => {
  // 유틸리티
  const [userLocation, setUserLocation] = useState({lat: 37.5665, lng: 126.9780});
  const [realEstateId, setRealEstateId] = useState(null);
  const [dataMarkers, setDataMarkers] = useState<any>([]);
  const [fAddress, setFAddress] = useState('');

  // 주소 검색
  const [selectedFirstName, setSelectedFirstName] = React.useState<any>(null);
  const [selectedSecondName, setSelectedSecondName] = React.useState<any>(null);
  const [selectedThirdName, setSelectedThirdName] = React.useState<any>(null);


  // 데이터 조회
  const {advertiserAgentList, mutateAdvertiserAgentList} = useAdvertiserAgentList(1, 999999999, fAddress);
  const navermaps = useNavermaps();



  useEffect(() => {
    getUserLocation();
  }, []);


  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
          console.log(position);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };


  useEffect(() => {
    if (advertiserAgentList) {
      advertiserAgentList.forEach((item) => {
        if (item?.address) {
          handleGeocode(item.address);
        }
      });
    }
  }, [advertiserAgentList]);


  const handleGeocode = async (fullAddress: string) => {
    navermaps.Service.geocode({
      query: fullAddress
    }, (status, response) => {
      if (status === navermaps.Service.Status.OK) {
        const result = response.v2.addresses[0];
        const lat = parseFloat(result?.y);
        const lng = parseFloat(result?.x);
        setDataMarkers((prev) => [...prev, { lat, lng }]);
      } else {
        console.error('Geocoding failed:', response);
      }
    });
  };


  const setLocation = (fullAddress: string) => {
    navermaps.Service.geocode({
      query: fullAddress
    }, (status, response) => {
      if (status === navermaps.Service.Status.OK) {
        const result = response.v2.addresses[0];
        const lat = parseFloat(result?.y);
        const lng = parseFloat(result?.x);
        if (lat && lng) {
          setUserLocation({ lat, lng });
        }
      } else {
        console.error('Geocoding failed:', response);
        message.error('해당 주소는 검색할 수 없습니다.');
      }
    });
  };


  useEffect(() => {
    const fullAddress = `${selectedFirstName || ''} ${selectedSecondName || ''} ${selectedThirdName || ''}`;
    // console.log('fullAddress', fullAddress)

    if (selectedFirstName || selectedSecondName || selectedThirdName) {
      setLocation(fullAddress);
    }

    setFAddress(fullAddress);
  }, [selectedFirstName, selectedSecondName, selectedThirdName]);


  const columns = [
    {
      dataIndex: '',
      title: '',
      render: (value, data) => {
        return (
              <MediaItem setRealEstateId={setRealEstateId} agentId={data?.agentId} address={data?.address}
                       businessHours={data?.businessHours} imageUrl={data?.imageUrl} mediaInfo={data?.mediaInfo}
                       store={data?.store} mediaType={data?.mediaType} />
        )
      }
    },
  ]


  return (
    <>
      <Display>
        {/*<RegistSection>*/}
        {/*    <RegistSaleButton>광고신청</RegistSaleButton>*/}
        {/*</RegistSection>*/}

        <SelectAddressItem
          selectedFirstName={selectedFirstName}
          setSelectedFirstName={setSelectedFirstName}
          selectedSecondName={selectedSecondName}
          setSelectedSecondName={setSelectedSecondName}
          selectedThirdName={selectedThirdName}
          setSelectedThirdName={setSelectedThirdName}
        />

        <ContentsSection>
          <MapDiv style={{width: '68%', height: '785px'}}>
            <NaverMap
              center={userLocation}
              defaultZoom={12}
            >
              {dataMarkers.map(marker => (
                <Marker
                  key={marker.id}
                  position={{lat: marker.lat, lng: marker.lng}}
                />
              ))}
            </NaverMap>
          </MapDiv>

          <RightContents>

            <ListHeader>
              {`${advertiserAgentList?.length || 0}곳의 공인중개사무소`}

              {/*<RoundCheckWrapper>*/}
              {/*  <HomeTownRoundCheckbox checked={true} text={'인기순'} />*/}
              {/*  <HomeTownRoundCheckbox checked={false} text={'최신순'} />*/}
              {/*</RoundCheckWrapper>*/}
            </ListHeader>

            {/*<ListContent>*/}
            {/*  {advertiserAgentList?.map((item, index) => (*/}
            {/*    <MediaItem setRealEstateId={setRealEstateId} agentId={item?.agentId} address={item?.address}*/}
            {/*               businessHours={item?.businessHours} imageUrl={item?.imageUrl} mediaInfo={item?.mediaInfo}*/}
            {/*               store={item?.store} mediaType={item?.mediaType} />*/}
            {/*  ))}*/}
            {/*</ListContent>*/}

            <ListContent>
              <Table columns={columns} dataSource={advertiserAgentList} pagination={{pageSize: 10, position: ['bottomLeft']}} showHeader={false} />
            </ListContent>

          </RightContents>

        </ContentsSection>
      </Display>

      {realEstateId !== null && <RealEstateInfo setRealEstateId={setRealEstateId} realEstateId={realEstateId}/>}
    </>
  )
}

const RegistSection = styled.div`
    display: flex;
    justify-content: flex-end;
`

const RegistSaleButton = styled.div`
    background-color: #530DAD;
    box-shadow: 0px 0px 10px 0px #0000000D;
    width: 140px;
    height: 42px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;

    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`

const RightContents = styled.div`

`

const PredictValueWrapper = styled.div`
    width: 486px;
    height: 109px;
    padding-top: 16px;
    padding-left: 20px;
`

const CommercialText = styled.div`
    display: flex;
    font-weight: 500;
    font-size: 18px;
    line-height: 21.48px;
    color: #530DAD;
    gap: 4px;
    margin-bottom: 16px;
`

const CommercialBoldText = styled.div`
    font-weight: 700;
`

const SubText = styled.div`
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
    gap: 10px;
`

const SubValue = styled.div`

`

const ListHeader = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 19.09px;
    margin-bottom: 10px;
    padding: 0px 12px;
    display: flex;
    justify-content: space-between;
`

const RoundCheckWrapper = styled.div`
    display: flex;
    gap: 14px;
`


const ListContent = styled.div`
    border-bottom: 1px solid #0000000D;
    border-top: 1px solid #0000000D;
    height: 785px;
    overflow: scroll;
`

const ContentsSection = styled.div`
    display: flex;
    gap: 12px
`