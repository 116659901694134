import React, {useState} from 'react'
import {Display} from "../../../CRM/organism/Main/styles";
import {AdminNoticeFilter} from "./AdminNoticeFilter";
import {AdminNoticeTable} from "./AdminNoticeTable";
import styled from "styled-components";
import {RegistNotice} from "./blocks/RegistNotice";
import {useAdminNoticeList} from "../../../HomeTown/hooks/Admin/AdminNotice/GetAdminNoticeList";
import NavBar from "../../components/NavBar";
import {DeskTopCalVW, media} from "../../../utils/CalVW";
import {useHomeNoticeList} from "../../../HomeTown/hooks/Admin/AdminNotice/GetHomeNoticeList";


export const HomeNotice = () => {
  //필터링
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [date, setDate] = useState('');
  const [type, setType] = useState<any>(3);
  const [searchWord, setSearchWord] = useState('');
  const [order, setOrder] = useState<'latest' | 'oldest'>('latest');

  // 유틸리티
  const [categoryItem, setCategoryItem] = useState('광고주');
  const [registNoticeModal, setRegistNoticeModal] = useState(false);
  const [isSelectItem, setIsSelectItem] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);

  // 데이터 조회
  const {
    homeNoticeList,
    mutateHomeNoticeList, total, isLoading
  } = useHomeNoticeList(page, pageSize, date, type, searchWord, order);


  return (
    <Container>
      <NavBar />

      <Display style={{boxShadow: 'none', }}>

        <Title>공지사항</Title>

        {/*<RegistSection>*/}
        {/*  <SelectCategory>*/}
        {/*    <SelectItem onClick={() => {*/}
        {/*      setCategoryItem('광고주');*/}
        {/*      setType(2);*/}
        {/*    }} Active={categoryItem === '광고주'}>광고주</SelectItem>*/}
        {/*    <SelectItem onClick={() => {*/}
        {/*      setCategoryItem('매체주');*/}
        {/*      setType(1);*/}
        {/*    }} Active={categoryItem === '매체주'}>매체주</SelectItem>*/}
        {/*  </SelectCategory>*/}

        {/*  <RegistSaleButton onClick={() => setRegistNoticeModal(true)}>공지사항 등록</RegistSaleButton>*/}
        {/*</RegistSection>*/}


        {/*<AdminNoticeFilter*/}
        {/*  setSearchWord={setSearchWord}*/}
        {/*  setOrder={setOrder}*/}
        {/*  categoryItem={categoryItem}*/}
        {/*  mutateAdminNoticeList={mutateAdminNoticeList}*/}
        {/*  order={order}*/}

        {/*  setIsSelectItem={setIsSelectItem}*/}
        {/*  isSelectItem={isSelectItem}*/}
        {/*  selectedRows={selectedRows}*/}
        {/*  setSelectedRows={setSelectedRows}*/}
        {/*/>*/}
        <AdminNoticeTable
          mutateAdminNoticeList={mutateHomeNoticeList}
          adminNoticeList={homeNoticeList}
          categoryItem={categoryItem}
          total={total}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          isLoading={isLoading}

          setIsSelectItem={setIsSelectItem}
          isSelectItem={isSelectItem}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          isAllChecked={isAllChecked}
          setIsAllChecked={setIsAllChecked}
        />

        {registNoticeModal && <RegistNotice setRegistNoticeModal={setRegistNoticeModal} type={type} mutateAdminNoticeList={mutateHomeNoticeList} />}
      </Display>
    </Container>
  )
}


const Title = styled.h2`
    font-size: 3rem;
    font-weight: 700;
    color: #212121;
    line-height: 60px;
    text-align: center;
    margin-top: 90px;
    margin-bottom: 20px;

    @media ${media.desktop} {
        font-size: ${DeskTopCalVW(48)};
        line-height: ${DeskTopCalVW(60)};
    }
`;


const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RegistSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const RegistSaleButton = styled.div`
    background-color: #530DAD;
    box-shadow: 0px 0px 10px 0px #0000000D;
    width: 140px;
    height: 42px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;

    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`


const SelectCategory = styled.div`
    display: flex;
    gap: 20px;
`

const SelectItem = styled.div<{ Active: boolean }>`
    font-weight: ${(props) => props.Active ? '700' : '500'};
    font-size: 24px;
    line-height: 28.64px;
    font-family: Pretendard, sans-serif;
    color: ${(props) => props.Active ? '#2E2559' : '#00000066'};
    text-decoration: ${(props) => props.Active ? 'underline' : 'none'};
    cursor: pointer;
`
