import React, {useEffect, useState} from 'react'
import {Blacked} from "../../../../CRM/components/styles";
import {ReactComponent as ModalCloseIcon} from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import styled from "styled-components";
import {MEDIA_URL} from "../../../../app/constants/APIKEYS";
import { FullImageList } from 'app/organisms/FullImageList';


export const PreviewMonitor = ({setIsPreviewModal, itemId, agentOutputList}) => {
  const [selectedItem, setSelectedItem] = React.useState<any>({});
  const [fileList, setFileList] = React.useState<any[]>([]);

  useEffect(() => {
    console.log('agentSaleList', agentOutputList);
    setSelectedItem(agentOutputList.find((item) => itemId === item.adId));
  }, [agentOutputList]);


  useEffect(() => {
    console.log('selectedItem', selectedItem);
    const newItem = selectedItem?.fileList?.map((item) => item?.url)

    console.log('newItem', newItem);

    if(newItem?.length > 0){
      setFileList(newItem);
    }
  }, [selectedItem]);

  useEffect(() => {
    console.log('fileList', fileList);
  }, [fileList]);

  return (
    <>
      <Blacked onClick={() => setIsPreviewModal(false)}/>
      <ModalWrapper className={"scroll-container"}>
        <ModalHeader>
          <ModalTitle>미리보기</ModalTitle>
          <CloseIconSVG as={ModalCloseIcon} onClick={() => {
            setIsPreviewModal(false)
          }}/>
        </ModalHeader>

        <Contents>
          <MonitorTitle>전면</MonitorTitle>
          <MonitorWrapper>
            <FullImageList imagelist={fileList?.length > 0 ? fileList : []} type={'six'} isWidth={true} width="415.61px" height="237.42px"   />
          </MonitorWrapper>
        </Contents>

        <Contents>
          <MonitorTitle>2면</MonitorTitle>
          <MonitorWrapper style={{padding: 4}}>
            <div style={{display: "flex", justifyContent: "center", gap: 6}}>

              <TwoWrapper>
                <FullImageList imagelist={fileList?.length > 0  ? fileList : []} isWidth={true} width="198px" height="142.01px" type={'six'} />
              </TwoWrapper>

              <TwoBig  />
            </div>

            <div style={{display: "flex", justifyContent: "center", gap: 5, marginTop: 5}}>
              <TwoSmall />
              <TwoSmall />
              <TwoSmall/>
              <TwoSmall />
            </div>
          </MonitorWrapper>
        </Contents>

        <Contents>
          <MonitorTitle>6면</MonitorTitle>
          <MonitorWrapper style={{padding: 4}}>
            <div style={{display: "flex", justifyContent: "center", gap: 3}}>
              <SixWrapper>
                <FullImageList imagelist={fileList?.length > 0  ? fileList : []} isWidth={true}  width="133px" height="111.55px" type={'six'} />
              </SixWrapper>
              {/* <SixImg src={selectedItem?.fileList && MEDIA_URL + selectedItem?.fileList[0]?.url}/> */}
              <SixBox />
              <SixBox />
            </div>

            <div style={{display: "flex", justifyContent: "center", gap: 3, marginTop: 3}}>
              <SixBox />
              <SixBox />
              <SixBox />
            </div>
          </MonitorWrapper>
        </Contents>

      </ModalWrapper>
    </>
  )
}

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 820px;
    overflow-y: scroll;
    width: 580px;
    background-color: #fff;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #2E2559;
`

const Contents = styled.div`
    margin-top: 45px;
    font-weight: 400;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
    color: #000000;
    white-space: pre-wrap;
    text-align: center;
`

const MonitorTitle = styled.div`
    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    font-family: pretendard, sans-serif;
    margin-bottom: 10px;
`

const MonitorWrapper = styled.div`
    border: 12.87px solid #000000;
    width: 437.61px;
    height: 257.42px;
    
`

const TwoWrapper = styled.div`
    width: 198px;
    height: 142.01px;
    overflow: hidden;
    
`

const SixWrapper = styled.div`
    width: 133px;
    height: 111.55px;
    overflow: hidden;
    
`

const FullImg = styled.img`
    background-color: #D9D9D9;
    width: 100%;
    height: 100%;
`

// const FullImgWrapper = styled.div`
//     width: 338.29px;
//     height: 231.68px;
// `

const TwoBigImg = styled.img`
    background-color: #D9D9D9;
    width: 198px;
    height: 142.01px;
`

const TwoBig = styled.div`
    background-color: #D9D9D9;
    width: 198px;
    height: 142.01px;
`

const TwoSmall = styled.img`
    background-color: #D9D9D9;
    width: 96.96px;
    height: 77.01px;
`

const SixImg = styled.img`
    background-color: #D9D9D9;
    width: 133px;
    height: 111.55px;
`

const SixBox = styled.div`
    background-color: #D9D9D9;
    width: 133px;
    height: 111.55px;
`
