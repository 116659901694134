import React, {useEffect} from 'react';
import styled from "styled-components";
import {ReactComponent as PaymentLogo} from "../assets/PaymentLogo.svg";
import {ReactComponent as CheckRoundIcon} from "../assets/CheckRoundIcon.svg";

import {useLocation, useNavigate} from "react-router-dom";
import {DeskTopCalVW, MobileCalVW} from "../../../utils/CalVW";


export const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const paymentData = location.state;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return(
    <Oraganism>

      <Contents>
        {/*<LogoWrapper>*/}
        {/*  <LogoSVG/>*/}
        {/*</LogoWrapper>*/}

        <CheckRoundIconSVG/>

        <TextTitle>
          {`${paymentData?.title} 결제가 완료되었습니다.`}
        </TextTitle>

        <StyledButton onClick={() => navigate('/admin')}>
          홈으로
        </StyledButton>

      </Contents>

    </Oraganism>
  )
}

const Oraganism = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Contents = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 360px;
    padding-top: 48px;
    
    @media (max-width: 1920px) {
        width: ${DeskTopCalVW(360)};
        padding-top: ${DeskTopCalVW(48)};
    }

    // mobile
    @media (max-width: 768px) {
        width: ${MobileCalVW(375)};
    }
    
`

const LogoWrapper = styled.div`
    width: 360px;
    padding-left: 12px;
    margin-bottom: 43px;

    @media (max-width: 1920px) {
        width: ${DeskTopCalVW(360)};
        padding-left: ${DeskTopCalVW(12)};
        margin-bottom: ${DeskTopCalVW(43)};
    }

    // mobile
    @media (max-width: 768px) {
        width: ${MobileCalVW(375)};
        padding-left: ${MobileCalVW(20)};
        margin-bottom: ${MobileCalVW(33)};
        margin-top: ${MobileCalVW(10)};
    }
`

const LogoSVG = styled(PaymentLogo)`
    width: 88px;
    height: 27px;
    
    @media (max-width: 1920px) {
        width: ${DeskTopCalVW(88)};
        height: ${DeskTopCalVW(27)};
    }

    // mobile
    @media (max-width: 768px) {
        width: ${MobileCalVW(88)};
        height: ${MobileCalVW(27)};
    }
`

const CheckRoundIconSVG = styled(CheckRoundIcon)`
    width: 38px;
    height: 38px;
    margin-bottom: 23px;

    @media (max-width: 1920px) {
        width: ${DeskTopCalVW(38)};
        height: ${DeskTopCalVW(38)};
        margin-bottom: ${DeskTopCalVW(23)};
    }

    // mobile
    @media (max-width: 768px) {
        width: ${MobileCalVW(38)};
        height: ${MobileCalVW(38)};
        margin-bottom: ${MobileCalVW(24.37)};
    }
`

const TextTitle = styled.div`
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 52px;

    @media (max-width: 1920px) {
        font-size: ${DeskTopCalVW(20)};
        line-height: ${DeskTopCalVW(30)};
        margin-bottom: ${DeskTopCalVW(52)};
    }

    // mobile
    @media (max-width: 768px) {
        font-size: ${MobileCalVW(20)};
        line-height: ${MobileCalVW(30)};
        margin-bottom: ${MobileCalVW(52)};
    }
`

const StyledButton = styled.div`
    width: 139px;
    height: 53px;
    border-radius: 10px;
    background-color: #0800A8;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    color: white;
    font-weight: 800;
    font-size: 16px;
    line-height: 19.09px;
    margin-bottom: 471px;

    @media (max-width: 1920px) {
        width: ${DeskTopCalVW(139)};
        height: ${DeskTopCalVW(53)};
        border-radius: ${DeskTopCalVW(10)};
        font-size: ${DeskTopCalVW(16)};
        line-height: ${DeskTopCalVW(19.09)};
        margin-bottom: ${DeskTopCalVW(471)};
    }

    // mobile
    @media (max-width: 768px) {
        width: ${MobileCalVW(139)};
        height: ${MobileCalVW(53)};
        border-radius: ${MobileCalVW(10)};
        font-size: ${MobileCalVW(16)};
        line-height: ${MobileCalVW(19.09)};
        margin-bottom: ${MobileCalVW(471)};
    }
`