import useSWR from 'swr'
import {useToken} from "../auth/token";
import API from "../../../utils/api";
import {message} from "antd";
import {useNavigate} from "react-router";


interface ResponseTypes {
	list: any[];
	total: number;
}

const M_KEY = '/agent/media' // 광고목록 조회

const fetchKey = () =>
	`${M_KEY}`;

export function useAgentMediaList() {
	const { data: token, mutate:tokenMutate } = useToken();

	const KEY = fetchKey();


	const fetcher = () => fetchAPI(KEY, token, tokenMutate);
	const { data, mutate } = useSWR(token ? `${KEY}` : null, fetcher, {
		revalidateOnFocus: false,
		revalidateOnReconnect: false,
		refreshInterval: 3600000,
	});


	return { agentMediaList: data?.list, mutateAgentMediaList: mutate, total: data?.total };
}

async function fetchAPI(KEY:string, token:any, tokenMutate:any): Promise<ResponseTypes> {
	try {
		const response = await API.get(`${KEY}`, token + '', {});
		return {list: response.result?.result?.list || [], total: response.result?.result?.total}
	} catch (error) {
		console.error('Failed to fetch Agent Media List:', error);
		// const IsConfirm = window.confirm(`${String(error)} - 로그인 화면으로 이동합니다.`);
		// if(IsConfirm) {
		// 	tokenMutate(null);
		// }

		return { list: [], total: 0 };
	}
}