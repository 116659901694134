import React, {useState, useEffect} from 'react'
import {Table, Checkbox, message} from 'antd'
import {DBFilterCheckboxArea, DBFilterWrapper, DBTableExcelBtn} from '../../../CRM/organism/DB/styles';
import styled from "styled-components";
import {useToken} from "../../../app/hooks";
import {CommercialFeeList} from "./blocks/CommercialFeeList";
import { numberWithCommas } from '../../../utils/ciutils';


export const CalculationTable = ({agentRefundList, mutateAgentRefundList, total, page, setPage, pageSize, setPageSize, isLoading}) => {

  // 유틸리티
  const [itemId, setItemId] = useState(null)

  // 데이터 조회


  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const {data: token} = useToken();


  useEffect(() => {
    const allSelected = agentRefundList?.length > 0 && agentRefundList?.every(it => selectedRows?.includes(it.refundId));
    setIsAllChecked(allSelected);
  }, [selectedRows, agentRefundList]);

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const columns = [
    {
      dataIndex: 'no',
      title: 'No',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'account',
      title: '계좌번호',
      render: (value, data) => {
        return (
          <div className='email'
               style={{
                 justifyContent: 'center',
                 display: 'flex'
               }}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'settlementAmount',
      title: '광고수익',
      render: (value, data, index) => {
        return (
          <div
            className='No.'
            onClick={() => setItemId(data?.settlementId)}
            style={{justifyContent: 'flex-end', display: 'flex',   cursor: 'pointer', textDecoration: 'underline', color: 'blue'}}
          >{numberWithCommas(value)+' 원'}</div>
        )
      }
    },
    {
      dataIndex: 'settlementTargetMonth',
      title: '정산일',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'settlementStatus',
      title: '상태',
      render: (value, data) => {
        return (
          <div className='type' style={{
            justifyContent: 'center',
            display: 'flex'
          }}>{value || ''}</div>
        )
      }
    },
    {
      dataIndex: 'settlementDate',
      title: '비고',
      render: (value, data) => {
        return (
          <div className='type' style={{
            justifyContent: 'center',
            display: 'flex'
          }}>{value || ''}</div>
        )
      }
    },
  ];

  return (
    <DBFilterWrapper>
      <DBFilterCheckboxArea>

      </DBFilterCheckboxArea>
      <StyleTable columns={columns}
                  dataSource={agentRefundList}
                  bordered={true}
                  loading={isLoading}
                  pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: total,
                  }}
                  onChange={handleTableChange}
      />


      {itemId !== null && <CommercialFeeList setItemId={setItemId} itemId={itemId}/>}
    </DBFilterWrapper>
  )
}

export default React.memo(CalculationTable);

const ColumnHeader = styled.div`

`

const StyleTable = styled(Table)`
    .ant-table-thead > tr > th {
        background-color: #F4F2FF;
        color: #00000099;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.71px;
    }
`
