import React, {useEffect, useState, useTransition} from 'react'

import {ReactComponent as ModalCloseIcon} from "../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import {ReactComponent as CustomerCard} from "../../../../assets/HomeTownLogin/CustomerCard.svg";
import {ReactComponent as RealEstateCard} from "../../../../assets/HomeTownLogin/RealEstateCard.svg";
import styled from "styled-components";
import {message, Select} from "antd";

import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import API from "../../../utils/api";
import {Blacked} from "../../../CRM/components/styles";
import {useUserInfo} from "../../hooks/Auth/GetUserInfo";
import {useToken} from "../../../app/hooks";
import { Text } from '../../../styles/reactnative';
import {SelectAddressItem} from "../../Customer/MediaList/blocks/SelectAddressItem";


const firebaseConfig = {
    apiKey: "AIzaSyD_rKVGvFk05UyYeq2XUc34NHiNTsDx8oE",
    authDomain: "hometown-37193.firebaseapp.com",
    projectId: "hometown-37193",
    storageBucket: "hometown-37193.appspot.com",
    messagingSenderId: "646365627133",
    appId: "1:646365627133:web:b574f16d1346f4d962f494",
    measurementId: "G-9B1MNTFY0E"
};


if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const auth = firebase.auth();



export const AdminDetail = ({adminDetailModal, setAdminDetailModal}) => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [store, setStore] = useState('');
    const [address, setAddress] = useState('');
    const [detailAddress, setDetailAddress] = useState('');
    const [email, setEmail] = useState('');
    const [account, setAccount] = useState('');
    const [password, setPassword] = useState('');
    const [businessType, setBusinessType] = useState('');
    const [region, setRegion] = useState('');
    const [coordinates, setCoordinates] = useState(null);

    // 유틸리티
    const [isPending, startTransition] = useTransition();
    const [verificationCode, setVerificationCode] = useState('');
    const [confirmationResult, setConfirmationResult] = useState<any>(null);
    const [isOpenAdminForm, setIsOpenAdminForm] = useState<any>(false);

    // 주소 검색
    const [selectedFirstName, setSelectedFirstName] = React.useState<any>(null);
    const [selectedSecondName, setSelectedSecondName] = React.useState<any>(null);
    const [selectedThirdName, setSelectedThirdName] = React.useState<any>(null);

    // 데이터 조회
    const { userInfo, mutateUserInfo } = useUserInfo();
    const {data: token} = useToken();


    // 관리자 생성
    const [adminName, setAdminName] = useState('');
    const [adminEmail, setAdminEmail] = useState('');
    const [adminPassword, setAdminPassword] = useState('');


    useEffect(() => {
        console.log('userInfo', userInfo);

        setName(userInfo?.name)
        setPhone(userInfo?.phone)
        setStore(userInfo?.store)
        setAddress(userInfo?.address)
        setEmail(userInfo?.email)
        setAccount(userInfo?.account)
        setPassword(userInfo?.password)
        setBusinessType(userInfo?.businessType)
        setDetailAddress(userInfo?.detailAddress)

        if(userInfo?.region){
            const AddressArray = userInfo?.region?.split(' ')
            setSelectedFirstName(AddressArray[0]);
            setSelectedSecondName(AddressArray[1]);
            setSelectedThirdName(AddressArray[2]);
        }

    }, [userInfo]);


    useEffect(() => {
        const fullAddress = `${selectedFirstName || ''} ${selectedSecondName || ''} ${selectedThirdName || ''}`;
        // console.log('fullAddress', fullAddress)

        if (selectedFirstName || selectedSecondName || selectedThirdName) {
            setRegion(fullAddress);
        }
    }, [selectedFirstName, selectedSecondName, selectedThirdName]);


    // useEffect(() => {
    //     // Initialize reCAPTCHA when component mounts
    //     if (!window.recaptchaVerifier) {
    //         window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
    //             'size': 'invisible',
    //             'callback': (response) => {
    //                 console.log('reCAPTCHA solved', response);
    //             },
    //             'expired-callback': () => {
    //                 console.log('reCAPTCHA expired');
    //             }
    //         });
    //     }
    // }, []);

    const sendVerificationCode = () => {
        const appVerifier = window.recaptchaVerifier;
        auth.signInWithPhoneNumber(phone, appVerifier)
          .then((confirmationResult) => {
              setConfirmationResult(confirmationResult);
              message.success(`${phone}으로 인증번호를 전송하였습니다.`)
          }).catch((error) => {
            message.error(`${error}`)
        });
    };

    const verifyCode = () => {
        confirmationResult.confirm(verificationCode)
          .then((result) => {
              const user = result.user;
              console.log("User signed in:", user);
          }).catch((error) => {
            console.error("Code verification failed", error);
        });
    };

    // const setupRecaptcha = () => {
    //     window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
    //         'size': 'invisible',
    //         'callback': (response) => {
    //             // reCAPTCHA solved, allow signInWithPhoneNumber.
    //             // onSignInSubmit();
    //             console.log('response', response)
    //         }
    //     });
    //
    //     // window.recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
    //     //     'size': 'invisible',
    //     //     'callback': (response) => {
    //     //         // reCAPTCHA solved, allow signInWithPhoneNumber.
    //     //        console.log('response', response)
    //     //     }
    //     // });
    // };

    // const sendVerificationCode = () => {
    //     setupRecaptcha();
    //     const appVerifier = window.recaptchaVerifier;
    //     signInWithPhoneNumber(auth, phone, appVerifier)
    //       .then((confirmationResult) => {
    //           setConfirmationResult(confirmationResult);
    //           alert('Verification code sent to your phone');
    //       }).catch((error) => {
    //         console.error("SMS not sent", error);
    //     });
    // };



    // const sendVerificationCode = () => {
    //     console.log('phone', phone)
    //
    //     setupRecaptcha();
    //     const appVerifier = window.recaptchaVerifier;
    //     auth.signInWithPhoneNumber(phone, appVerifier)
    //       .then((confirmationResult) => {
    //           setConfirmationResult(confirmationResult);
    //           alert('Verification code sent to your phone');
    //       }).catch((error) => {
    //         console.error("SMS not sent", error);
    //     });
    // };




    const putEditUser = () => {
        const shouldEdit = window.confirm(`수정하시겠습니까?`);
        if(shouldEdit) {
            if(!name && !phone && !address && !email && !password && !region && !detailAddress) {
                message.error('필수 사항을 입력해주세요.')
            } else {
                API.put('/user/info', token + '', {
                   datas: {
                       email2:email,
                       password,
                       name,
                       phone,
                       store,
                       address,
                       email,
                       account,
                       businessType,
                       region,
                       detailAddress
                   }
                })
                  .then((res: any) => {
                      if (res.result.success) {
                          message.success('수정되었습니다.');
                          setAdminDetailModal(false);
                          mutateUserInfo();
                      } else {
                          message.error(res.result.message ||  '실패하였습니다.');
                      }
                  })
                  .catch((err: any) => {
                      message.error('실패하였습니다.');
                  })
            }
        }
    }


    const postAdminUser = () => {
        const shouldEdit = window.confirm(`관리자를 생성하시겠습니까?`);
        if(shouldEdit) {
            if(!name && !email && !password) {
                message.error('필수 사항을 입력해주세요.')
            } else {
                API.post('/admin/account', token + '', {
                    name: adminName,
                    email: adminEmail,
                    password: adminPassword,
                })
                  .then((res: any) => {
                      if (res.result.success) {
                          message.success('생성되었습니다.');
                          setIsOpenAdminForm(false);
                          mutateUserInfo();
                      } else {
                          message.error(res.result.message ||  '실패하였습니다.');
                      }
                  })
                  .catch((err: any) => {
                      message.error('실패하였습니다.');
                  })
            }
        }
    }

    const openPostcode = () => {
        new window.daum.Postcode({
            oncomplete: function (data: any) {
                const fullAddress = data?.address;
                startTransition(() => {
                    setAddress(fullAddress);
                    // // 좌표 변환 API 호출
                    // handleGeocode(fullAddress);
                });
            }
        }).open();
    };

    // const handleGeocode = (fullAddress: string) => {
    //     navermaps.Service.geocode({
    //         query: fullAddress
    //     }, (status, response) => {
    //         console.log(status);
    //         if (status === navermaps.Service.Status.OK) {
    //             const result = response.v2.addresses[0];
    //             const lat = parseFloat(result.y);
    //             const lng = parseFloat(result.x);
    //             // startTransition(() => {
    //             //     setCoordinates({ lat, lng });
    //             // });
    //             console.log('Coordinates:', { lat, lng });
    //         } else {
    //             console.error('Geocoding failed:', response);
    //         }
    //     });
    // };


    const deleteUser = () => {
        const shouldEdit = window.confirm(`탈퇴하시겠습니까?`);
        if(shouldEdit) {
            API.delete('/user/info', token + '', {})
              .then((res: any) => {
                  if (res.result.success) {
                      message.success('탈퇴되었습니다.');
                      setAdminDetailModal(false);
                      mutateUserInfo();
                  } else {
                      message.error(res.result.message ||  '실패하였습니다.');
                  }
              })
              .catch((err: any) => {
                  message.error('실패하였습니다.');
              })
        }
    }



    return (
      <>
          <Blacked onClick={() => setAdminDetailModal(false)}/>
          <ModalWrapper >
              <ModalHeader>
                  <ModalTitle>설정</ModalTitle>
                  <CloseIconSVG as={ModalCloseIcon} onClick={() => {setAdminDetailModal(false)}  }/>
              </ModalHeader>

              <Table>
                  <tbody>
                  <TableRow>
                      <Th>
                          <ThLabel>
                              이름
                              <RequireIcon>*</RequireIcon>
                          </ThLabel>
                      </Th>
                      <Td><Input type="text" value={name} onChange={e => setName(e.target.value)}/></Td>
                  </TableRow>
                  {/*<TableRow>*/}
                  {/*    <Th>*/}
                  {/*        <ThLabel>*/}
                  {/*            연락처*/}
                  {/*            <RequireIcon>*</RequireIcon>*/}
                  {/*        </ThLabel>*/}
                  {/*    </Th>*/}
                  {/*    <Td>*/}
                  {/*        <TdRowContents style={{paddingRight: 167}}>*/}
                  {/*            <Input  type="text" value={phone} onChange={e => setPhone(e.target.value)} placeholder={'ex. 010-1234-1234'}/>*/}
                  {/*            /!*<GreyButton id={'recaptcha-container'} onClick={() => sendVerificationCode()}>*!/*/}
                  {/*            /!*    휴대폰인증*!/*/}
                  {/*            /!*</GreyButton>*!/*/}
                  {/*        </TdRowContents>*/}
                  {/*    </Td>*/}
                  {/*</TableRow>*/}
                  {/*<TableRow>*/}
                  {/*    <Th>*/}
                  {/*        <ThLabel>*/}
                  {/*            지역*/}
                  {/*            <RequireIcon>*</RequireIcon>*/}
                  {/*        </ThLabel>*/}
                  {/*    </Th>*/}
                  {/*    <Td>*/}
                  {/*        <SelectAddressItem selectedFirstName={selectedFirstName} setSelectedFirstName={setSelectedFirstName}*/}
                  {/*                           selectedSecondName={selectedSecondName} setSelectedSecondName={setSelectedSecondName}*/}
                  {/*                           selectedThirdName={selectedThirdName} setSelectedThirdName={setSelectedThirdName}/>*/}
                  {/*    </Td>*/}
                  {/*</TableRow>*/}
                  {/*<TableRow>*/}
                  {/*    <Th>*/}
                  {/*        <ThLabel>*/}
                  {/*            주소*/}
                  {/*            <RequireIcon>*</RequireIcon>*/}
                  {/*        </ThLabel>*/}
                  {/*    </Th>*/}
                  {/*    <Td>*/}
                  {/*        <TdRowContents>*/}
                  {/*            <GreyButton onClick={() => openPostcode()}>주소검색</GreyButton>*/}
                  {/*            <Input type="text" value={address} disabled/>*/}
                  {/*        </TdRowContents>*/}
                  {/*    </Td>*/}
                  {/*</TableRow>*/}
                  {/*<TableRow>*/}
                  {/*    <Th>*/}
                  {/*        <ThLabel>*/}
                  {/*            세부주소*/}
                  {/*            <RequireIcon>*</RequireIcon>*/}
                  {/*        </ThLabel>*/}
                  {/*    </Th>*/}
                  {/*    <Td><Input type="text" value={detailAddress} onChange={e => setDetailAddress(e.target.value)}/></Td>*/}
                  {/*</TableRow>*/}
                  <TableRow>
                      <Th>
                          <ThLabel>
                              메일(아이디)
                              <RequireIcon>*</RequireIcon>
                          </ThLabel>
                      </Th>
                      <Td><Input type="text" placeholder={'test@gmail.com'} value={email} onChange={e => setEmail(e.target.value)}/></Td>
                  </TableRow>
                  <TableRow>
                      <Th>
                          <ThLabel>
                              비밀번호
                              <RequireIcon>*</RequireIcon>
                          </ThLabel>
                      </Th>
                      <Td><Input type="text"  value={password} onChange={e => setPassword(e.target.value)}/></Td>
                  </TableRow>
                  {/*<TableRow>*/}
                  {/*    <Th>*/}
                  {/*        <ThLabel>*/}
                  {/*            상호*/}
                  {/*        </ThLabel>*/}
                  {/*    </Th>*/}
                  {/*    <Td><Input type="text" value={store} onChange={e => setStore(e.target.value)}/></Td>*/}
                  {/*</TableRow>*/}
                  {/*<TableRow>*/}
                  {/*    <Th>*/}
                  {/*        <ThLabel>*/}
                  {/*            업종*/}
                  {/*        </ThLabel>*/}
                  {/*    </Th>*/}
                  {/*    <Td><Input type="text" value={businessType} onChange={e => setBusinessType(e.target.value)}/></Td>*/}
                  {/*</TableRow>*/}
                  <TableRow>
                      <Th>
                          <ThLabel>
                              계좌번호
                          </ThLabel>
                      </Th>
                      <Td><Input type="text" placeholder={'한국은행/123-45-678/홍길동'} value={account} onChange={e => setAccount(e.target.value)}/>
                          <Desc>{`계좌번호 예시 : 한국은행/123-45-789/홍길동
* 예치금 환급시 사용됩니다.`}</Desc>
                      </Td>
                  </TableRow>
                  </tbody>
              </Table>

              <ButtonWrapper>
                  <SignUpButton onClick={() => putEditUser()}>
                      수정
                  </SignUpButton>
                  <DeleteButton onClick={() => deleteUser()}>
                      탈퇴
                  </DeleteButton>
                  <SignUpButton style={{backgroundColor: '#e54848'}} onClick={() => setIsOpenAdminForm(true)}>
                      관리자 생성
                  </SignUpButton>
              </ButtonWrapper>


              {isOpenAdminForm &&
              <AdminFormWrapper>
                  <ModalHeader>
                      <ModalTitle>관리자 생성</ModalTitle>
                      <AdminConfirmButton onClick={() => {postAdminUser()}}>
                          생성
                      </AdminConfirmButton>
                  </ModalHeader>

                  <Table>
                      <tbody>
                      <TableRow>
                          <Th>
                              <ThLabel>
                                  이름
                                  <RequireIcon>*</RequireIcon>
                              </ThLabel>
                          </Th>
                          <Td><Input type="text" value={adminName} onChange={e => setAdminName(e.target.value)}/></Td>
                      </TableRow>
                      <TableRow>
                          <Th>
                              <ThLabel>
                                  메일(아이디)
                                  <RequireIcon>*</RequireIcon>
                              </ThLabel>
                          </Th>
                          <Td><Input type="text" placeholder={'test@gmail.com'} value={adminEmail} onChange={e => setAdminEmail(e.target.value)}/></Td>
                      </TableRow>
                      <TableRow>
                          <Th>
                              <ThLabel>
                                  비밀번호
                                  <RequireIcon>*</RequireIcon>
                              </ThLabel>
                          </Th>
                          <Td><Input type="text"  value={adminPassword} onChange={e => setAdminPassword(e.target.value)}/></Td>
                      </TableRow>
                      </tbody>
                  </Table>
              </AdminFormWrapper>
              }

          </ModalWrapper>
      </>
    )
}


const Desc = styled.div`
    white-space: pre-wrap;
    margin-top: 10px;
    margin-left: 5px;
    font-size: 13px;
    color: #757575;
`

const AdminFormWrapper = styled.div`
    width: 550px;
    margin-top: 70px;
`

const AdminConfirmButton = styled.div`
    background-color: #530DAD;
    color: white;
    padding: 6px 10px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
`


const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 800px;
    width: 600px;
    min-height: 411px;
    background-color: #fff;
    overflow-y: auto;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #2E2559;
`

const Table = styled.table`
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
    border: 1px solid #ccc;
`;

const TableRow = styled.tr`
    min-height: 40px;
`;

const Th = styled.th`
    padding: 10px;
    text-align: left;
    width: 22%;
    border: 1px solid #ccc;
    background-color: #F4F2FF;
`;

const ThLabel = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
    color: #00000099;
    display: flex;
    gap: 2px;
`

const RequireIcon = styled.p`
    color: #F15151;
`

const Td = styled.td`
    padding: 10px;
    text-align: left;
    width: 78%;
    border: 1px solid #ccc;
`;

const TdRowContents = styled.div`
    display: flex;
    align-items: center;

    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
    font-family: Pretendard, sans-serif;
    color: #000000;
`

const DescText = styled.div`
  font-weight: 400;
    font-size: 14px;
    font-family: Pretendard, sans-serif;
    line-height: 16.71px;
    color: #00000099;
    margin-top: 8px;
    margin-bottom: 20px;
    white-space: pre-wrap;
`

const Input = styled.input`
    width: 100%;
    height: 28px;
    padding-left: 10px;
    border: 1px solid #2E2559;
    border-radius: 4px;
    display: flex;
`;

const FileInput = styled.input`
    width: 100%;
    padding: 2px;
    border: 1px solid #2E2559;
    border-radius: 4px;
    display: flex;
`;

const GreyButton = styled.div`
    height: 31px;
    width: 120px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0000001A;
    cursor: pointer;
    color: #000000CC !important;
    margin: 0 4px;

    font-size: 14px;
    font-weight: 500;
    line-height: 16.71px;
`

const StyledSelect = styled(Select)`
    width: 160px;
    border: 1px solid #00000033;
    border-radius: 7px;
`

const SignUpButton = styled.div`
    width: 100px;
    padding: 11px;
    background-color: #530DAD;
    color: #fff;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;


const DeleteButton = styled.div`
    width: 100px;
    padding: 11px;
    background-color: white;
    color: #000000;
    border: 1px solid #000000;
    border-radius: 2px;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;

const ButtonWrapper = styled.div`
    display: flex;
    gap: 5px;
`