import React, {useState, useEffect} from 'react'
import {Table, Checkbox, message} from 'antd'
import {DBFilterCheckboxArea, DBFilterWrapper, DBTableExcelBtn} from '../../../CRM/organism/DB/styles';
import styled from "styled-components";
import {useToken} from "../../../app/hooks";
import {NoticeDetail} from "../../RealEstate/Notice/blocks/NoticeDetail";


export const AdminNoticeTable = ({
                                   adminNoticeList,
                                   mutateAdminNoticeList,
                                   categoryItem,
                                   total,
                                   page,
                                   setPage,
                                   pageSize,
                                   setPageSize,
                                   isLoading,
                                   setIsSelectItem,isSelectItem, selectedRows, setSelectedRows, isAllChecked, setIsAllChecked
                                 }) => {

  const [itemId, setItemId] = useState(null);
  const {data: token} = useToken();


  useEffect(() => {
    const allSelected = adminNoticeList?.length > 0 && adminNoticeList?.every(it => selectedRows?.includes(it.postId));
    setIsAllChecked(allSelected);
  }, [selectedRows, adminNoticeList]);


  const handleCheckboxChange = (e, id) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows?.filter(rowId => rowId !== id));
    }
  };


  const handleSelectAllChange = (e) => {
    setIsAllChecked(e.target.checked);
    const ids = adminNoticeList?.map(db => db.postId);
    setSelectedRows(e.target.checked ? ids : []);
  };



  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const customerColumns = [
    {
      dataIndex: '',
      title: isSelectItem ?
        ( <Checkbox
          checked={isAllChecked}
          onChange={handleSelectAllChange}
        />)
        :
        'No'
      ,
      render: (value, data, index) => {
        return (
          <>
            {isSelectItem ?
              (<div className='checkbox' style={{justifyContent: 'center', display: 'flex'}}>
                <Checkbox checked={selectedRows?.includes(data.postId)} key={data?.postId} onChange={(e) => handleCheckboxChange(e, data?.postId)} />
              </div>)
              :
              <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{data?.postId || '-'}</div>
            }
          </>
        )
      }
    },
    // {
    //   dataIndex: 'postId',
    //   title: 'No',
    //   render: (value, data, index) => {
    //     return (
    //       <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
    //     )
    //   }
    // },
    {
      dataIndex: 'targetType',
      title: '대상',
      render: (value, data) => {
        return (
          <div className='email' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'target',
      title: '상세지역',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'title',
      title: '제목',
      render: (value, data, index) => {
        return (
          <div className='No.' onClick={() => setItemId(data?.postId)} style={{
            justifyContent: 'center',
            display: 'flex',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}>{value  || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '첨부파일',
      render: (value, data) => {
        return (
          <div className='type'
               style={{justifyContent: 'center', display: 'flex'}}>{`${data?.fileList?.length || 0}`}</div>
        )
      }
    },
    {
      dataIndex: 'createdAt',
      title: '등록일',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{value}</div>
        )
      }
    }
  ];


  return (
    <DBFilterWrapper>

      <StyleTable columns={customerColumns}
                  dataSource={adminNoticeList}
                  bordered={true}
                  loading={isLoading}
                  pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: total,
                  }}
                  onChange={handleTableChange}
      />

      {itemId !== null && <NoticeDetail itemId={itemId} setItemId={setItemId}/>}

    </DBFilterWrapper>
  )
}

export default React.memo(AdminNoticeTable);

const ColumnHeader = styled.div`

`

const StyleTable = styled(Table)`
    .ant-table-thead > tr > th {
        background-color: #F4F2FF;
        color: #00000099;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.71px;
    }
`
