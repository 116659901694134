import React, {useState} from 'react'
import {Blacked} from "../../../../CRM/components/styles";
import {ReactComponent as ModalCloseIcon} from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import {ReactComponent as CustomerCard} from "../../../../assets/HomeTownLogin/CustomerCard.svg";
import {ReactComponent as RealEstateCard} from "../../../../assets/HomeTownLogin/RealEstateCard.svg";
import styled from "styled-components";
import {Input, message, Select} from "antd";
import {HomeTownRoundCheckbox} from "../../../../app/organisms/Auth/Forms/HomeTownRoundCheckbox";
import TextArea from "antd/es/input/TextArea";
import {useToken} from "../../../../app/hooks";
import API from "../../../../utils/api";


export const CommercialCancel = ({itemId, setIsCancelModal, mutate}) => {
    const { data: token } = useToken();

    const [rejectReason, setRejectReason] = useState('');
    const [etcReason, setEtcReason] = useState('');


    const permitCommercial = () => {
        const shouldEdit = window.confirm(`광고를 거절하시겠습니까?`);
        if (shouldEdit) {
            API.post('/agent/ad/list/permit', token + '', {
                ids: [itemId],
                status : 2,
                rejectReason : etcReason ? etcReason : rejectReason
            })
              .then((res: any) => {
                  if (res.result.success) {
                      message.success(`거절하였습니다.`);
                      setIsCancelModal(false);
                      mutate();
                  } else {
                      message.error(res.result.message || '실패하였습니다');
                  }
              })
              .catch((err: any) => {
                  message.error('실패하였습니다.')
              })
        }
    }

    const SelectItem = [
        {name: '광고내용 부적절' ,value:false},
        {name: '이미지 해상도 흐림' ,value:false},
        {name: '기피업종' ,value:false},
        {name: '오타발견' ,value:false},
        {name: '기타(사유직접작성)' ,value:true},
    ]

    return (
      <>
          <Blacked onClick={() => setIsCancelModal(null)}/>
          <ModalWrapper className={"scroll-container"}>
              <ModalHeader>
                  <ModalTitle>거절 사유선택</ModalTitle>
                  <CloseIconSVG as={ModalCloseIcon} onClick={() => {setIsCancelModal(null)}  }/>
              </ModalHeader>

              <Contents>
                  {SelectItem.map((item, index) => (
                    <HomeTownRoundCheckbox text={item?.name} checked={ rejectReason === item?.name} onClick={() => setRejectReason(item?.name) } />
                  ))}
              </Contents>

              {rejectReason === '기타(사유직접작성)' &&
                <StyledTextInput placeholder={'사유작성'} value={etcReason} onChange={e => setEtcReason(e.target.value as string)} />
              }

              <ButtonsWrapper>
                  <SignUpButton onClick={() => permitCommercial()}>
                      확인
                  </SignUpButton>

                  <CancelButton onClick={() => setIsCancelModal(null)}>
                      취소
                  </CancelButton>
              </ButtonsWrapper>
          </ModalWrapper>
      </>
    )
}

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 800px;
    width: 580px;
    min-height: 211px;
    background-color: #fff;
    overflow-y: auto;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #2E2559;
`

const Contents = styled.div`
    width: 100%;
    margin-top: 20px;
    margin-bottom: 6px;
    display: flex;
    flex-direction: column;
    gap: 14px;
`

const StyledTextInput = styled(TextArea)`
    height: 84px !important;
    
`

const ButtonsWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    gap: 6px;
`

const SignUpButton = styled.div`
    width: 100px;
    height: 40px;
    padding: 11px;
    background-color: #530DAD;
    color: #fff;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;

const CancelButton = styled.div`
    width: 100px;
    height: 40px;
    padding: 11px;
    border: 1px solid #000000;
    color: #000000;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;