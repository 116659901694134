import React, {useState} from 'react'
import {Blacked} from "../../../../CRM/components/styles";
import {ReactComponent as ModalCloseIcon} from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import {ReactComponent as CustomerCard} from "../../../../assets/HomeTownLogin/CustomerCard.svg";
import {ReactComponent as RealEstateCard} from "../../../../assets/HomeTownLogin/RealEstateCard.svg";
import styled from "styled-components";
import {ReactComponent as DownloadIcon} from "../assets/DownloadIcon.svg";
import {ReactComponent as LeftArrowIcon} from "../assets/LeftArrowIcon.svg";
import {ReactComponent as RightArrowIcon} from "../assets/RightArrowIcon.svg";
import {useAgentNoticeInfo} from "../../../hooks/Agent/Notice/GetAgentNoticeInfo";
import {MEDIA_URL} from "../../../../app/constants/APIKEYS";
import {message} from "antd";

export const NoticeDetail = ({itemId, setItemId}) => {
   const {agentNoticeInfo, mutateAgentNoticeInfo} = useAgentNoticeInfo(itemId)

    const handleDownload = async (url, name) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const link = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);
            link.href = objectUrl;
            link.style.display = 'none';
            link.download = name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(objectUrl);
        } catch (error) {
            message.error('다운로드에 실패하였습니다.');
        }
    };

    const handleDownloadAll = async (fileList) => {
        try {
            await Promise.all(
              fileList.map(file => handleDownload(MEDIA_URL + file.url, file.name))
            );
            message.success('모든 파일을 저장하였습니다.');
        } catch (error) {
            message.error('모든 파일 저장에 실패하였습니다.');
        }
    };

    return (
      <>
          <Blacked onClick={() => setItemId(null)}/>
          <ModalWrapper className={"scroll-container"}>
              <ModalHeader>
                  <ModalTitle>공지사항 상세</ModalTitle>
                  <CloseIconSVG as={ModalCloseIcon} onClick={() => {setItemId(null)}  }/>
              </ModalHeader>
              
              <Table>
                  <tbody>
                  <TableRow>
                      <Th>
                          <ThLabel>
                              제목
                          </ThLabel>
                      </Th>
                      <Td colSpan={3}>{agentNoticeInfo?.title}</Td>
                  </TableRow>
                  <TableRow>
                      <Th>
                          <ThLabel>
                              대상
                          </ThLabel>
                      </Th>
                      <Td >
                          {agentNoticeInfo?.targetType}
                      </Td>

                      <Th>
                          <ThLabel>
                              상세지역
                          </ThLabel>
                      </Th>
                      <Td >
                          {agentNoticeInfo?.target}
                      </Td>
                  </TableRow>
                  <TableRow>
                      <Th>
                          <ThLabel>
                              등록일
                          </ThLabel>
                      </Th>
                      <Td colSpan={3}>{agentNoticeInfo?.createdAt}</Td>
                  </TableRow>
                  <TableRow>
                      <Th>
                          <ThLabel>
                              첨부파일
                          </ThLabel>
                      </Th>
                      <Td colSpan={3}>
                          <div>
                              <div style={{display: 'flex', fontWeight: '500', fontSize: 14, lineHeight: '16.71px', marginBottom: 12}}>
                                  {`${agentNoticeInfo?.fileList?.length || 0}건`}
                                  <div style={{fontWeight: '300', color: '#00000066', marginLeft: 6}}>|</div>
                                  <div onClick={() => handleDownloadAll(agentNoticeInfo?.fileList)} style={{fontWeight: '400', color: '#125DE6CC', textDecoration: 'underline', marginLeft: 6, cursor: 'pointer'}}>모두저장</div>
                              </div>

                              {agentNoticeInfo?.fileList?.map((item, index) => (
                                <div style={{
                                    backgroundColor: '#FAFAFC',
                                    width: 592,
                                    height: 29,
                                    padding: '6px 8px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: 4
                                }}>
                                    <div>{item?.name || '-'}</div>
                                    <DownloadSVG as={DownloadIcon} onClick={() => handleDownload(MEDIA_URL + item?.url, item?.name)}/>
                                </div>
                              ))}

                          </div>
                      </Td>
                  </TableRow>
                  <TableRow>
                      <Th>
                          <ThLabel>
                              내용
                          </ThLabel>
                      </Th>
                      <Td colSpan={3}>
                          <div style={{minHeight: 356, display: 'flex', justifyContent: 'space-between', whiteSpace: 'pre-wrap'}}>
                              {agentNoticeInfo?.content}
                          </div>
                      </Td>
                  </TableRow>
                  </tbody>
              </Table>

              <ButtonsWrapper>
                  {/*<PrevNextText>*/}
                  {/*    <ArrowSVG as={LeftArrowIcon}/>*/}
                  {/*    이전글*/}
                  {/*</PrevNextText>*/}

                  <CancelButton onClick={() => setItemId(null)}>
                      확인
                  </CancelButton>

                  {/*<PrevNextText>*/}
                  {/*    다음글*/}
                  {/*    <ArrowSVG as={RightArrowIcon}/>*/}
                  {/*</PrevNextText>*/}
              </ButtonsWrapper>
          </ModalWrapper>
      </>
    )
}

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 808px;
    width: 780px;
    background-color: #fff;
    overflow-y: auto;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #2E2559;
`

const Table = styled.table`
    width: 100%;
    margin-top: 8px;
    border-collapse: collapse;
    border: 1px solid #ccc;
`;

const TableRow = styled.tr`
    min-height: 40px;
`;

const Th = styled.th`
    padding: 10px;
    text-align: left;
    width: 20%;
    border: 1px solid #ccc;
    background-color: #F4F2FF;
    vertical-align: middle;
`;

const ThLabel = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
    color: #00000099;
    
`

const Td = styled.td`
    padding: 10px;
    text-align: left;
    border: 1px solid #ccc;
`;


const ButtonsWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`


const CancelButton = styled.div`
    width: 100px;
    height: 40px;
    padding: 11px;
    border: 1px solid #000000;
    color: #000000;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;

const DownloadSVG = styled.svg`
    cursor: pointer;
    width: 16px;
`

const PrevNextText = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
    font-family: Pretendard, sans-serif;
    color: #00000099;
    cursor: pointer;
`

const ArrowSVG = styled.svg`
    width: 20px;
`