import useSWR from 'swr'
import API from '../../utils/api'
import { getPersistData } from '../../app/hooks/persist'
import { useToken } from '../../app/hooks'

const KEY = '/user/info' // (1) 회원정보 가져오기

let swrData: any = getPersistData(KEY)
//본사팀장, 마스터, 어드민, 리더
export const LEVEL = {
	MASTER: 9,
	ADMIN: 8,
	LEADER: 7,
	USER: 6,
	BRANCHLEADER: 5,
	BRANCHUSER: 4,	
}

export function useMyInfo() {
	const { data: token } = useToken()
	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			const response = await API.get(KEY, token + '', {})
			swrData = response.result?.result

			return swrData
		},
		{ revalidateOnFocus: true }
	)

	let level = LEVEL.MASTER

	if(data?.master == 1){
		level = LEVEL.MASTER
	}else if(data?.admin == 1){
		level = LEVEL.ADMIN
	}else{
		if(data?.branchId != 1){
			if(data?.leader == 1){
				level = LEVEL.BRANCHLEADER
			}else{
				level = LEVEL.BRANCHUSER
			}
		}else{
			if(data?.leader == 1){
				level = LEVEL.LEADER
			}else{
				level = LEVEL.USER
			}
		}

	}
		


	return {
		myInfo: data,
		level: level,
		mutate: (value?: any) => {
			if (value != undefined) {
				swrData = value
			}
			return mutate()
		},
	}
}