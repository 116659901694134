import useSWR from 'swr'
import {useToken} from "../../../../app/hooks";
import API from "../../../../utils/api";
import {useEffect} from "react";

interface ResponseTypes {
	list: any[];
	total: number;
}

const M_KEY = '/admin/payment/advertiser' // 광고목록 조회

const fetchKey = (page, maxData, searchWord, searchType) =>
	`${M_KEY}?${page && 'page=' + page}${maxData && '&maxData=' + maxData}${searchWord && '&searchKeyword=' + searchWord}${searchType && '&searchType=' + searchType}`;

export function useAdminPaymentADList(page=1, maxData=1000, searchWord='', searchType='') {
	const { data: token } = useToken();

	const KEY = fetchKey(page, maxData, searchWord, searchType);


	const fetcher = () => fetchAPI(KEY, token);
	const { data, mutate, isLoading } = useSWR(token ? `${KEY}` : null, fetcher, { revalidateOnFocus: true });

	return { adminPaymentADList: data?.list, mutateAdminPaymentADList: mutate, total: data?.total, isLoading };
}

async function fetchAPI(KEY:string, token:any): Promise<ResponseTypes> {
	try {
		const response = await API.get(`${KEY}`, token + '', {});
		return {list: response.result?.result?.list || [], total: response.result?.result?.totalCount}
	} catch (error) {
		console.error('Failed to fetch Admin Payment AD List:', error);
		return { list: [], total: 0 };
	}
}