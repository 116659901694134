import useSWR from 'swr'
import {useToken} from "../../../../app/hooks";
import API from "../../../../utils/api";
import {useEffect} from "react";

interface ResponseTypes {
	list: any[];
	total: number;
}

const M_KEY = '/maker/user/list' // 광고목록 조회

const fetchKey = (id) =>
	`${M_KEY}/${id ? id : ''}`;

export function useMakerUserDetail(id) {
	// const { data: token } = useToken();

	const KEY = fetchKey(id);


	const fetcher = () => fetchAPI(KEY);
	const { data, mutate, isLoading } = useSWR(`${KEY}`, fetcher, { revalidateOnFocus: true });

	return { makerUserDetail: data?.list, mutateMakerUserDetail: mutate, total: data?.total, isLoading };
}

async function fetchAPI(KEY:string): Promise<ResponseTypes> {
	try {
		const response = await API.get(`${KEY}`, '', {});
		return {list: response.result?.resunt || {}, total: response.result?.result?.totalCount}
	} catch (error) {
		console.error('Failed to fetch Maker User Detail:', error);
		return { list: [], total: 0 };
	}
}