import React from 'react'
import styled from 'styled-components'
import {ReceiptPaper} from "./molecules/ReceiptPaper";
import {PaymentMethod} from "./molecules/PaymentMethod";
import {media} from "../../../utils/mediaUtil";


export const QRReceipt = () => {
  return(
    <Page>
      {/*<StoreNameWrapper>*/}
      {/*  <StoreName>*/}
      {/*    매장명*/}
      {/*  </StoreName>*/}
      {/*</StoreNameWrapper>*/}

      {/*<ReceiptPaper/>*/}

      {/*<Line/>*/}

      <PaymentMethod/>

    </Page>
  )
}

const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
`

const StoreNameWrapper = styled.div`
    width: 100%;
    text-align: end;
    padding: 2%;
    
`

const StoreName = styled.div`

`

const Line = styled.div`
    height: 1px;
    background-color: #a0a0a0;
    width: 40%;
    margin: 80px auto;

    @media ${media.mobile} {
        width: 95%;
    }
`

