import React, {useEffect, useState} from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import * as PAGE from "./pagesImport";
import { GlobalStyle } from "../styles/global-styles";
import { NotFoundPage } from "./components/NotFoundPage/Loadable";
import { useToken } from "./hooks";
import { LogoutPage } from "./pages/AuthenticationPages/LogoutPage/index";
import { HomeTown } from "../CRM/organism/Main";
import { DigitalSignage } from "./organisms/DigitalSignage";
import { SaleDigitalSignage } from "./organisms/SaleDigitalSignage";
import { BothDigitalSignage } from "./organisms/BothDigitalSignage";
import { Helmet } from "react-helmet-async";
import { useUserInfo } from "../HomeTown/hooks/Auth/GetUserInfo";
import {PaymentPage} from "../HomeTown/PaymentPage";
import {PaymentSuccess} from "../HomeTown/PaymentPage/organisms/PaymentSuccess";
import {PaymentFailed} from "../HomeTown/PaymentPage/organisms/PaymentFailed";
import {PaymentProgress} from "../HomeTown/PaymentPage/organisms/PaymentProgress";
import {QRReceipt} from "../HomeTown/TossPayment/QRReceipt";
import {TossPaymentSuccess} from "../HomeTown/TossPayment/PaymentSuccess";
import {PaymentFail} from "../HomeTown/TossPayment/PaymentFail";
import {GlobalFooter} from "./components/Layout/GlobalFooter";
import InicisClose from "../HomeTown/InicisPayment/InicisClose";
import HomePageMain from "../HomePage/pages/HomePageMain";
import Fourth from "../HomePage/pages/Fourth";
import Second from "../HomePage/pages/Second";
import Third from "../HomePage/pages/Third";
import {SaleImageMobile} from "../HomeTown/Mobile/SaleImageMobile";
import {SuccessRegister} from "../HomeTown/Mobile/block/SuccessRegister";
import Fifth from "../HomePage/pages/Fifth";
import {HomeNotice} from "../HomePage/pages/Notice";

export function MainRoutes() {
  const { data: token } = useToken();
  const { userInfo, mutateUserInfo } = useUserInfo();
  const [scrollbarWidth, setScrollbarWidth] = useState(() => {
    return localStorage.getItem("scrollbarWidth") || "15px";
  });

  useEffect(() => {
    // 이벤트 리스너로 스크롤바 너비 변경
    const container = document.querySelector(".scroll-container");
    if (container) {
      const updateWidth = () => {
        const newWidth = "20px";
        localStorage.setItem("scrollbarWidth", newWidth);
        setScrollbarWidth(newWidth);
      };
      container.addEventListener("wheel", updateWidth);

      // 클린업
      return () => {
        container.removeEventListener("wheel", updateWidth);
      };
    }
  }, []);


  return (
    <BrowserRouter>
      <Helmet>
        <title>
          울동네
          {userInfo?.is_agent === 1 ? " - 매체주" : userInfo?.is_ad === 1 ? " - 광고주" : userInfo?.is_admin === 1 ? " - 관리자" : ''}
        </title>
      </Helmet>
      
      <GlobalStyle scrollbarWidth={scrollbarWidth} />

      <Routes>
        <Route
          path="/"
          element={<HomePageMain />}
        />

        <Route
          path="/home-login"
          element={<Fourth />}
        />

        <Route
          path="/home-introduce"
          element={<Second />}
        />

        <Route
          path="/home-digital-detail"
          element={<Third />}
        />

        <Route
          path="/method"
          element={<Fifth />}
        />

        <Route
          path="/mobile"
          element={<SaleImageMobile />}
        />

        <Route
          path="/mobile-success-register"
          element={<SuccessRegister />}
        />

        <Route
          path="/notice"
          element={<HomeNotice />}
        />

        <Route path="/admin/login" element={<PAGE.LoginPage />} />
        <Route path="/admin/logout" element={<LogoutPage />} />
        <Route path="/admin" element={token ? <HomeTown /> : <PAGE.LoginPage />} />
        <Route path="/admin/*" element={<HomeTown />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route
          path="/tv"
          element={token ? <DigitalSignage /> : <PAGE.LoginPage />}
        />
        <Route
          path="/digitalSignage"
          element={token ? <DigitalSignage /> : <PAGE.LoginPage />}
        />


        <Route
          path="/checkout"
          element={<QRReceipt />}
        />

        <Route path="/progress-payment" element={<PaymentProgress />} />
        <Route path="/success-payment" element={<PaymentSuccess />} />
        <Route path="/failed-payment" element={<PaymentFailed />} />

        <Route path="/paymentSuccess" element={<TossPaymentSuccess />} />
        <Route path="/paymentFail" element={<PaymentFail />} />


        <Route path="/payment-close" element={<InicisClose />} />

        {/*<Route path="/saleDigitalSignage" element={<SaleDigitalSignage />} />*/}
        {/*<Route path="/bothDigitalSignage" element={<BothDigitalSignage />} />*/}
      </Routes>
    </BrowserRouter>
  );
}
