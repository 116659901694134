export const sizes = {
  mobile: '768px',
  tablet: '1024px',
  desktop: '1920px',
}

export const media = {
  mobile: `(max-width: ${sizes.mobile})`,
  tablet: `(max-width: ${sizes.tablet})`,
  desktop: `(max-width: ${sizes.desktop})`,
}

