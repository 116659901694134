import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {PaymentCard} from "../atoms/PaymentCard";
import { loadTossPayments, ANONYMOUS } from "@tosspayments/tosspayments-sdk";
import {media} from "../../../../utils/mediaUtil";

const generateRandomString = () => window.btoa(String(Math.random())).slice(0, 20);
const clientKey = "test_gck_docs_Ovk5rk1EwkEbP0W43n07xlzm";

export const PaymentMethod = () => {
  const [ready, setReady] = useState(false);
  const [widgets, setWidgets] = useState<any>(null);
  const [amount, setAmount] = useState({
    currency: "KRW",
    value: 50_000,
  });

  useEffect(() => {
    async function fetchPaymentWidgets() {
      const tossPayments = await loadTossPayments(clientKey);
      const widgets = tossPayments.widgets({ customerKey: ANONYMOUS });
      setWidgets(widgets);
    }

    fetchPaymentWidgets();
  }, [clientKey]);


  useEffect(() => {
    async function renderPaymentWidgets() {
      if (widgets == null) {
        return;
      }
      /**
       * 위젯의 결제금액을 결제하려는 금액으로 초기화하세요.
       * renderPaymentMethods, renderAgreement, requestPayment 보다 반드시 선행되어야 합니다.
       * @docs https://docs.tosspayments.com/sdk/v2/js#widgetssetamount
       */
      await widgets.setAmount(amount);

      await Promise.all([
        /**
         * 결제창을 렌더링합니다.
         * @docs https://docs.tosspayments.com/sdk/v2/js#widgetsrenderpaymentmethods
         */
        widgets.renderPaymentMethods({
          selector: "#payment-method",
          // 렌더링하고 싶은 결제 UI의 variantKey
          // 결제 수단 및 스타일이 다른 멀티 UI를 직접 만들고 싶다면 계약이 필요해요.
          // @docs https://docs.tosspayments.com/guides/v2/payment-widget/admin#새로운-결제-ui-추가하기
          variantKey: "DEFAULT",
        }),
        /**
         * 약관을 렌더링합니다.
         * @docs https://docs.tosspayments.com/reference/widget-sdk#renderagreement선택자-옵션
         */
        widgets.renderAgreement({
          selector: "#agreement",
          variantKey: "AGREEMENT",
        }),
      ]);

      setReady(true);
    }

    renderPaymentWidgets();
  }, [widgets]);



  return(
    <Molecule>
      {/*<PriceWrapper>*/}
      {/*  <TotalPrice>총 합계 {'0'}</TotalPrice>*/}
      {/*</PriceWrapper>*/}

      <div className="w-100">
        <div id="payment-method" className="w-100"/>
        <div id="agreement" className="w-100"/>
        <div className="btn-wrapper w-100">
          <button
            className="btn primary w-100"
            onClick={async () => {
              try {
                /**
                 * 결제 요청
                 * 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
                 * 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
                 * @docs https://docs.tosspayments.com/sdk/v2/js#widgetsrequestpayment
                 */
                await widgets?.requestPayment({
                  orderId: generateRandomString(),
                  orderName: "토스 티셔츠 외 2건",
                  customerName: "김토스",
                  customerEmail: "customer123@gmail.com",
                  successUrl: window.location.origin + "/paymentSuccess" + window.location.search,
                  failUrl: window.location.origin + "/paymentFail" + window.location.search
                });
              } catch (error) {
                // TODO: 에러 처리
                console.log('Toss Payment ERROR ===> ', error)
              }
            }}
          >
            결제하기
          </button>
        </div>
      </div>

      {/*<CardMethodTitle>*/}
      {/*  결제 수단 선택*/}
      {/*</CardMethodTitle>*/}

      {/*<MethodGrid>*/}
      {/*  {paymentMethods?.map(method => (*/}
      {/*    <PaymentCard/>*/}
      {/*  ))}*/}
      {/*</MethodGrid>*/}

    </Molecule>
  )
}

const Molecule = styled.div`
    width: 40%;

    @media ${media.mobile} {
        width: 100%;
    }
`

const PriceWrapper = styled.div`
    text-align: end;
    padding: 0 22px;

    @media ${media.mobile} {
        padding: 0 5% ;
    }
`

const TotalPrice = styled.div`

`

const CardMethodTitle = styled.div`
    text-align: center;
    margin-top: 100px;
    margin-bottom: 50px;
`

const MethodGrid = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 20px;
`