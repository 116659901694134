import React, {useState} from 'react'
import {Blacked} from "../../../../CRM/components/styles";
import {ReactComponent as ModalCloseIcon} from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import {ReactComponent as CustomerCard} from "../../../../assets/HomeTownLogin/CustomerCard.svg";
import {ReactComponent as RealEstateCard} from "../../../../assets/HomeTownLogin/RealEstateCard.svg";
import styled from "styled-components";
import {ReactComponent as LeftArrowIcon} from "../assets/LeftArrowIcon.svg";
import {ReactComponent as RightArrowIcon} from "../assets/RightArrowIcon.svg";
import {ReactComponent as DeleteIcon} from "../assets/DeleteIcon.svg";
import {Input, message} from "antd";
import TextArea from "antd/es/input/TextArea";
import API from "../../../../utils/api";
import {useToken} from "../../../../app/hooks";

export const OneToOneDetail = ({setIsOTOModal, mutateAgentInquireList}) => {
    const { data: token } = useToken();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [fileList, setFileList] = useState<any>([]);

    const [fileInputKey, setFileInputKey] = useState(Date.now());


    const UploadData = (file, fileType) => {
        if (!file) {
            message.error('파일을 선택하세요');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);


        API.postImage('POST', '/content?type=user', '', formData)
          .then((res) => {
              if (res.message === 'success') {
                  message.success('파일이 추가되었습니다.');
                  console.log(res)

                  const url = res?.result?.url;
                  const name = res?.result?.name;

                  setFileList([...fileList, {name, fileType, url}])
                  setFileInputKey(Date.now())
              } else {
                  message.error('파일이 잘못되었습니다');
              }
          })
          .catch((err) => {
              message.error('업로드 중 오류가 발생했습니다');
              console.log('Upload Data ERROR',err);
          });
    };


    const PostInquire = () => {
        const shouldEdit = window.confirm(`문의하시겠습니까?`);
        if (shouldEdit) {
            API.post('/board/inquiry/list', token + '', {
                title,
                content,
                fileList
            })
              .then((res: any) => {
                  if (res.result.success) {
                      message.success(`문의하였습니다.`);
                      mutateAgentInquireList();
                      setIsOTOModal(false);
                  } else {
                      message.error(res.result.message || '실패하였습니다');
                  }
              })
              .catch((err: any) => {
                  message.error('실패하였습니다.')
              })
        }
    }


    return (
      <>
          <Blacked onClick={() => setIsOTOModal(null)}/>
          <ModalWrapper className={"scroll-container"}>
              <ModalHeader>
                  <ModalTitle>문의하기</ModalTitle>
                  <CloseIconSVG as={ModalCloseIcon} onClick={() => {setIsOTOModal(null)}  }/>
              </ModalHeader>

              <Table>
                  <tbody>
                  <TableRow>
                      <Th>
                          <ThLabel>
                              제목
                          </ThLabel>
                      </Th>
                      <Td colSpan={3}>
                          <InputText value={title} onChange={e => setTitle(e.target.value as string)} />
                      </Td>
                  </TableRow>
                  <TableRow>
                      <Th>
                          <ThLabel>
                              첨부파일
                          </ThLabel>
                          <ThLabel>
                              개당 1MB
                          </ThLabel>
                      </Th>
                      <Td colSpan={3}>
                          <div>
                              <FileInput key={fileInputKey} type="file" onChange={({target: {files}}) => {
                                  files?.length && UploadData(files[0], 0)
                              }}/>

                              {fileList?.map((item: any, index: number) => (
                                <DescText>
                                    {item?.name}
                                    <DeleteItemIconSVG as={ModalCloseIcon} onClick={() => {
                                        setFileList((prevFileList) => prevFileList.filter((_, i) => i !== index));
                                    }}/>
                                </DescText>
                              ))}

                              {/*<div style={{height: 29, display: 'flex', alignItems: 'center'}}>*/}
                              {/*    <div>매물등록 예시 이미지.jpg (1.7MB)</div>*/}
                              {/*    <DeleteSVG as={DeleteIcon}/>*/}
                              {/*</div>*/}
                          </div>
                      </Td>
                  </TableRow>
                  <TableRow>
                      <Th>
                          <ThLabel>
                              내용
                          </ThLabel>
                      </Th>
                      <Td colSpan={3}>
                          <TextArea value={content} onChange={e => setContent(e.target.value as string)} style={{minHeight: 212, display: 'flex', justifyContent: 'space-between', whiteSpace: 'pre-wrap'}}/>
                      </Td>
                  </TableRow>
                  </tbody>
              </Table>

              <ButtonsWrapper>
                  {/*<PrevNextText>*/}
                  {/*    <ArrowSVG as={LeftArrowIcon}/>*/}
                  {/*    이전글*/}
                  {/*</PrevNextText>*/}

                  <div style={{display: "flex", gap: 6}}>
                      <RegistButton onClick={() => PostInquire()}>
                          등록
                      </RegistButton>
                      <CancelButton onClick={() => setIsOTOModal(null)}>
                          취소
                      </CancelButton>
                  </div>

                  {/*<PrevNextText>*/}
                  {/*    다음글*/}
                  {/*    <ArrowSVG as={RightArrowIcon}/>*/}
                  {/*</PrevNextText>*/}
              </ButtonsWrapper>
          </ModalWrapper>
      </>
    )
}

const DeleteItemIconSVG = styled.svg`
    width: 12px;
    cursor: pointer;
    margin-left: 10px;
`



const DescText = styled.div`
    font-weight: 400;
    font-size: 14px;
    font-family: Pretendard, sans-serif;
    line-height: 16.71px;
    color: #000000;
    margin-top: 8px;
    margin-bottom: 20px;
    white-space: pre-wrap;
`


const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 808px;
    width: 780px;
    background-color: #fff;
    overflow-y: auto;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #2E2559;
`

const Table = styled.table`
    width: 100%;
    margin-top: 8px;
    border-collapse: collapse;
    border: 1px solid #ccc;
`;

const TableRow = styled.tr`
    min-height: 40px;
`;

const Th = styled.th`
    padding: 10px;
    text-align: left;
    width: 20%;
    border: 1px solid #ccc;
    background-color: #F4F2FF;
    vertical-align: middle;
`;

const ThLabel = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
    color: #00000099;
    
`

const Td = styled.td`
    padding: 10px;
    text-align: left;
    border: 1px solid #ccc;
`;

const InputText = styled(Input)`
    width: 100%;
    height: 28px;
    padding-left: 10px;
    border: 1px solid #2E2559;
    border-radius: 4px;
    display: flex;

    &::placeholder {
        color: #535353;
    }
`;


const ButtonsWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`

const RegistButton = styled.div`
    width: 100px;
    height: 40px;
    padding: 11px;
    border: 1px solid #0000004D;
    color: #125DE6;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`

const CancelButton = styled.div`
    width: 100px;
    height: 40px;
    padding: 11px;
    border: 1px solid #0000004D;
    color: #FF6C4B;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;

const DeleteSVG = styled.svg`
    cursor: pointer;
    width: 12px;
    margin-left: 6px;
`

const PrevNextText = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
    font-family: Pretendard, sans-serif;
    color: #00000099;
    cursor: pointer;
`

const ArrowSVG = styled.svg`
    width: 20px;
`

const FileInput = styled(Input)`
    width: 100%;
    padding: 2px;
    border: 1px solid #2E2559;
    border-radius: 4px;
    display: flex;
    margin-bottom: 12px;
`;
