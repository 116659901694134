import React, {useEffect, useState} from 'react'
import {Blacked} from "../../../../CRM/components/styles";
import {ReactComponent as ModalCloseIcon} from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import {ReactComponent as RightArrowIcon} from "../assets/RightArrowIcon.svg";
import {ReactComponent as LeftArrowIcon} from "../assets/LeftArrowIcon.svg";

import styled from "styled-components";
import {Input, message} from "antd";
import {PreviewMonitor} from "./PreviewMonitor";
import {Container as MapDiv, Marker, NaverMap, useNavermaps} from "react-naver-maps";
import {MEDIA_URL} from "../../../../app/constants/APIKEYS";
import {useAdminUserInfo} from "../../../hooks/Admin/UserManage/GetAdminUserInfo";


export const RequestUserInfo = ({setRequestId, requestId}) => {

  // 유틸리티
  const [storeLocation, setStoreLocation] = useState({ lat: 37.5665, lng: 126.9780 });
  const [isPreviewModal, setIsPreviewModal] = React.useState(false);
  const navermaps = useNavermaps();

  // 데이터 조회
  const {adminUserInfo, mutateAdminUserInfo} = useAdminUserInfo(requestId);


  useEffect(() => {

    if(adminUserInfo?.address) {
      setLocation(adminUserInfo?.address)
    }

  }, [adminUserInfo]);


  const setLocation = (fullAddress: string) => {
    navermaps.Service.geocode({
      query: fullAddress
    }, (status, response) => {
      if (status === navermaps.Service.Status.OK) {
        const result = response.v2.addresses[0];
        const lat = parseFloat(result?.y);
        const lng = parseFloat(result?.x);
        if (lat && lng) {
          setStoreLocation({ lat, lng });
        }
      } else {
        console.error('Geocoding failed:', response);
        message.error('해당 주소는 검색할 수 없습니다.');
      }
    });
  };



  return (
    <>
      <Blacked onClick={() => {setRequestId(null);}}/>
      <ModalWrapper className={"scroll-container"}>

        <ModalHeader>
          <ModalTitle>{'부동산중개소 정보'}</ModalTitle>
          <CloseIconSVG as={ModalCloseIcon} onClick={() => {setRequestId(null);}  }/>
        </ModalHeader>

        <MapWrapper>
          <MapDiv style={{ width: '532px', height: '295px' }}>
            <NaverMap
              center={storeLocation}
              defaultZoom={12}
            >
              <Marker
                position={storeLocation}
              />
            </NaverMap>
          </MapDiv>
        </MapWrapper>


        <Table>
          <tbody>
          <TableRow>
            <Th>
              <ThLabel>
                부동산
              </ThLabel>
            </Th>
            <Td>{adminUserInfo?.store}</Td>
            <Th>
              <ThLabel>
                대표자
              </ThLabel>
            </Th>
            <Td>{adminUserInfo?.name}</Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                주소
              </ThLabel>
            </Th>
            <Td colSpan={3}>{adminUserInfo?.address}</Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                매체정보
              </ThLabel>
            </Th>
            <Td colSpan={3}>
              {`${adminUserInfo?.mediaInfo || '-'}, ${adminUserInfo?.mediaType || '-'}`}
            </Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                매체 운영시간
              </ThLabel>
            </Th>
            <Td colSpan={3}>
              {adminUserInfo?.businessHours.replace(/,/g, '') || '-'}
            </Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                유동인구 및 상권
              </ThLabel>
            </Th>
            <Td colSpan={3}>
              <GraphWrapper>

                {/*<GraphTitle>*/}
                {/*  상권의 특성*/}
                {/*  <GraphSubTitle>주거지역</GraphSubTitle>*/}
                {/*</GraphTitle>*/}

                {/*<GraphContents>*/}
                {/*  <PieChart/>*/}

                {/*  <LabelContents>*/}
                {/*    <LabelTitle>상권 유형 분류</LabelTitle>*/}
                {/*    <LabelText><ColorBadge color={'#00a3ff'}/>주거지역 <LabelValue>99%</LabelValue></LabelText>*/}
                {/*    <LabelText><ColorBadge color={'#1053db'}/>기타지역 <LabelValue>1%</LabelValue></LabelText>*/}
                {/*  </LabelContents>*/}

                {/*</GraphContents>*/}

                <div>{`일평균 유동인구 등급: ${adminUserInfo?.footTrafficGrade}`}</div>
                {adminUserInfo?.fileList?.filter(item => item?.type === 'FT') ?
                  adminUserInfo?.fileList?.filter(item => item?.type === 'FT').map((item, index) => (
                    <img alt={item?.url} style={{width: '100%', margin: '0 2px'}}
                         src={MEDIA_URL + item?.url}/>
                  ))
                  :
                  '-'
                }

              </GraphWrapper>

              <GraphWrapper>
                {adminUserInfo?.fileList?.filter(item => item?.type === 'F') ?
                  adminUserInfo?.fileList?.filter(item => item?.type === 'F').map((item, index) => (
                    <img alt={item?.url} style={{width: '100%', margin: '0 2px'}}
                         src={MEDIA_URL + item?.url}/>
                  ))
                  :
                  '-'
                }

                {/*<GraphTitle>*/}
                {/*  유동인구*/}
                {/*</GraphTitle>*/}

                {/*<GraphContents>*/}
                {/*  <BarChart/>*/}
                {/*</GraphContents>*/}

              </GraphWrapper>

            </Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                사진
              </ThLabel>
            </Th>
            <Td colSpan={3}>
              {adminUserInfo?.fileList?.filter(item => item?.type === 'IMG') ?
                adminUserInfo?.fileList?.filter(item => item?.type === 'IMG').map((item, index) => (
                  <img alt={item?.url} style={{width: '50%', margin: '0 2px'}}
                       src={MEDIA_URL + item?.url}/>
                ))
                :
                '-'
              }
            </Td>
          </TableRow>
          </tbody>
        </Table>



        <ButtonsWrapper>
          <PrevNextText>
            <ArrowSVG as={LeftArrowIcon}/>
            이전글
          </PrevNextText>

          <div style={{display: "flex", gap: 6, alignItems: 'center'}}>
            <RegistButton >
              승인
            </RegistButton>
            <CancelButton>
              거절
            </CancelButton>
          </div>

          <PrevNextText>
            다음글
            <ArrowSVG as={RightArrowIcon}/>
          </PrevNextText>
        </ButtonsWrapper>
      </ModalWrapper>

      {/*{isEditModal &&  <CommercialEdit setIsEditModal={setIsEditModal} />}*/}
      {/*{isPauseModal &&  <CommercialPause setIsPauseModal={setIsPauseModal} />}*/}
      {/*{isSuccessModal &&  <TransactionSuccess setIsSuccessModal={setIsSuccessModal} />}*/}
      {/*{isPreviewModal &&  <PreviewMonitor setIsPreviewModal={setIsPreviewModal} />}*/}
    </>
  )
}


const ButtonsWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

const RegistButton = styled.div`
    width: 100px;
    height: 40px;
    padding: 11px;
    color: white;
    background-color: #530DAD;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`

const PrevNextText = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
    font-family: Pretendard, sans-serif;
    color: #00000099;
    cursor: pointer;
`

const ArrowSVG = styled.svg`
    width: 20px;
`


const GraphWrapper = styled.div`

`

const GraphContents = styled.div`
    display: flex;
  align-items: center;
`

const LabelContents = styled.div`
    width: 100px;
`

const LabelTitle = styled.div`
    font-size: 12px;
  font-weight: 600;
  margin-bottom: 8px;
`

const ColorBadge = styled.div<{color:string}>`
  background-color: ${(props) => props.color};
  width: 7px;
  height: 7px;
  border-radius: 50px;
  margin-right: 4px;
`

const LabelText = styled.div`
    display: flex;
  font-size: 11px;
  align-items: center;
  margin-bottom: 4px;
`

const LabelValue = styled.div`
    font-weight: 600;
  margin-left: 10px;
`

const GraphTitle = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: 600;
`

const GraphSubTitle = styled.div`
    color: #00000099;
    font-size: 14px;
`

const MapWrapper = styled.div`

`

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 900px;
    width: 600px;
    min-height: 411px;
    background-color: #fff;
    overflow-y: auto;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 21.5px;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #2E2559;
`

const Table = styled.table`
    width: 100%;
    margin-top: 8px;
    border-collapse: collapse;
    border: 1px solid #ccc;
`;

const TableRow = styled.tr`
    min-height: 40px;
`;

const Th = styled.th`
    padding: 10px;
    text-align: left;
    width: 20%;
    border: 1px solid #ccc;
    background-color: #F4F2FF;
    vertical-align: middle;
`;

const ThLabel = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
    color: #00000099;
    display: flex;
    gap: 2px;
`

const RequireIcon = styled.p`
    color: #F15151;
`

const Td = styled.td`
    padding: 10px;
    text-align: left;
    border: 1px solid #ccc;
`;


const InputText = styled(Input)`
    width: 100%;
    height: 28px;
    padding-left: 10px;
    border: 1px solid #2E2559;
    border-radius: 4px;
    display: flex;
`;



const CancelButton = styled.div`
    width: 100px;
    padding: 11px;
    border: 1px solid #000000;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`