import React, {useEffect, useState} from 'react'
import {Blacked} from "../../../../CRM/components/styles";
import {ReactComponent as ModalCloseIcon} from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import styled from "styled-components";
import {DatePicker, Input, message, Select, Table, TimePicker} from "antd";
import {ReactComponent as RightArrowIcon} from "../assets/RightArrowIcon.svg";
import API from "../../../../utils/api";
import {useToken} from "../../../../app/hooks";



export const ApplicationThreeStep = ({setIsTwoStepModal, setIsThreeStepModal, setIsFourthStepModal, advertiserADInfo, adIdValue, mutateAdvertiserADList}) => {
  // 데이터 조회
  const {data: token} = useToken();



  const dayMap = {
    0: '일',
    1: '월',
    2: '화',
    3: '수',
    4: '목',
    5: '금',
    6: '토'
  };

  const transformText = (text) => {
    if (!text) {
      return '';
    }

    // 주어진 텍스트를 분리합니다.
    const [daysBinary, time] = text.split('/*-/');

    if (!daysBinary || !time) {
      return '';
    }

    // 이진 문자열을 요일 문자열로 변환합니다.
    const days = daysBinary?.split('')?.map((char, index) => char === '1' ? dayMap[index] : '').filter(Boolean).join('');

    return `${days} | ${time}`;
  };


  const DeleteAdvertiser = () => {
    const shouldEdit = window.confirm(`신청를 취소하시겠습니까?`);
    if (shouldEdit) {
      API.delete(`/advertiser/ad/list/${adIdValue}`, token + '', {
      })
        .then((res: any) => {
          if (res.result.success) {
            message.success(`취소하였습니다.`);
            setIsThreeStepModal(false);
            mutateAdvertiserADList();
          } else {
            message.error(res.result.message || `실패하였습니다.`);
          }
        })
        .catch((err: any) => {
          message.error('실패하였습니다.')
        })
    }
  }


  const columns = [
    {
      dataIndex: '',
      title: 'No',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{data?.agentId}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '신청매체',
      render: (value, data) => {
        return (
          <TextWrapper>
            <StoreText>
              {data?.store}
            </StoreText>
            <AddressText>
              {data?.address}
            </AddressText>
            <SubInfoText>
              {transformText(data?.businessHours)}
              {/*10:00-22:00 | 40인치 이하*/}
            </SubInfoText>
          </TextWrapper>
        )
      }
    },
    {
      dataIndex: '',
      title: '진행현황',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex', color: '#125DE6', fontWeight: '500'}}>{data?.status}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '광고비',
      render: (value, data) => {
        return (
          <div className='type' style={{justifyContent: 'center', display: 'flex'}}>{data?.price}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '처리현황',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{'-'}</div>
        )
      }
    }
  ];

  return (
    <>
      <Blacked onClick={() => {
        setIsTwoStepModal(false);
      }}/>
      <ModalWrapper className={"scroll-container"}>
        <ModalHeader>
          <div style={{padding: 12}}/>
          <ModalTitle>광고신청</ModalTitle>
          <CloseIconSVG as={ModalCloseIcon} onClick={() => {
            setIsThreeStepModal(false);
          }}/>
        </ModalHeader>

        <ProcessWrapper>
          <StepWrapper>
            <StepNumber>1</StepNumber>
            <StepTitle>광고내용입력</StepTitle>
          </StepWrapper>

          <ArrowSVG as={RightArrowIcon}/>

          <StepWrapper>
            <StepNumber>2</StepNumber>
            <StepTitle>광고매체선택</StepTitle>
          </StepWrapper>

          <ArrowSVG as={RightArrowIcon}/>

          <StepWrapper>
            <StepNumber Active={true}>3</StepNumber>
            <StepTitle Active={true}>신청완료 및 승인대기</StepTitle>
          </StepWrapper>

          {/*<ArrowSVG as={RightArrowIcon}/>*/}

          {/*<StepWrapper>*/}
          {/*  <StepNumber>4</StepNumber>*/}
          {/*  <StepTitle>승인완료 및 자동결제(예치금차감)</StepTitle>*/}
          {/*</StepWrapper>*/}
        </ProcessWrapper>


        <TopText>
          {`광고신청이 완료되었습니다.
광고시작 1일 전까지 승인완료된 건에 한하여 광고비가 결제됩니다.
신청취소는 결제예정일 전까지만 가능합니다.`}
          <ColorText>신청내용 수정을 원하시는 경우 신청취소 후 재신청 해주시기 바랍니다.</ColorText>
        </TopText>

        <ContentWrapper>
          <ContentTitle>신청내역</ContentTitle>
          <LabelWrapper>
            <ContentLabel>
              광고명
              <ContentValue>{advertiserADInfo?.title}</ContentValue>
            </ContentLabel>

            <ContentLabel>
              광고기간
              <ContentValue>{advertiserADInfo?.period}</ContentValue>
            </ContentLabel>

            <ContentLabel>
              광고상품
              <ContentValue>{advertiserADInfo?.itemName}</ContentValue>
            </ContentLabel>
          </LabelWrapper>


          <StyleTable columns={columns}
                      dataSource={advertiserADInfo?.list}
                      pagination={false}
          />

        </ContentWrapper>

        <EstimateValueWrapper>
          <EstimateLabel>
            광고비 결제예정일
            <EstimateLabelBold>
              {advertiserADInfo?.paymentDueDate}
            </EstimateLabelBold>
          </EstimateLabel>

          <EstimateColorLabel>
            결제 예정 광고비
            <EstimateColorLabelBold>
              {advertiserADInfo?.totalPrice}
            </EstimateColorLabelBold>
          </EstimateColorLabel>
        </EstimateValueWrapper>


        <ButtonWrapper>

          <CancelButton onClick={() => {
            setIsThreeStepModal(false);
            // setIsFourthStepModal(true);
          }}>확인</CancelButton>

          <TempSaveButton onClick={() => {
            DeleteAdvertiser();
          }}>
            신청취소
          </TempSaveButton>


        </ButtonWrapper>
      </ModalWrapper>
    </>
  )
}

const EstimateValueWrapper = styled.div`
    width: 793px;
    height: 54px;
    border: 1px solid #00000033;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
`

const EstimateLabel = styled.div`
    display: flex;
    gap: 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19.09px;
    color: #000000CC;
`

const EstimateLabelBold = styled.div`
    color: #000000;
`

const EstimateColorLabel = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21.48px;
    color: #530DAD;
    display: flex;
    gap: 4px;
`

const EstimateColorLabelBold = styled.div`
    font-weight: 700;
`

const StyleTable = styled(Table)`
 .ant-table-thead > tr > th {
    background-color: #EBEBF4;
    color: #000000;
     font-size: 14px;
     font-weight: 500;
     line-height: 16.71px;
     text-align: center;
  }
`

const TopText = styled.div`
    white-space: pre-wrap;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000E5;
    margin-bottom: 24px;
`

const ColorText = styled.div`
  color: red;
`

const ContentWrapper = styled.div`
    width: 793px;
    min-height: 534px;
    background-color: #FAFAFC;
    padding: 0 40px;
    overflow-y: scroll;
`

const ContentTitle = styled.div`
    text-align: center;
    margin-top: 40px;
    margin-bottom: 16px;
`

const LabelWrapper = styled.div`
    margin-bottom: 16.5px;
`



const ContentLabel = styled.div`
    display: flex;
    gap: 10px;
    font-weight: 600;
    line-height: 17.9px;
    font-size: 15px;
`

const ContentValue = styled.div`
    font-weight: 400;
    line-height: 17.9px;
    font-size: 15px;
`

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1200px;
    max-height: 900px;
    min-height: 411px;
    background-color: #fff;
    overflow-y: auto;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 700;
    font-size: 30px;
    line-height: 35.8px;
    color: #2E2559;
`


const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
    gap: 6px;
`


const TempSaveButton = styled.div`
    width: 100px;
    padding: 11px;
    color: #FF6C4B;
    border: 1px solid #FF6C4B;
    border-radius: 2px;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`

const CancelButton = styled.div`
    width: 100px;
    padding: 11px;
    border: 1px solid #000000;
    border-radius: 2px;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`

const ProcessWrapper = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    margin-top: 45.5px;
    margin-bottom: 67.5px;
`

const ArrowSVG = styled.svg`

`

const StepWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`

const StepNumber = styled.div<{ Active?: boolean }>`
    background-color: ${(props) => props.Active ? '#2E1155' : '#BFBFBF'};
    border-radius: 50px;
    width: 18px;
    height: 18px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-weight: bold;
`

const StepTitle = styled.div<{ Active?: boolean }>`
    font-family: Pretendard, sans-serif;
    font-weight: ${(props) => props.Active ? '400' : '600'};
    font-size: 16px;
    line-height: 19.09px;
    color: ${(props) => props.Active ? '#000000' : '#000000B2'};
`

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-right: 121px;
`

const StoreText = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 19.09px;
`

const AddressText = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
`

const SubInfoText = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
    color: #000000B2;
`