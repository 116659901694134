import useSWR from 'swr'
import {useToken} from "../../../../app/hooks";
import API from "../../../../utils/api";
import {useEffect} from "react";


const M_KEY = '/agent/payment/refund/search-criteria'

const fetchKey = () =>
	`${M_KEY}`;

export function useAgentRefundSearch() {
	const { data: token } = useToken();

	const KEY = fetchKey();


	const fetcher = () => fetchAPI(KEY, token);
	const { data, mutate } = useSWR(token ? `${KEY}` : null, fetcher, { revalidateOnFocus: true });

	return { agentRefundSearch: data, mutateAgentRefundSearch: mutate};
}

async function fetchAPI(KEY:string, token:any)  {
	try {
		const response = await API.get(`${KEY}`, token + '', {});
		return response.result?.result;
	} catch (error) {
		console.error('Failed to fetch Agent Refund Search:', error);
		return {};
	}
}