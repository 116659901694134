import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Checkbox} from "antd";
import {MEDIA_URL} from "../../../../app/constants/APIKEYS";

interface PropsType {
  setRealEstateId?:React.Dispatch<React.SetStateAction<any>>;
  setAgentIds?:React.Dispatch<React.SetStateAction<any>>;
  address?:string
  agentId?:number
  businessHours?:string
  imageUrl?: string
  mediaInfo?:string
  store?:string
  agentIds?:any
}

export const TwoStepListItem = ({setRealEstateId, agentId, address, businessHours, imageUrl, mediaInfo, store, setAgentIds, agentIds}:PropsType) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    // console.log('agentIds', agentIds)
    // console.log('agentId', agentId)

    if (agentIds && agentId) {
      setChecked(agentIds.includes(agentId));
    }
  }, [agentIds, agentId]);

  const handleCheckboxChange = (e: any) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      setAgentIds && setAgentIds((prev: number[]) => [...prev, agentId!]);
    } else {
      setAgentIds && setAgentIds((prev: number[]) => prev.filter(id => id !== agentId));
    }
  };

  return (
    <Atom >
      <Checkbox style={{marginRight: 21}} checked={checked} onChange={handleCheckboxChange}/>
      <TextWrapper>
        <StoreText onClick={() =>  setRealEstateId && setRealEstateId(agentId)}>
          {store || '등록되지 않은 상호'}
        </StoreText>
        <AddressText>
          {address || '등록되지 않은 주소'}
        </AddressText>
        <SubInfoText>
          {`${businessHours ? businessHours?.slice(0, 2) + '시' + '~' + businessHours?.slice(3, 5) + '시' : ''}  ${ mediaInfo ? '| ' + mediaInfo : ''}`}
        </SubInfoText>
      </TextWrapper>

      {imageUrl && <PlaceImg src={MEDIA_URL + imageUrl}/> }


    </Atom>
  )
}

const Atom = styled.div`
  display: flex;
    align-items: center;
    padding: 20px;
    //border-bottom: 1px solid #0000000D;
`

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-right: 121px;
`

const StoreText = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 19.09px;
    text-decoration: underline;
    cursor: pointer;
`

const AddressText = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
`

const SubInfoText = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
    color: #000000B2;
`

const PlaceImg = styled.img`
    width: 125px;
`