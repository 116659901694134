import React, {useState} from 'react'
import {Display} from "../../../CRM/organism/Main/styles";
import {CommercialRegistFilter} from "./CommercialRegistFilter";
import {CommercialRegistTable} from "./CommercialRegistTable";
import {useOrderList} from '../../../CRM/hooks/Tosme/OperateManage/Sales/OrderManage/GetOrderList'
import styled from "styled-components";
import {useAdminContentsList} from "../../hooks/Admin/ContentsManage/GetAdminContentList";
import {RegistContents} from "./blocks/RegistContents";
import {useAdminUserList} from "../../hooks/Admin/CommercialRegist/GetAdminUserList";


export const CommercialRegist = () => {
  // 필터링
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchWord, setSearchWord] = useState('');
  const [order, setOrder] = useState<'latest' | 'oldest'>('latest');
  const [status, setStatus] = useState<0 | 1 | 2>(0);

  // 유틸리티
  const [registContentsModal, setRegistContentsModal] = useState(false);
  const [isSelectItem, setIsSelectItem] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);

  // 데이터 조회
  const {adminUserList, mutateAdminUserList, total, isLoading} = useAdminUserList(page, pageSize, status, searchWord, order)


  return (
    <Display>

      <CommercialRegistFilter
        setSearchWord={setSearchWord}
        setOrder={setOrder}
        order={order}
        setStatus={setStatus}
        mutateAdminContentsList={mutateAdminUserList}
        setIsSelectItem={setIsSelectItem}
        isSelectItem={isSelectItem}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        isAllChecked={isAllChecked}
        setIsAllChecked={setIsAllChecked}
      />

      <CommercialRegistTable
        adminContentsList={adminUserList}
        mutateAdminContentsList={mutateAdminUserList}
        total={total}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        isLoading={isLoading}
        setIsSelectItem={setIsSelectItem}
        isSelectItem={isSelectItem}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        isAllChecked={isAllChecked}
        setIsAllChecked={setIsAllChecked}
      />

      {/*{registContentsModal && <RegistContents setRegistContentsModal={setRegistContentsModal} mutateAdminContentsList={mutateAdminContentsList} />}*/}
    </Display>
  )
}

const RegistSection = styled.div`
    display: flex;
    justify-content: flex-end;
`

const RegistSaleButton = styled.div`
    background-color: #530DAD;
    box-shadow: 0px 0px 10px 0px #0000000D;
    width: 140px;
    height: 42px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;

    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`
