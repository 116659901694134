import React from "react";
import styled from "styled-components";

export const GlobalFooter = () => {
	return (
		<Container>
			<ContentsWrap>
				<div>
					<Contents>
						{`주식회사 나이츠랩 대표 신임식
사업자등록번호 : 104-34-24444
주소 : 대구광역시 달서구 상인로 128, 212호
통신판매업신고번호 : 제2024-대구달서-1494호
나이츠랩에서 운영하는 사이트에서 판매되는 모든 상품은 나이츠랩에서 책임지고 있습니다.`}
					</Contents>
				</div>

				<Contents>
					{`연락처 : 010-2354-2459
이메일 : maxforall@naver.com`}
				</Contents>
			</ContentsWrap>
		</Container>
	);
};

const Container = styled.footer`
	width: 100%;
	height: 300px;
	background-color: #2e2559;
	z-index: 1;
	position: relative;
	border-top: 1px solid #3e346b;
	padding: 40px 60px;
`;

const ContentsWrap = styled.div`
	display: flex;
	gap: 40px;
`;

const Contents = styled.div`
	color: #fff;
	white-space: pre-wrap;
	line-height: 50px;
`;

const ContentsGrey = styled.div`
	color: "#999";
	white-space: pre-wrap;
	line-height: 50px;
`;
