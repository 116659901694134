import styled from 'styled-components';
import grid1 from '../../assets/images/grid_1.png';
import grid2 from '../../assets/images/grid_2.png';
import grid3 from '../../assets/images/grid_3.png';
import grid4 from '../../assets/images/grid_4.png';
import grid5 from '../../assets/images/grid_5.png';
import grid6 from '../../assets/images/grid_6.png';
import GridPost from '../GridPost';
import * as React from "react";
import {RegisteModal} from "../../pages/Second/modal/RegisterModal";
import {useMakerUserList} from "../../../HomeTown/hooks/Admin/CommercialRegist/GetMakerUserList";
import {useNavigate} from "react-router";
import {MEDIA_URL} from "../../../app/constants/APIKEYS";
import {DeskTopCalVW, media} from "../../../utils/CalVW";

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 56px 320px 0 320px;

    @media ${media.desktop} {
        padding: ${DeskTopCalVW(56)} ${DeskTopCalVW(320)} ${DeskTopCalVW(0)} ${DeskTopCalVW(320)};
    }
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(302px, 1fr));
    gap: 36px 24px;

    // @media ${media.desktop} {
    //     gap: ${DeskTopCalVW(36)} ${DeskTopCalVW(24)};
    //     grid-template-columns: repeat(auto-fill, minmax(${DeskTopCalVW(302)}, 1fr));
    // }
`;

const ContactButton = styled.button`
  padding: 10px;
  border-radius: 4px;
  background-color: #ff2828;
  font-size: 1.25rem;
  font-weight: 700;
  color: #ffffff;
  line-height: 28px;
  border: none;

    // @media ${media.desktop} {
    //     padding: ${DeskTopCalVW(10)};
    //     border-radius: ${DeskTopCalVW(4)};
    //     font-size: ${DeskTopCalVW(20)};
    //     line-height: ${DeskTopCalVW(28)};
    // }
`;

const RegisterWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;

    @media ${media.desktop} {
        margin-bottom: ${DeskTopCalVW(20)};
    }
`;


function SecondContent() {
  const [isRegisterOpen, setIsRegisterOpen] = React.useState(false);
  const navigate = useNavigate();

  // 필터링
  // const [page, setPage] = useState(1);
  // const [pageSize, setPageSize] = useState(10);
  // const [searchWord, setSearchWord] = useState('');
  // const [order, setOrder] = useState<'latest' | 'oldest'>('latest');
  // const [status, setStatus] = useState<0 | 1 | 2>(0);

  // 데이터 조회
  const {makerUserList, mutateMakerUserList, total, isLoading} = useMakerUserList(1, 9999, 0, '', '')


  const gridData = [
    grid1,
    grid2,
    grid3,
    grid4,
    grid5,
    grid6,
  ];

  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * gridData.length);
    return gridData[randomIndex];
  };



  return (
    <Container>
      <RegisterWrap>
        <ContactButton
          onClick={() => {
            setIsRegisterOpen(true);
          }}
        >광고물 제작 업체 등록 신청</ContactButton>
      </RegisterWrap>

      <GridContainer>
        {makerUserList?.map((data, index) => (
          <GridPost
            onClick={() => {
              navigate("/home-digital-detail", { state: { id: data.userId } });
            }}
            key={`homeCommercialGrid_${index}`}
            image={data?.filelist ?  MEDIA_URL + data?.filelist[0].url : getRandomImage()}
            title={data.store}
            description={data.address || '-'}
            descriptionDetail={data.detailAddress || '-'}
          />
        ))}
      </GridContainer>

      {isRegisterOpen &&
        <RegisteModal setIsRegisterOpen={setIsRegisterOpen}/>
      }
    </Container>
  );
}

export default SecondContent;
