import * as React from 'react'
// import { ContentContainer, ContentWrapper } from '../../../../styles/login'
import LoginSection from '../../../../app/organisms/Auth/LoginSection'
import { ThemeContext } from '../../../../theme/ThemeProvider'
import styled from "styled-components";
import LoginForm from "../../../organisms/Auth/Forms/LoginForm";
import {HomeTownSignUpModal} from "../../../organisms/Auth/Forms/HomeTownSignUpModal";
import {HomeTownCustomerSignUpModal} from "../../../organisms/Auth/Forms/HomeTownCustomerSignUpModal";
import {HomeTownRealEstateSignUpModal} from "../../../organisms/Auth/Forms/HomeTownRealEstateSignUpModal";
import {media} from "../../../../utils/CalVW";

export function LoginPage() {
	const [isSignUpModalOpen, setIsSignUpModalOpen] = React.useState(false);
	const [isCustomerSignUpModalOpen, setIsCustomerSignUpModalOpen] = React.useState(false);
	const [isRealEstateSignUpModalOpen, setIsRealEstateSignUpModalOpen] = React.useState(false);

	return (
		<ContentContainer>
			<LoginForm setIsSignUpModalOpen={setIsSignUpModalOpen}  />

			{isSignUpModalOpen &&
				<HomeTownSignUpModal setIsSignUpModalOpen={setIsSignUpModalOpen} setIsCustomerSignUpModalOpen={setIsCustomerSignUpModalOpen} setIsRealEstateSignUpModalOpen={setIsRealEstateSignUpModalOpen}/>
			}

			{isCustomerSignUpModalOpen &&
				<HomeTownCustomerSignUpModal setIsCustomerSignUpModalOpen={setIsCustomerSignUpModalOpen}/>
			}

			{isRealEstateSignUpModalOpen &&
				<HomeTownRealEstateSignUpModal setIsRealEstateSignUpModalOpen={setIsRealEstateSignUpModalOpen}/>
			}

		</ContentContainer>
	)
}

const ContentContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #F7F2FD;
	
	
`
